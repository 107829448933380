import { FC, useRef } from 'react';
import { Stack } from '@mui/material';
import { Dialog } from '@/components/Dialog/Dialog';
import { GOALKEEPER_PENALTY_FACED_DIALOG_TITLE } from '@/components/Dialogs/constants';
import { GoalkeeperPenaltyFacedAction } from '@/types/action/action';
import { Controls } from '@/components/Dialogs/Common/Controls';
import { postAction } from '@/service/helpers/postAction';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { OUTCOME_LABEL, STANCE_LABEL } from '@/constants';
import { GoalkeeperPenaltyFacedOutcome, Stance } from '@contract';
import {
  setActionPartialMetadata,
  setMode,
} from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import {
  GOALKEEPER_PENALTY_FACED_OUTCOME,
  GOALKEEPER_PENALTY_FACED_STANCE,
} from '@/components/Dialogs/GoalkeeperPenaltyFacedDialog/constants';
import { MODE } from '@/stores/ActionStore/constants';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { FlagField } from '@/components/Dialogs/Common/FlagField';

interface GoalkeeperPenaltyFacedDialogProps {
  action: GoalkeeperPenaltyFacedAction;
  onClose: VoidFunction;
}

const onOutcomeChange = (outcome: GoalkeeperPenaltyFacedOutcome) => {
  setActionPartialMetadata<GoalkeeperPenaltyFacedAction>({ outcome });
};

const onStanceChange = (stance: Stance) => {
  setActionPartialMetadata<GoalkeeperPenaltyFacedAction>({ stance });
};

export const GoalkeeperPenaltyFacedDialog: FC<
  GoalkeeperPenaltyFacedDialogProps
> = ({ action, onClose }) => {
  const initialAction = useRef(action);
  const metadata = getActionMetadata(action);

  const onSubmit = () => {
    postAction(action);
    onClose();
    if (metadata.outcome === GoalkeeperPenaltyFacedOutcome.Saved) {
      setMode(MODE.GOALKEEPER_SAVE);
    }
  };

  return (
    <Dialog isOpen title={GOALKEEPER_PENALTY_FACED_DIALOG_TITLE}>
      <Stack gap={2}>
        <RadioButtons
          name={OUTCOME_LABEL}
          onChange={onOutcomeChange}
          value={metadata.outcome}
          controlLabel={OUTCOME_LABEL}
          fields={GOALKEEPER_PENALTY_FACED_OUTCOME}
        />
        <RadioButtons
          name={STANCE_LABEL}
          onChange={onStanceChange}
          value={metadata.stance}
          controlLabel={STANCE_LABEL}
          fields={GOALKEEPER_PENALTY_FACED_STANCE}
        />
        <ActionPlayerField action={action} />
        <PeriodClockField action={action} />
        <FlagField action={action} />
        <Controls
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={
            JSON.stringify(action) === JSON.stringify(initialAction.current)
          }
        />
      </Stack>
    </Dialog>
  );
};
