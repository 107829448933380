import { Box, styled } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { PitchCoords } from '@/stores/ActionStore/ActionStore';
import { Dot } from '@/components/Dot/Dot';
import { POSITION_ATTRIBUTE } from './ClickableDots';

export type CoordsDotProps = {
  coords: Pick<PitchCoords, 'xDraw' | 'yDraw'>;
  playerNumber?: string | null;
  color?: string;
  positionId?: string;
  sx?: CSSProperties;
};

const DotContainer = styled(Box)`
  --left: 0;
  --top: 0;
  position: absolute;
  top: var(--top);
  left: var(--left);
  translate: -50% -50%;
  pointer-events: none;
  z-index: 1;
  overflow: visible;
`;

const PlayerNumber = styled('span')`
  position: absolute;
  bottom: 101%;
  left: 50%;
  translate: -50% 0;
  min-width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  background-color: var(--pitchColor, black);
`;

export const CoordsDot: FC<CoordsDotProps> = ({
  coords,
  playerNumber = '',
  color,
  positionId,
  sx,
}) => {
  return (
    <DotContainer
      style={
        {
          ...sx,
          '--left': coords.xDraw + '%',
          '--top': coords.yDraw + '%',
          '--targetsColor': color,
        } as CSSProperties
      }
    >
      <Dot {...{ [POSITION_ATTRIBUTE]: positionId }} />
      <PlayerNumber>{playerNumber}</PlayerNumber>
    </DotContainer>
  );
};
