import { Player } from '@/service/lineups';
import { Action } from '@/types/action/action';
import { ACTION_TYPE_ID, Extras, METADATA_KEY } from '@contract';
import {
  FILTER_NAME,
  FilterName,
  FilterValue,
} from '@/components/EventHistory/FiltersDrawer/constants';
import { getActionMetadata, getNoFlagWarnings, hasFlagToQa } from './actions';

export const makePlayersFilter = (players: Player[]) => {
  if (players.length === 0) return null;
  return (action: Action) => {
    return players.some((player) => {
      return action.player?.id === player.id;
    });
  };
};

export const makeActionTypeFilter = (events: ACTION_TYPE_ID[]) => {
  if (events.length === 0) return null;
  return (action: Action) => {
    return events.some((event) => {
      return action.actionTypeId === event;
    });
  };
};

export const makeOutcomeFilter = (outcome: boolean | null) => {
  if (outcome === null) return null;

  return (action: Action) => {
    return action.isSuccessful === outcome;
  };
};

export const makeFlagFilter = (withFlag: boolean | null) => {
  if (withFlag === null) return null;
  return (action: Action) => {
    return withFlag ? hasFlagToQa(action) : !hasFlagToQa(action);
  };
};

export const makeWarningFilter = (withWarnings: boolean | null) => {
  if (withWarnings === null) return null;

  return (action: Action) => {
    const noFlagWarnings = getNoFlagWarnings(action);
    const hasNoFlagWarnings = !!noFlagWarnings && noFlagWarnings.length > 0;
    if (withWarnings) {
      return hasNoFlagWarnings;
    }
    return !hasNoFlagWarnings;
  };
};

export const makeExtrasFilter = (extras: Extras[]) => {
  if (extras.length === 0) return null;

  return (action: Action) => {
    if (
      !action.metadata ||
      !action.actionTypeMetadata ||
      action.actionTypeMetadata === METADATA_KEY.goalkeeperSave
    )
      return false;
    const metadata = getActionMetadata(action);
    const extrasMetadata = 'extras' in metadata && metadata.extras;

    if (!extrasMetadata) return false;

    return extras.some((extra) => {
      return extrasMetadata.includes(extra);
    });
  };
};

export type ActionFilterFn = (action: Action) => boolean;

function getFilterFn(
  filterName: FilterName,
  filterValues: FilterValue,
): ActionFilterFn | null {
  switch (filterName) {
    case FILTER_NAME.PLAYERS:
      return makePlayersFilter(filterValues[filterName]);
    case FILTER_NAME.EVENTS:
      return makeActionTypeFilter(filterValues[filterName]);
    case FILTER_NAME.EXTRAS:
      return makeExtrasFilter(filterValues[filterName]);
    case FILTER_NAME.OUTCOME:
      return makeOutcomeFilter(filterValues[filterName]);
    case FILTER_NAME.WARNINGS:
      return makeWarningFilter(filterValues[filterName]);
    case FILTER_NAME.FLAGS:
      return makeFlagFilter(filterValues[filterName]);
    default:
      return null;
  }
}

export function makeFilters(filterValues: FilterValue) {
  const filters = Object.keys(filterValues).reduce<ActionFilterFn[]>(
    (acc, key) => {
      const filterName = key as keyof typeof filterValues;
      const filterFn = getFilterFn(filterName, filterValues);
      if (filterFn !== null) acc.push(filterFn);
      return acc;
    },
    [],
  );
  return filters;
}
