export const CANCEL_BUTTON_LABEL = 'Cancel';
export const COMMIT_BUTTON_LABEL = 'Commit';
export const RESET_BUTTON_LABEL = 'Reset';
export const BACK_BUTTON_LABEL = 'Back';
export const DELETE_LABEL = 'Delete';
export const HOME_BUTTON_LABEL = 'Home';
export const SIGN_OUT_BUTTON_LABEL = 'Sign out';

export const ATTEMPT_TYPE_LABEL = 'Attempt type';
export const ATTEMPT_BY_LABEL = 'Attempt by';
export const ASSIST_LABEL = 'Assist';
export const TIME_LABEL = 'Time';
export const PLAYER_LABEL = 'Player';
export const OUTCOME_LABEL = 'Outcome';
export const FROM_PLAYER_LABEL = 'From player';
export const PASS_TO_LABEL = 'To player';
export const PATTERN_OF_PLAY_LABEL = 'Pattern of play';
export const BODY_PARTS_LABEL = 'Body parts';
export const EXTRAS_LABEL = 'Extras';
export const EVENTS_LABEL = 'Events';
export const SHOT_COORDS_LABEL = 'Shot coordinates';
export const BALL_COORDS_LABEL = 'Ball coordinates';
export const BLOCKER_COORDS_LABEL = 'Blocker coordinates';
export const GOAL_COORDS_LABEL = 'Goal coordinates';
export const EDIT_TIME_LABEL = 'Edit event time';
export const FLAG_LABEL = 'Flag';
export const CARD_LABEL = 'Card';
export const REASON_LABEL = 'Please select a reason';
export const PLAYER_CARDED_LABEL = 'Player carded';
export const OPPOSITE_TEAM_LABEL = 'Opposite team';
export const HEADED_LABEL = 'Headed';
export const STANCE_LABEL = 'Stance';
