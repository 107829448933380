import { FC, useRef } from 'react';
import { Stack } from '@mui/material';
import {
  GoalkeeperSaveAttemptType,
  GoalkeeperSaveBodyPart,
  GoalkeeperSaveExtras,
  GoalkeeperSaveType,
  ActionWarning,
} from '@contract';
import { Dialog } from '@/components/Dialog/Dialog';
import { GOALKEEPER_SAVE_DIALOG_TITLE } from '@/components/Dialogs/constants';
import { GoalkeeperSaveAction } from '@/types/action/action';
import { postAction } from '@/service/helpers/postAction';
import { getActionMetadata } from '@/utils/actions';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ATTEMPT_TYPE_LABEL,
  BODY_PARTS_LABEL,
  EXTRAS_LABEL,
  STANCE_LABEL,
} from '@/constants';
import {
  setAction,
  setActionPartialMetadata,
} from '@/stores/ActionStore/ActionStore';
import {
  GOALKEEPER_SAVE_ATTEMPT_TYPE,
  GOALKEEPER_SAVE_BODY_PART,
  GOALKEEPER_SAVE_STANCE,
  goalkeeperSaveExtras,
} from '@/components/Dialogs/GoalkeeperSaveDialog/constants';
import { CheckboxGroup } from '@/components/Form/CheckboxGroup';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { FlagField } from '@/components/Dialogs/Common/FlagField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { toggleWarning } from '@/components/ActionWarnings/helpers';
import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import { Controls } from '../Common/Controls';

interface GoalkeeperSaveProps {
  action: GoalkeeperSaveAction;
  onClose: VoidFunction;
}

const onBodyPartChange = (bodyPart: GoalkeeperSaveBodyPart) => {
  setActionPartialMetadata<GoalkeeperSaveAction>({ bodyPart });
};

const onStanceChange = (type: GoalkeeperSaveType) => {
  setActionPartialMetadata<GoalkeeperSaveAction>({ type });
};

const onExtrasChange = (extras: GoalkeeperSaveExtras[]) => {
  setActionPartialMetadata<GoalkeeperSaveAction>({ extras });
};

export const GoalkeeperSaveDialog: FC<GoalkeeperSaveProps> = ({
  action,
  onClose,
}) => {
  const initialAction = useRef(action);
  const metadata = getActionMetadata(action);

  const onAttemptTypeChange = (attemptType: GoalkeeperSaveAttemptType) => {
    let modifiedWarnings: ActionWarning[] = [];
    let modifiedMetadata = { ...metadata };

    if (attemptType === GoalkeeperSaveAttemptType.TemptSave) {
      modifiedWarnings = toggleWarning(null, ACTION_WARNING.FLAG_TO_QA);
      modifiedMetadata = {
        type: null,
        bodyPart: null,
        extras: null,
        position: metadata.position,
        attemptType: GoalkeeperSaveAttemptType.TemptSave,
      };
    }
    if (attemptType === GoalkeeperSaveAttemptType.Save) {
      modifiedMetadata.attemptType = GoalkeeperSaveAttemptType.Save;
    }

    const updatedAction = {
      ...action,
      warnings: modifiedWarnings,
      metadata: {
        goalkeeperSave: modifiedMetadata,
      },
    };

    setAction(updatedAction);
  };

  const onSubmit = () => {
    postAction(action);
    onClose();
  };

  return (
    <Dialog isOpen title={GOALKEEPER_SAVE_DIALOG_TITLE}>
      <Stack gap={2}>
        <RadioButtons
          name={ATTEMPT_TYPE_LABEL}
          onChange={onAttemptTypeChange}
          value={metadata.attemptType}
          controlLabel={ATTEMPT_TYPE_LABEL}
          fields={GOALKEEPER_SAVE_ATTEMPT_TYPE}
        />
        <RadioButtons
          name={BODY_PARTS_LABEL}
          onChange={onBodyPartChange}
          value={metadata.bodyPart}
          controlLabel={BODY_PARTS_LABEL}
          fields={GOALKEEPER_SAVE_BODY_PART}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <RadioButtons
          name={STANCE_LABEL}
          onChange={onStanceChange}
          value={metadata.type}
          controlLabel={STANCE_LABEL}
          fields={GOALKEEPER_SAVE_STANCE}
          sx={{ maxWidth: '400px' }}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <CheckboxGroup
          controlLabel={EXTRAS_LABEL}
          fields={goalkeeperSaveExtras}
          onChange={onExtrasChange}
          values={metadata.extras || []}
          disabled={
            metadata.attemptType === GoalkeeperSaveAttemptType.TemptSave
          }
        />
        <ActionPlayerField action={action} />
        <PeriodClockField action={action} />
        <FlagField action={action} />
        <Controls
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={
            JSON.stringify(action) === JSON.stringify(initialAction.current) ||
            (metadata.attemptType === GoalkeeperSaveAttemptType.Save &&
              (metadata.bodyPart === null ||
                metadata.type === null ||
                metadata.extras === null))
          }
        />
      </Stack>
    </Dialog>
  );
};
