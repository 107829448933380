import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import Remove from '@mui/icons-material/Remove';
import HighlightOff from '@mui/icons-material/HighlightOff';
import type { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import type { Action } from '@/types/action/action';

type OutcomeIconProps = SvgIconProps & {
  outcome: Action['isSuccessful'];
};

export const OUTCOME_TEST_ID = {
  SUCCESSFUL: 'outcome-successful',
  FAILED: 'outcome-failed',
  NONE: 'outcome-none',
} as const;

export const OutcomeIcon: FC<OutcomeIconProps> = (allProps) => {
  const { outcome, ...props } = allProps;
  if (outcome === null) {
    return <Remove data-testid={OUTCOME_TEST_ID.NONE} {...props} />;
  }

  if (outcome) {
    return (
      <CheckCircleOutline
        data-testid={OUTCOME_TEST_ID.SUCCESSFUL}
        color='success'
        {...props}
      />
    );
  }

  return (
    <HighlightOff
      data-testid={OUTCOME_TEST_ID.FAILED}
      color='error'
      {...props}
    />
  );
};
