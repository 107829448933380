import { Stack, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { darkGlass } from '@/utils/style/darkGlass';
import type { PlayerPositionDto } from '@contract';
import { POSITION_NAME } from '@/stores/LineupStore/constants';

export type PlayerProps = {
  player: PlayerPositionDto['player'];
  position: PlayerPositionDto['position'];
};

export const activeStyle = {
  backgroundColor: 'hsla(0deg 66% 30% / 100%)',
  backdropFilter: 'none',
  transform: 'scale(1.1)',
};
export const activePartialStyle = {
  backgroundColor: 'hsla(0deg 75% 15% / 75%)',
};

export const LineupPlayerShirt: FC<TypographyProps> = ({ sx, ...props }) => {
  return (
    <Typography
      sx={{
        display: 'inline-flex',
        width: '2.5em',
        aspectRatio: '1',
        borderRadius: '50%',
        boxShadow: (theme) => theme.shadows[6],
        ...darkGlass,
        alignItems: 'center',
        justifyContent: 'center',
        '.active &': {
          ...activeStyle,
        },
        '.active-partial &': {
          ...activePartialStyle,
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export const LineupPlayerLabel: FC<TypographyProps> = ({ sx, ...props }) => {
  return (
    <Typography
      sx={{
        position: 'absolute',
        top: '100%',
        maxWidth: '100%',
        marginTop: (theme) => theme.spacing(0.5),
        fontSize: '0.7em',
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: 1.1,
        padding: (theme) => theme.spacing(0.5, 1),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxShadow: (theme) => theme.shadows[3],
        ...darkGlass,
        borderRadius: '999em',
        '.active &': {
          ...activeStyle,
        },
        '.active-partial &': {
          ...activePartialStyle,
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export const LineupPlayer: FC<PlayerProps> = ({ player, position }) => {
  const { firstName, lastName, shirtNumber, id } = player;
  const positionName = POSITION_NAME[position];

  const playerNumber = useActionStore((state) => state.playerNumber);
  const selectedPlayer = useActionStore((state) => state.player);
  const displayName = `${firstName.charAt(0)}. ${lastName}`;

  const getStatusClass = () => {
    if (!playerNumber) {
      return;
    }

    if (selectedPlayer) {
      if (selectedPlayer.id === id) {
        return 'active';
      }
      return;
    }

    const isExactShirtNumber = String(shirtNumber) === playerNumber;
    if (isExactShirtNumber) {
      return 'active';
    }

    const isPartialShirtNumber = String(shirtNumber).startsWith(playerNumber);
    if (isPartialShirtNumber) {
      return 'active-partial';
    }
  };

  return (
    <Stack
      sx={{
        color: 'white',
        position: 'relative',
        justifySelf: 'stretch',
        alignSelf: 'center',
        alignItems: 'center',
        fontSize: '1rem',
      }}
      className={getStatusClass()}
      style={{ gridArea: positionName }}
    >
      <LineupPlayerShirt>{shirtNumber}</LineupPlayerShirt>
      <LineupPlayerLabel>{displayName}</LineupPlayerLabel>
    </Stack>
  );
};
