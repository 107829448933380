import {
  Backdrop,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';
import { FC } from 'react';

export type LoadingOverlayProps = {
  open?: boolean;
  circularProgessProps?: CircularProgressProps;
};
export const LoadingOverlay: FC<LoadingOverlayProps> = ({
  open,
  circularProgessProps,
}) => {
  return (
    <Backdrop
      open={open === undefined ? true : open}
      sx={{
        position: 'absolute',
      }}
    >
      <CircularProgress size='2em' {...circularProgessProps} />
    </Backdrop>
  );
};
