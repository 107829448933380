import type { SaveLineupDto } from '@contract';

declare global {
  interface Window {
    _ed: {
      setDebug?: (enable: boolean) => void;
      $video?: HTMLVideoElement | null;
      saveLineup?: (data: SaveLineupDto) => void;
    };
  }
}
export const SS_KEY = {
  DEBUG: 'debug',
  TRACE_STORE: 'traceStore',
};

export const setupWindow = () => {
  if (!window._ed) {
    window._ed = {};
  }
};

export function registerWindowUtil<Key extends keyof Window['_ed']>(
  key: Key,
  val: Window['_ed'][Key],
) {
  setupWindow();
  window._ed[key] = val;
}
