import { useMemo } from 'react';
import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import {
  ACTION_OUTCOME_ONLY_NONE,
  ACTION_OUTCOME_ONLY_SUCCESS,
} from '@/stores/ActionStore/constants';
import { TableAction } from '@/stores/FixtureStore';
import { Action, PassAction } from '@/types/action/action';
import { ActionWarning } from '@contract';

export function isFlagToQAWarning(warning: ActionWarning) {
  return warning.type === ACTION_WARNING.FLAG_TO_QA.type;
}

export function hasFlagToQa(action: Action | TableAction) {
  if (!action.warnings) return false;
  return action.warnings.some(isFlagToQAWarning);
}

export function getNoFlagWarnings(action: Action | TableAction) {
  if (!action.warnings) return null;
  return action.warnings.filter((warning) => !isFlagToQAWarning(warning));
}

export function isPassSamePlayerWarning(warning: ActionWarning) {
  return warning.type === ACTION_WARNING.PASS_SAME_PLAYER.type;
}

export function isPassSamePlayerAction(action: PassAction) {
  if (!action.player || !action.metadata.pass.targetPlayer) return false;
  return action.player.id === action.metadata.pass.targetPlayer.id;
}

export function getActionMetadata<T extends Action>(action: T) {
  return action.metadata[action.actionTypeMetadata] as ActionMeta<T>;
}

export function isPositiveOutcome(action: Action) {
  return !!action.isSuccessful;
}

export function isActionOutcomeEditable(actionTypeId: Action['actionTypeId']) {
  if (
    ACTION_OUTCOME_ONLY_NONE.includes(actionTypeId) ||
    ACTION_OUTCOME_ONLY_SUCCESS.includes(actionTypeId)
  ) {
    return false;
  }
  return true;
}

export function useIsActionOutcomeEditable(
  actionTypeId: Action['actionTypeId'],
) {
  return useMemo(() => isActionOutcomeEditable(actionTypeId), [actionTypeId]);
}
