import { Chip, Zoom } from '@mui/material';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';

const TEST_ID = 'formation-chip';

export const Formation = () => {
  const formation = useLineupStore((state) => state.formation);

  return (
    <Zoom in={!!formation}>
      <Chip data-testid={TEST_ID} label={formation} />
    </Zoom>
  );
};
