import { Box, Container, Paper, Stack, Typography } from '@mui/material';
import { BottomBar } from '@/components/BottomBar/BottomBar';
import { FixturesCollectionsTable } from '@/components/FixturesCollectionsTable/FixturesCollectionsTable';
import { NavBar } from '@/components/NavBar/NavBar';
import { FIXTURES_COLLECTIONS_TYPE } from '@/components/FixturesCollectionsTable/constants';
import { useGetFixturesCollection } from '@/service/fixturesCollection';

export const FixtureCollectionView = () => {
  const { data, isLoading } = useGetFixturesCollection();

  return (
    <Stack minHeight='100vh'>
      <NavBar>
        <Typography m={2} variant='body1' mr='auto'>
          Event Data
        </Typography>
      </NavBar>
      <Container sx={{ maxWidth: 'md', marginBlock: 4 }}>
        <Paper elevation={4} sx={{ marginBottom: 4 }}>
          <FixturesCollectionsTable
            type={FIXTURES_COLLECTIONS_TYPE.UPCOMING}
            collections={data ? data.upcomingCollections : []}
            isLoading={isLoading}
          />
        </Paper>
        <Paper elevation={4}>
          <FixturesCollectionsTable
            type={FIXTURES_COLLECTIONS_TYPE.PREVIOUS}
            collections={data ? data.previousCollections : []}
            isLoading={isLoading}
          />
        </Paper>
      </Container>
      <Box marginTop='auto'>
        <BottomBar />
      </Box>
    </Stack>
  );
};
