import { FC, MouseEventHandler, useRef } from 'react';
import { Stack } from '@mui/material';
import { Dialog } from '@/components/Dialog/Dialog';
import {
  PitchCoords,
  setCoords,
  setPartialOwnGoalMetadata,
} from '@/stores/ActionStore/ActionStore';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ATTEMPT_BY_LABEL,
  BODY_PARTS_LABEL,
  PATTERN_OF_PLAY_LABEL,
} from '@/constants';
import { AttemptType, BodyPart, PatternOfPlay } from '@contract';
import { postAction } from '@/service/helpers/postAction';
import { OwnGoalAction } from '@/types/action/action';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { Goal } from '../Common/Goal';
import { ShotPitch } from '../Common/ShotPitch';
import {
  BODY_PART,
  OWN_GOAL_DIALOG_TITLE,
  PATTERN_OF_PLAY,
} from '../constants';
import { Controls } from '../Common/Controls';

interface OwnGoalDialogProps {
  action: OwnGoalAction;
  onClose: VoidFunction;
}

const onPatternOfPlayChange = (patternOfPlay: PatternOfPlay) => {
  setPartialOwnGoalMetadata({ patternOfPlay });
};

const onBodyPartChange = (bodyPart: BodyPart) => {
  setPartialOwnGoalMetadata({ bodyPart });
};

export const OwnGoalDialog: FC<OwnGoalDialogProps> = ({ action, onClose }) => {
  const defaultAction = useRef(action);

  const onFormRightClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    onClose();
  };

  const onSubmit = () => {
    const ownGoalAction = structuredClone(action) as OwnGoalAction;

    setCoords(action?.metadata.ownGoal.position as PitchCoords);
    postAction(ownGoalAction);
    onClose();
  };

  if (!action?.metadata.ownGoal) return;

  return (
    <Dialog
      onRightClick={onFormRightClick}
      isOpen
      title={OWN_GOAL_DIALOG_TITLE}
    >
      <Stack direction='row' gap={2}>
        <Stack gap={2}>
          <Goal
            ballPosition={action.metadata.ownGoal.ballPosition}
            setPartialMetadata={setPartialOwnGoalMetadata}
            offTargetDisabled
          />
          <ShotPitch
            attemptType={AttemptType.TemptAttempt}
            position={action.metadata.ownGoal.position}
            setPartialMetadata={setPartialOwnGoalMetadata}
          />
        </Stack>
        <Stack gap={2}>
          <ActionPlayerField
            action={action}
            playerAutoCompleteProps={{ label: ATTEMPT_BY_LABEL }}
          />
          <PeriodClockField action={action} />
          <RadioButtons
            name='patternOfPlay'
            onChange={onPatternOfPlayChange}
            controlLabel={PATTERN_OF_PLAY_LABEL}
            fields={PATTERN_OF_PLAY}
            value={action?.metadata.ownGoal.patternOfPlay}
          />
          <RadioButtons
            name='bodyPart'
            onChange={onBodyPartChange}
            controlLabel={BODY_PARTS_LABEL}
            fields={BODY_PART}
            value={action?.metadata.ownGoal.bodyPart}
          />
          <Controls
            onClose={onClose}
            onSubmit={onSubmit}
            disabled={
              action &&
              JSON.stringify(action) === JSON.stringify(defaultAction.current)
            }
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
