import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { sendToken } from './SocketStore/SocketStore';

export type UserStore = {
  userId?: string;
  token?: string;
};
export const DEFAULT_STATE: UserStore = {
  userId: undefined,
  token: undefined,
};

export const useUserStore = create<UserStore>()(
  devtools(() => ({ ...DEFAULT_STATE }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'UserStore',
  }),
);

export function setUserId(userId: string) {
  return useUserStore.setState({ userId }, false, 'setUserId');
}
export function getUserId() {
  return useUserStore.getState().userId;
}
export function setAccessToken(token?: string) {
  sendToken(token);
  return useUserStore.setState({ token }, false, 'setAccessToken');
}
export function getCurrentAccessToken(): UserStore['token'] {
  return useUserStore.getState().token;
}
