import { Player } from '@/service/lineups';
import { ACTION_TYPE_ID } from '@contract';

export const MODE = {
  PITCH: 'pitch',
  PASS: 'pass',
  SHOT: 'shot',
  GOAL: 'goal',
  OWN_GOAL: 'ownGoal',
  THROW_IN: 'throwIn',
  CROSS: 'cross',
  LAUNCH: 'launch',
  GOALKEEPER_THROW: 'goalkeeperThrow',
  CARD: 'card',
  VAR_PENDING: 'varPending',
  VAR_DECISION: 'varDecision',
  SUBSTITUTION: 'substitution',
  OFFSIDE: 'offside',
  FREE_KICK: 'freeKick',
  PLAY_STOPPED: 'playStopped',
  GOALKEEPER_PENALTY_FACED: 'Goalkeeper Penalty Faced',
  GOALKEEPER_SAVE: 'Goalkeeper Save',
} as const;
export type Mode = ConstType<typeof MODE>;

export const PLAYER_REF_SYMBOL = Symbol('playerRef');

export type PlayerQuickInputMap = {
  [PLAYER_REF_SYMBOL]?: Player;
  [i: string]: PlayerQuickInputMap;
};

export type PassMode =
  | typeof MODE.PASS
  | typeof MODE.CROSS
  | typeof MODE.LAUNCH
  | typeof MODE.GOALKEEPER_THROW;

export const ACTION_OUTCOME_ONLY_SUCCESS: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.Shot,
];
export const ACTION_OUTCOME_DEFAULT_SUCCESS: ACTION_TYPE_ID[] = [
  ...ACTION_OUTCOME_ONLY_SUCCESS,
  ACTION_TYPE_ID.Pass,
  ACTION_TYPE_ID.Cross,
  ACTION_TYPE_ID.Launch,
  ACTION_TYPE_ID.BallTouch,
  ACTION_TYPE_ID.BadTouch,
  ACTION_TYPE_ID.ThrowInTaken,
  ACTION_TYPE_ID.GoalkeeperThrow,
  ACTION_TYPE_ID.GoalkeeperPunch,
  ACTION_TYPE_ID.Interception,
  ACTION_TYPE_ID.Clearance,
  ACTION_TYPE_ID.Tackle,
];
export const ACTION_OUTCOME_ONLY_NONE: ACTION_TYPE_ID[] = [
  ACTION_TYPE_ID.BallRecovery,
  ACTION_TYPE_ID.GoalkeeperSave,
  ACTION_TYPE_ID.GoalkeeperCatch,
  ACTION_TYPE_ID.GoalkeeperDrop,
  ACTION_TYPE_ID.GoalkeeperCollection,
  ACTION_TYPE_ID.GoalkeeperPenaltyFaced,
  ACTION_TYPE_ID.Goal,
  ACTION_TYPE_ID.OwnGoal,
  ACTION_TYPE_ID.Dispossessed,
  ACTION_TYPE_ID.Offside,
  ACTION_TYPE_ID.OffsideFor,
  ACTION_TYPE_ID.Card,
  ACTION_TYPE_ID.Substitution,
  ACTION_TYPE_ID.VARPending,
  ACTION_TYPE_ID.VARDecision,
  ACTION_TYPE_ID.BigChance,
  ACTION_TYPE_ID.PlayStopped,
  ACTION_TYPE_ID.PlayResumed,
  ACTION_TYPE_ID.AddedTime,
  ACTION_TYPE_ID.Block,
];
