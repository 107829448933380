import {
  Action,
  BadTouchAction,
  CardAction,
  CrossAction,
  FreeKickAwardedAction,
  GoalAction,
  GoalkeeperThrowAction,
  LaunchAction,
  PassAction,
  PlayStoppedAction,
  ShotAction,
  TackleAction,
  VARDecisionAction,
  VARPendingAction,
  type ClearanceAction,
  type InterceptionAction,
  GoalkeeperSaveAction,
  GoalkeeperPenaltyFacedAction,
  type ThrowInTakenAction,
} from '@/types/action/action';
import { ACTION_TYPE } from '@/types/action/actionType';
import {
  ACTION_TYPE_ID,
  Extras,
  CardType,
  CardReason,
  BodyPart,
} from '@contract';
import { PlayerName } from '@/components/PlayerName/PlayerName';
import { ATTEMPT_TYPE, TAKEN_EXTRAS } from '@/components/Dialogs/constants';
import { sortByActionTimes } from '@/utils/sortByActionTimes';
import { getActionMetadata } from '@/utils/actions';
import { camelCaseToSpace } from '@/utils/string';
import { VAR_PENDING_REASON } from '@/components/Dialogs/VARPendingDialog/constants';
import { VAR_DECISION } from '@/components/Dialogs/VARDecisionDialog/constants';
import {
  FREE_KICK_TYPE,
  FreeKickType,
} from '@/components/Dialogs/FreeKickDialog/constants';
import { PLAY_STOPPED_REASON } from '@/components/Dialogs/PlayStoppedDialog/constants';
import { ASSIST_LABEL, HEADED_LABEL, OPPOSITE_TEAM_LABEL } from '@/constants';
import {
  GOALKEEPER_SAVE_BODY_PART,
  GOALKEEPER_SAVE_EXTRAS,
  GOALKEEPER_SAVE_STANCE,
} from '@/components/Dialogs/GoalkeeperSaveDialog/constants';
import { GOALKEEPER_PENALTY_FACED_STANCE } from '@/components/Dialogs/GoalkeeperPenaltyFacedDialog/constants';
import type { FixtureStore, TableAction } from './FixtureStore';
import { ACTION_STATE } from './constants';

export function createTableActionsFromActions(
  actions: FixtureStore['actions'],
): FixtureStore['tableActions'] {
  return [...actions.values()]
    .map(([latestAction]) => latestAction)
    .filter(({ isRemoved }) => !isRemoved)
    .sort(sortByActionTimes)
    .map(mapToTableAction);
}

export function mapActionBase(action: Action): TableAction {
  const {
    messageId,
    actionId,
    actionTypeId,
    clockTime,
    clockTimeTicks,
    createdAt,
    isRemoved,
    warnings,
    state,
    player,
    updatedAt,
    isSuccessful,
  } = action;
  return {
    messageId: messageId ?? undefined,
    typeId: actionTypeId,
    type: ACTION_TYPE[actionTypeId],
    time: clockTime,
    clockTimeTicks: clockTimeTicks,
    createdAt,
    updatedAt,
    player: player ?? undefined,
    targetPlayer: undefined,
    extras: undefined,
    actionId,
    isRemoved,
    warnings,
    state: state ?? ACTION_STATE.NONE,
    isSuccessful: isSuccessful,
  };
}

export function mapPass(
  action: PassAction | LaunchAction | CrossAction | GoalkeeperThrowAction,
): TableAction {
  const metadata = getActionMetadata(action);

  const { targetPlayer, isAssist, bodyPart } = metadata;
  const extras = [];
  if (metadata.actionContext !== null) {
    extras.push(TAKEN_EXTRAS[metadata.actionContext]);
  }
  if (bodyPart === BodyPart.Head) {
    extras.push(HEADED_LABEL);
  }
  if (isAssist) {
    extras.push(ASSIST_LABEL);
  }

  return {
    ...mapActionBase(action),
    targetPlayer: targetPlayer ?? undefined,
    extras: extras,
  };
}

export function mapThrowInTaken(action: ThrowInTakenAction): TableAction {
  const metadata = getActionMetadata(action);

  const { targetPlayer } = metadata;

  return {
    ...mapActionBase(action),
    targetPlayer: targetPlayer ?? undefined,
  };
}

export function mapShot(shot: ShotAction): TableAction {
  const { attemptType, assistPlayer, extras: metaExtras } = shot.metadata.shot;
  const extras: TableAction['extras'] = [ATTEMPT_TYPE[attemptType]];

  if (assistPlayer) {
    extras.push({
      key: assistPlayer.id,
      label: (
        <>
          {`${ASSIST_LABEL}: `}
          <PlayerName player={assistPlayer} />
        </>
      ),
    });
  }
  if (metaExtras) {
    metaExtras.forEach(
      (extra) => {
        extras.push(Extras[extra]);
      },
      [extras],
    );
  }

  return {
    ...mapActionBase(shot),
    extras: extras,
  };
}

export function mapGoal(goal: GoalAction): TableAction {
  const { assistPlayer, extras: metaExtras } = goal.metadata.goal;
  const extras: TableAction['extras'] = [];

  if (assistPlayer) {
    extras.push({
      key: assistPlayer.id,
      label: (
        <>
          {`${ASSIST_LABEL}: `}
          <PlayerName player={assistPlayer} />
        </>
      ),
    });
  }
  if (metaExtras) {
    metaExtras.forEach(
      (extra) => {
        extras.push(Extras[extra]);
      },
      [extras],
    );
  }

  return {
    ...mapActionBase(goal),
    extras,
  };
}

function mapCardAction(cardAction: CardAction) {
  const tableAction = mapActionBase(cardAction);
  const metadata = getActionMetadata(cardAction);
  const extras = [];
  if (metadata.outcome !== null && metadata.outcome !== undefined) {
    extras.push(camelCaseToSpace(CardType[metadata.outcome]));
  }
  if (metadata.reason !== null && metadata.reason !== undefined) {
    extras.push(camelCaseToSpace(CardReason[metadata.reason]));
  }

  return {
    ...tableAction,
    extras,
  };
}

function mapVARPending(varAction: VARPendingAction): TableAction {
  const tableAction: TableAction = mapActionBase(varAction);
  const reason = varAction.metadata.varPending.reason;
  if (reason !== null) {
    tableAction.extras = VAR_PENDING_REASON[reason];
  }
  return tableAction;
}

function mapVARDecision(varAction: VARDecisionAction): TableAction {
  const tableAction: TableAction = mapActionBase(varAction);
  const outcome = varAction.metadata.varDecision.outcome;
  if (outcome !== null) {
    tableAction.extras = VAR_DECISION[outcome];
  }
  return tableAction;
}

function mapFreeKick(freeKickAction: FreeKickAwardedAction): TableAction {
  const tableAction: TableAction = mapActionBase(freeKickAction);
  const isPenalty = freeKickAction.metadata.freeKickAwarded.isPenalty;
  if (isPenalty) {
    tableAction.extras = FREE_KICK_TYPE[FreeKickType.Penalty];
  }
  return tableAction;
}

function mapActionIsAssistOnly(
  action: TackleAction | BadTouchAction,
): TableAction {
  const tableAction: TableAction = mapActionBase(action);
  const metadata = getActionMetadata(action);

  const { isAssist } = metadata;

  if (isAssist) {
    tableAction.extras = [ASSIST_LABEL];
  }

  return tableAction;
}

function mapPlayStopped(playStoppedAction: PlayStoppedAction) {
  const tableAction: TableAction = mapActionBase(playStoppedAction);
  const { reason, isFromOppositeTeam } = getActionMetadata(playStoppedAction);

  tableAction.extras = [];

  if (reason !== null) {
    tableAction.extras.push(PLAY_STOPPED_REASON[reason]);
  }
  if (isFromOppositeTeam) {
    tableAction.extras.push(OPPOSITE_TEAM_LABEL);
  }

  return tableAction;
}
function mapClearance(action: ClearanceAction) {
  const tableAction = mapActionBase(action);
  const isHeaded = getActionMetadata(action).bodyPart === BodyPart.Head;
  if (isHeaded) {
    tableAction.extras = [HEADED_LABEL];
  }
  return tableAction;
}

function mapInterception(action: InterceptionAction) {
  const tableAction = mapActionBase(action);
  const isHeaded = getActionMetadata(action).bodyPart === BodyPart.Head;
  if (isHeaded) {
    tableAction.extras = [HEADED_LABEL];
  }
  return tableAction;
}

function mapGoalkeeperSave(goalkeeperSaveAction: GoalkeeperSaveAction) {
  const tableAction: TableAction = mapActionBase(goalkeeperSaveAction);
  const {
    type,
    extras: metaExtras,
    bodyPart,
  } = getActionMetadata(goalkeeperSaveAction);

  const extras: TableAction['extras'] = [];

  if (bodyPart !== null) {
    extras.push(GOALKEEPER_SAVE_BODY_PART[bodyPart]);
  }

  if (type !== null) {
    extras.push(GOALKEEPER_SAVE_STANCE[type]);
  }

  if (metaExtras !== null) {
    metaExtras.forEach((extra) => {
      extras.push(GOALKEEPER_SAVE_EXTRAS[extra]);
    });
  }

  return {
    ...tableAction,
    extras,
  };
}

function mapGoalkeeperPenaltyFaced(
  goalkeeperPenaltyFacedAction: GoalkeeperPenaltyFacedAction,
) {
  const tableAction: TableAction = mapActionBase(goalkeeperPenaltyFacedAction);
  const { stance } = getActionMetadata(goalkeeperPenaltyFacedAction);

  tableAction.extras = [];

  if (stance) {
    tableAction.extras.push(GOALKEEPER_PENALTY_FACED_STANCE[stance]);
  }

  return tableAction;
}

export function mapToTableAction(action: Action): TableAction {
  switch (action.actionTypeId) {
    case ACTION_TYPE_ID.Pass:
    case ACTION_TYPE_ID.Cross:
    case ACTION_TYPE_ID.Launch:
    case ACTION_TYPE_ID.GoalkeeperThrow:
      return mapPass(action);
    case ACTION_TYPE_ID.Shot:
      return mapShot(action);
    case ACTION_TYPE_ID.Goal:
      return mapGoal(action);
    case ACTION_TYPE_ID.Card:
      return mapCardAction(action);
    case ACTION_TYPE_ID.VARPending:
      return mapVARPending(action);
    case ACTION_TYPE_ID.VARDecision:
      return mapVARDecision(action);
    case ACTION_TYPE_ID.FreeKickAwarded:
      return mapFreeKick(action);
    case ACTION_TYPE_ID.Tackle:
    case ACTION_TYPE_ID.BadTouch:
      return mapActionIsAssistOnly(action);
    case ACTION_TYPE_ID.Clearance:
      return mapClearance(action);
    case ACTION_TYPE_ID.Interception:
      return mapInterception(action);
    case ACTION_TYPE_ID.PlayStopped:
      return mapPlayStopped(action);
    case ACTION_TYPE_ID.GoalkeeperSave:
      return mapGoalkeeperSave(action);
    case ACTION_TYPE_ID.GoalkeeperPenaltyFaced:
      return mapGoalkeeperPenaltyFaced(action);
    case ACTION_TYPE_ID.ThrowInTaken:
      return mapThrowInTaken(action);
    default:
      return mapActionBase(action);
  }
}
