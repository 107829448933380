import { FC } from 'react';
import type { Action } from '@/types/action/action';
import { setAction } from '@/stores/ActionStore/ActionStore';
import {
  PlayerAutocomplete,
  type PlayerAutocompleteProps,
} from '@/components/Form/PlayerAutocomplete';
import { PLAYER_LABEL } from '@/constants';
import type { Player } from '@/service/lineups';

type ActionPlayerFieldProps = {
  action: Action;
  playerAutoCompleteProps?: Partial<PlayerAutocompleteProps>;
};

export const ActionPlayerField: FC<ActionPlayerFieldProps> = ({
  action,
  playerAutoCompleteProps,
}) => {
  if (!action.player) return;

  const onPlayerChange = (player: Player | null) => {
    if (!player) return;
    setAction({
      ...action,
      player,
    });
  };

  return (
    <PlayerAutocomplete
      player={action.player}
      onChange={onPlayerChange}
      label={PLAYER_LABEL}
      {...playerAutoCompleteProps}
    />
  );
};
