import {
  Checkbox,
  CheckboxProps,
  Chip,
  FormControlLabel,
  FormLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { ASSIST_LABEL } from '@/constants';
import { getRelativePreviousAction } from '@/stores/FixtureStore';
import { isPositiveOutcome } from '@/utils/actions';
import { ActionChip } from '@/components/ActionChip/ActionChip';
import { PlayerName } from '@/components/PlayerName/PlayerName';
import { Action } from '@/types/action/action';
import type { PlayerDto } from '@contract';

export const ASSIST_CHECKBOX_NAME = 'is-assisted-by';

const DisabledTooltip: FC = () => {
  return (
    <Tooltip
      sx={{ justifySelf: 'flex-end' }}
      title={
        <>
          <Typography variant='body2'>The last action is LOST.</Typography>
          <Typography variant='body2'>
            You cannot add an assist until the outcome of previous action is
            WON.
          </Typography>
        </>
      }
    >
      <InfoOutlined />
    </Tooltip>
  );
};

const SamePlayerTooltip: FC = () => {
  return (
    <Tooltip
      sx={{ justifySelf: 'flex-end' }}
      title={
        <Typography variant='body2'>
          The same player made previous action.
        </Typography>
      }
    >
      <InfoOutlined />
    </Tooltip>
  );
};

export type AssistFieldProps = {
  action?: Action;
  controlLabel: string;
  player: PlayerDto | null;
  assistPlayer: PlayerDto | null;
  onChange: (assistPlayer: PlayerDto | null) => void;
};

export const AssistField: FC<AssistFieldProps> = ({
  action,
  player,
  assistPlayer,
  controlLabel,
  onChange,
}) => {
  const previousAction = getRelativePreviousAction(
    action ? action.actionId : null,
  );

  const isPreviousActionPositive = previousAction
    ? isPositiveOutcome(previousAction)
    : false;
  const previousActionPlayer = previousAction && previousAction.player;

  const handleChange: CheckboxProps['onChange'] = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? previousActionPlayer : null;

    onChange(value);
  };

  const isDisabled = (() => {
    if (assistPlayer) return false;
    if (
      isPreviousActionPositive &&
      previousActionPlayer &&
      player &&
      previousActionPlayer.id !== player.id
    )
      return false;

    return true;
  })();

  const error = (() => {
    if (!assistPlayer) return false;
    if (!isPreviousActionPositive) {
      return 'The assist player is defined but previous action is LOST.';
    }
    if (previousActionPlayer && assistPlayer.id !== previousActionPlayer.id) {
      return 'Assist player does not match previous action player.';
    }
    return false;
  })();

  useEffect(() => {
    if (player && assistPlayer && player.id === assistPlayer.id) {
      onChange(null);
    }
  }, [player, assistPlayer, onChange]);

  const InfoTooltip = () => {
    if (!isDisabled) return;
    if (
      previousActionPlayer &&
      player &&
      previousActionPlayer.id === player.id
    ) {
      return <SamePlayerTooltip />;
    }
    return <DisabledTooltip />;
  };

  return (
    <Stack>
      <FormLabel error={!!error}>{controlLabel}</FormLabel>
      {!!error && (
        <Typography variant='body2' color='error'>
          {error}
        </Typography>
      )}
      <Stack direction='row' alignItems='center'>
        <FormControlLabel
          label={ASSIST_LABEL}
          disabled={isDisabled}
          control={
            <Checkbox
              name={ASSIST_CHECKBOX_NAME}
              aria-label={ASSIST_LABEL}
              checked={!!assistPlayer}
              onChange={handleChange}
            />
          }
        />
        {!!error && <Chip label={<PlayerName player={assistPlayer} />} />}
        <InfoTooltip />
      </Stack>

      <Typography variant='caption'>Previous action</Typography>
      {previousAction && <ActionChip action={previousAction} />}
    </Stack>
  );
};
