import { FC, MouseEventHandler, useRef } from 'react';
import { Stack } from '@mui/material';
import { Dialog } from '@/components/Dialog/Dialog';
import {
  PitchCoords,
  setCoords,
  setPartialGoalMetadata,
  setRecentlyAwardedAction,
  updatePreviousPassIsAssist,
} from '@/stores/ActionStore/ActionStore';
import { createDefaultAction } from '@/stores/ActionStore/utils';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ASSIST_LABEL,
  ATTEMPT_BY_LABEL,
  BODY_PARTS_LABEL,
  EXTRAS_LABEL,
  PATTERN_OF_PLAY_LABEL,
} from '@/constants';
import { CheckboxGroup } from '@/components/Form/CheckboxGroup';
import { GoalAction, ShotAction } from '@/types/action/action';
import {
  ACTION_TYPE_ID,
  AttemptType,
  BodyPart,
  Extras,
  METADATA_KEY,
  PatternOfPlay,
} from '@contract';
import { postAction } from '@/service/helpers/postAction';
import { getRelativePreviousAction } from '@/stores/FixtureStore';
import { getActionMetadata } from '@/utils/actions';
import { goalExtras } from '@/components/Dialogs/GoalDialog/constants';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { Goal } from '../Common/Goal';
import { ShotPitch } from '../Common/ShotPitch';
import { BODY_PART, GOAL_DIALOG_TITLE, PATTERN_OF_PLAY } from '../constants';
import { AssistField } from '../Common/AssistField';
import { Controls } from '../Common/Controls';
import { ActionPlayerField } from '../Common/ActionPlayerField';

interface GoalDialogProps {
  action: GoalAction;
  onClose: VoidFunction;
}

const onPatternOfPlayChange = (patternOfPlay: PatternOfPlay) => {
  setPartialGoalMetadata({ patternOfPlay });
};

const onBodyPartChange = (bodyPart: BodyPart) => {
  setPartialGoalMetadata({ bodyPart });
};

const onExtrasChange = (extras: Extras[]) => {
  setPartialGoalMetadata({ extras });
};

const onAssistPlayerChange = (
  assistPlayer: ActionMeta<GoalAction>['assistPlayer'],
) => {
  setPartialGoalMetadata({ assistPlayer });
};

export const GoalDialog: FC<GoalDialogProps> = ({ action, onClose }) => {
  const defaultAction = useRef(action);
  const previousAction = action.messageId
    ? getRelativePreviousAction(action.actionId)
    : null;
  const shotAction =
    previousAction && previousAction.actionTypeId === ACTION_TYPE_ID.Shot
      ? previousAction
      : null;

  const onFormRightClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    onClose();
  };

  const onSubmit = () => {
    const goalAction = action;

    if (!goalAction.messageId) {
      const defaultShotAction = createDefaultAction<ShotAction>(
        ACTION_TYPE_ID.Shot,
        METADATA_KEY.shot,
      );
      if (!defaultShotAction) return;
      const goalMeta = getActionMetadata(goalAction);

      const shotAction: ShotAction = {
        ...defaultShotAction,
        clockTime: goalAction.clockTime,
        clockTimeTicks: goalAction.clockTimeTicks,
        player: goalAction.player,
        createdAt: goalAction.createdAt - 1,
        metadata: {
          shot: {
            attemptType: AttemptType.OnTarget,
            assistPlayer: goalMeta.assistPlayer,
            ballPosition: goalMeta.ballPosition,
            blockersPositions: goalMeta.blockersPositions,
            bodyPart: goalMeta.bodyPart,
            extras: goalMeta.extras,
            patternOfPlay: goalMeta.patternOfPlay,
            position: goalMeta.position,
          },
        },
      };

      postAction(shotAction);
      updatePreviousPassIsAssist(shotAction);
    }

    setCoords(action.metadata.goal.position as PitchCoords);
    postAction(goalAction);
    if (!goalAction.messageId) {
      setRecentlyAwardedAction(null);
    }
    onClose();
  };

  if (!action.metadata.goal) return;

  return (
    <Dialog onRightClick={onFormRightClick} isOpen title={GOAL_DIALOG_TITLE}>
      <Stack direction='row' gap={2}>
        <Stack gap={2}>
          <Goal
            ballPosition={action.metadata.goal.ballPosition}
            setPartialMetadata={setPartialGoalMetadata}
            offTargetDisabled
          />
          <ShotPitch
            position={action.metadata.goal.position}
            blockersPositions={action.metadata.goal.blockersPositions}
            attemptType={AttemptType.OnTarget}
            setPartialMetadata={setPartialGoalMetadata}
          />
        </Stack>
        <Stack gap={2}>
          <ActionPlayerField
            playerAutoCompleteProps={{ label: ATTEMPT_BY_LABEL }}
            action={action}
          />
          <AssistField
            action={shotAction || undefined}
            controlLabel={ASSIST_LABEL}
            onChange={onAssistPlayerChange}
            player={action.player}
            assistPlayer={action.metadata.goal.assistPlayer}
          />
          <PeriodClockField action={action} />
          <RadioButtons
            name='patternOfPlay'
            onChange={onPatternOfPlayChange}
            controlLabel={PATTERN_OF_PLAY_LABEL}
            fields={PATTERN_OF_PLAY}
            value={action?.metadata.goal.patternOfPlay}
          />
          <RadioButtons
            name='bodyPart'
            onChange={onBodyPartChange}
            controlLabel={BODY_PARTS_LABEL}
            fields={BODY_PART}
            value={action?.metadata.goal.bodyPart}
          />
          <CheckboxGroup
            controlLabel={EXTRAS_LABEL}
            fields={goalExtras}
            onChange={onExtrasChange}
            values={action?.metadata.goal.extras || []}
          />
          <Controls
            onClose={onClose}
            onSubmit={onSubmit}
            disabled={
              action &&
              JSON.stringify(action) === JSON.stringify(defaultAction.current)
            }
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
