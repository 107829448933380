import useSWR from 'swr';
import { DataCollectionConfigDto } from '@contract';
import { setPeriodDetails } from '@/stores/FixtureStore';
import { useAuthToken } from '@/service/hooks/authToken';
import { SERVICE_ENDPOINT } from '@/service/constants/endpoint';
import { fetchData } from '@/service/fetchData';
import { useCurrentCollection } from '@/stores/CollectionStore';

export function useGetDataCollectionConfig() {
  const token = useAuthToken();
  const collection = useCurrentCollection();

  const output = useSWR<DataCollectionConfigDto>(
    SERVICE_ENDPOINT.DataCollectionConfig.get(collection ? collection.id : ''),
    (url: string) =>
      fetchData({ url, token }).then((response: DataCollectionConfigDto) => {
        setPeriodDetails(response.fixtureOptions.sportRules.periodDetails);
        return response;
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  return output;
}
