import { HubConnectionState } from '@microsoft/signalr';
import {
  ActionAddedMsg,
  ActionsReceivedMsg,
  ActionsSubscribedMsg,
  SCORING_WORKER_ACTION,
  SCORING_WORKER_HOST_ACTION,
  ScoringWorkerMsgEvent,
  WebSocketConnectionMsg,
  WorkerReadyMsg,
  type LineupsSubscribedMsg,
} from '@/workers/scoring/types';
import {
  storeAction,
  setActions,
  setTableActions,
  useFixtureStore,
  setTeamPossessionPhase,
  getInitialPossessionPhaseId,
  setCollectionState,
  checkUnresolvedAwardedActions,
} from '@/stores/FixtureStore';
import { sortByCreatedAt } from '@/utils/sortByActionTimes';
import { isDebugEnabled } from '@/utils/debug';
import type { FixtureState, SaveLineupDto } from '@contract';
import { createTableActionsFromActions } from '../tableActionMapper';
import {
  getBackedUpActions,
  cleanSyncedActions,
} from '../UnsyncedActionsStore';
import {
  composeLineup,
  setLineup,
  useLineupStore,
} from '../LineupStore/LineupStore';
import {
  setActionsSubscribed,
  setIsLineupsInSync,
  setIsWorkerReady,
  setLineupsSubscribed,
  setWSConnection,
  useSocketStore,
} from './SocketStore';

export function onWorkerReady(message: WorkerReadyMsg) {
  setIsWorkerReady(message.payload.isWorkerReady);
}

export function onWsConnection(message: WebSocketConnectionMsg) {
  const collectionId = useFixtureStore.getState().collectionId;
  const fixtureId = useFixtureStore.getState().fixtureId;
  const teamId = useLineupStore.getState().teamId;
  const worker = useSocketStore.getState().worker;

  setWSConnection(message.payload.wsConnection);

  if (message.payload.wsConnection !== HubConnectionState.Connected) return;
  if (!collectionId) return;
  worker.postMessage({
    action: SCORING_WORKER_HOST_ACTION.FIXTURE_ID,
    payload: { fixtureId: collectionId },
  });
  if (!teamId) return;
  worker.postMessage({
    action: SCORING_WORKER_HOST_ACTION.LINEUPS_SUBSCRIBE,
    payload: { fixtureId: collectionId, teamId: teamId },
  });
  if (!fixtureId) return;
}

export function onActionsSubscribed({ payload }: ActionsSubscribedMsg) {
  setActionsSubscribed(payload.actionsSubscribed);
}
export function onLineupsSubscribed({ payload }: LineupsSubscribedMsg) {
  setLineupsSubscribed(payload.lineupsSubscribed);
}

export function onActionsReceived({ payload }: ActionsReceivedMsg) {
  const actions = new Map(payload.actions);

  cleanSyncedActions(actions);

  const backupActions = getBackedUpActions();
  for (const backup of backupActions) {
    const matchedAction = actions.get(backup.actionId);
    if (!matchedAction) {
      actions.set(backup.actionId, [backup]);
      continue;
    }
    actions.set(
      backup.actionId,
      [backup, ...matchedAction].sort(sortByCreatedAt),
    );
  }

  setTeamPossessionPhase(getInitialPossessionPhaseId(actions));
  setActions(actions);
  setTableActions(createTableActionsFromActions(actions));
  checkUnresolvedAwardedActions();
}

export function onActionAdded({ payload }: ActionAddedMsg) {
  storeAction(payload.action);
  return false;
}

export function onLineupsModified(payload: SaveLineupDto | null) {
  setIsLineupsInSync(true);
  if (payload !== null) {
    setLineup(composeLineup(payload));
  }
}
export function onLineupsReceived(payload: SaveLineupDto | null) {
  setIsLineupsInSync(true);
  if (payload === null) {
    return setLineup([]);
  }
  return setLineup(composeLineup(payload));
}

export function onStateReceived(payload: FixtureState) {
  setCollectionState(payload);
}

export function onStateModified(payload: FixtureState) {
  setCollectionState(payload);
}

export function onWorkerMessage({ data }: ScoringWorkerMsgEvent) {
  isDebugEnabled() && console.log(`workerMsg->${data.action}`, data.payload);
  switch (data.action) {
    case SCORING_WORKER_ACTION.ACTION_ADDED:
      return onActionAdded(data);
    case SCORING_WORKER_ACTION.ACTIONS_SUBSCRIBED:
      return onActionsSubscribed(data);
    case SCORING_WORKER_ACTION.LINEUPS_SUBSCRIBED:
      return onLineupsSubscribed(data);
    case SCORING_WORKER_ACTION.WS_CONNECTION:
      return onWsConnection(data);
    case SCORING_WORKER_ACTION.ACTIONS_RECEIVED:
      return onActionsReceived(data);
    case SCORING_WORKER_ACTION.WORKER_READY:
      return onWorkerReady(data);
    case SCORING_WORKER_ACTION.LINEUP_MODIFIED:
      return onLineupsModified(data.payload);
    case SCORING_WORKER_ACTION.LINEUP_RECEIVED:
      return onLineupsReceived(data.payload);
    case SCORING_WORKER_ACTION.STATE_RECEIVED:
      return onStateReceived(data.payload);
    case SCORING_WORKER_ACTION.STATE_MODIFIED:
      return onStateModified(data.payload);
    default:
      break;
  }
}
