import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import { toggleWarning } from '@/components/ActionWarnings/helpers';
import { postAction } from '@/service/helpers/postAction';
import {
  Action,
  BadTouchAction,
  CrossAction,
  GoalkeeperThrowAction,
  LaunchAction,
  PassAction,
  ShotAction,
  TackleAction,
  type ClearanceAction,
  type InterceptionAction,
} from '@/types/action/action';
import { getActionMetadata, isPositiveOutcome } from '@/utils/actions';
import { BodyPart } from '@contract';

export async function toggleFlagToChecker(action: Action) {
  const newAction = structuredClone(action);

  newAction.warnings = toggleWarning(
    action.warnings,
    ACTION_WARNING.FLAG_TO_QA,
  );
  return postAction(newAction);
}

export async function togglePassAssist(
  action:
    | PassAction
    | CrossAction
    | LaunchAction
    | TackleAction
    | BadTouchAction
    | GoalkeeperThrowAction,
) {
  const newAction = structuredClone(action);
  const metadata = getActionMetadata(newAction);
  metadata.isAssist = !metadata.isAssist;

  return postAction(newAction);
}

export async function toggleHeaded(
  action: PassAction | ClearanceAction | InterceptionAction,
) {
  const newAction = structuredClone(action);
  const metadata = getActionMetadata(newAction);
  const newBodyPart =
    metadata.bodyPart === BodyPart.Head ? null : BodyPart.Head;
  metadata.bodyPart = newBodyPart;

  return postAction(newAction);
}

export async function updateShotAssist(
  action: ShotAction,
  assistPlayer: ActionMeta<ShotAction>['assistPlayer'],
) {
  const updateShot: ShotAction = structuredClone(action);
  updateShot.metadata.shot.assistPlayer = assistPlayer;
  return postAction(updateShot);
}

export function useUpdateShotAssist(
  action: ShotAction,
  previousAction: Action | null,
) {
  const currentAssistPlayer = action.metadata.shot.assistPlayer;
  const previousActionPlayer = previousAction && previousAction.player;

  const isPreviousActionPosistiveOutcome =
    !!previousAction && isPositiveOutcome(previousAction);

  const canUpdateAssist = (() => {
    if (
      isPreviousActionPosistiveOutcome &&
      currentAssistPlayer &&
      previousActionPlayer &&
      currentAssistPlayer.id === previousActionPlayer.id
    ) {
      return false;
    }
    if (!isPreviousActionPosistiveOutcome && currentAssistPlayer === null) {
      return false;
    }
    return true;
  })();

  const updatePlayer = isPreviousActionPosistiveOutcome
    ? previousActionPlayer
    : null;

  return {
    canUpdateAssist,
    updatePlayer,
    updateAssist: async () => {
      if (!canUpdateAssist) return;
      return updateShotAssist(action, updatePlayer);
    },
  };
}
