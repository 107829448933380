import { FC } from 'react';
import { Stack } from '@mui/material';
import { TableAction } from '@/stores/FixtureStore';
import { COLUMNS, TABLE_CLASS_NAMES } from './constants';
import { ActionTableCell } from './ActionsTableCell';

interface ActionsTableRowProps {
  action: TableAction;
}

export const ActionsTableRow: FC<ActionsTableRowProps> = ({ action }) => {
  return (
    <Stack
      className={`${TABLE_CLASS_NAMES.ROW}`}
      direction='row'
      data-action-state={action.state}
      data-action-id={action.actionId}
      sx={{
        backgroundColor: () => (action.isRemoved ? '#262626' : ''),
        color: () => (action.isRemoved ? '#979797' : ''),
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:hover': {
          backgroundColor: (theme) => theme.palette.action.hover,
        },
      }}
    >
      {COLUMNS.map((column) => (
        <ActionTableCell key={column.id} action={action} column={column} />
      ))}
    </Stack>
  );
};
