import { Outlet, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ROUTE_PARAM } from './router/constants';
import { setCollectionId } from './stores/FixtureStore';

export function App() {
  const collectionId = useParams()[ROUTE_PARAM.COLLECTION_ID];

  useEffect(() => {
    setCollectionId(collectionId);
  }, [collectionId]);

  return <Outlet />;
}
