import { FC } from 'react';
import { PeriodDto } from '@contract';
import { TimeSelect } from '@/components/Form/TimeSelect';
import { TIME_LABEL } from '@/constants';
import { setAction } from '@/stores/ActionStore/ActionStore';
import type { Action } from '@/types/action/action';
import { timeStringToSeconds } from '@/utils/timeStringToSeconds';

type ClockTimeFieldProps = {
  action: Action;
};

export const PeriodClockField: FC<ClockTimeFieldProps> = ({ action }) => {
  const onPeriodChange = (period: PeriodDto | null) => {
    setAction({
      ...action,
      period,
    });
  };

  const onClockTimeChange = (clockTime: string) => {
    setAction({
      ...action,
      clockTime,
      clockTimeTicks: timeStringToSeconds(clockTime),
    });
  };

  return (
    <TimeSelect
      onClockTimeChange={onClockTimeChange}
      period={action.period}
      onPeriodChange={onPeriodChange}
      controlLabel={TIME_LABEL}
      clockTime={action.clockTime}
    />
  );
};
