import { Chip, Typography } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { PITCH_PLAYERS_MAX_LEN } from '@/stores/LineupStore/constants';

export const SquadLength = () => {
  const squadLength = useLineupStore((state) => state.pitchPlayersLenght);
  const isGKSelected = useLineupStore((state) => state.isGKSelected);
  const requiredPlayers = PITCH_PLAYERS_MAX_LEN - squadLength;

  const isGKRequired = !requiredPlayers && !isGKSelected;
  const isSuccess = !requiredPlayers && isGKSelected;

  if (requiredPlayers) {
    return <Typography>{requiredPlayers}</Typography>;
  }
  if (isGKRequired) {
    return <Chip icon={<ErrorOutline />} label={'GK Required'} color='error' />;
  }
  if (isSuccess) {
    return <CheckCircle color='success' />;
  }
};
