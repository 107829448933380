import { ACTION_TYPE_ID, ActionWarning } from '@contract';
import { Action, PassAction } from '@/types/action/action';
import {
  isPassSamePlayerAction,
  isPassSamePlayerWarning,
} from '@/utils/actions';
import { ACTION_WARNING } from './constants';

export function getPassWarnings(action: PassAction): ActionWarning[] | null {
  let newWarnings: ActionWarning[] | null = action.warnings
    ? [...action.warnings]
    : [];

  if (
    isPassSamePlayerAction(action) &&
    !newWarnings.some(isPassSamePlayerWarning)
  ) {
    newWarnings.push(ACTION_WARNING.PASS_SAME_PLAYER);
  } else {
    newWarnings = newWarnings.filter(
      (warning) => !isPassSamePlayerWarning(warning),
    );
  }
  if (newWarnings.length === 0) newWarnings = null;
  return newWarnings;
}

export function getActionWarnings(action: Action): ActionWarning[] | null {
  switch (action.actionTypeId) {
    case ACTION_TYPE_ID.Pass:
      return getPassWarnings(action);
    default:
      return action.warnings;
  }
}

export function toggleWarning(
  originalWarnings: Action['warnings'],
  warning: ActionWarning,
) {
  let warnings = originalWarnings ? [...originalWarnings] : null;

  if (warnings === null) {
    warnings = [warning];
  } else if (warnings.some((w) => w.type === warning.type)) {
    warnings = warnings.filter((w) => w.type !== warning.type);
  } else {
    warnings.push(warning);
  }

  return warnings;
}
