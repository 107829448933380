type ActionSortProps = {
  clockTimeTicks: number;
  createdAt: number;
  updatedAt: number;
};

export function sortByActionTimes(a: ActionSortProps, b: ActionSortProps) {
  if (b.clockTimeTicks > a.clockTimeTicks) return 1;
  if (b.clockTimeTicks < a.clockTimeTicks) return -1;
  return b.createdAt - a.createdAt;
}

export function sortByCreatedAt(a: ActionSortProps, b: ActionSortProps) {
  return b.createdAt - a.createdAt;
}
export function sortByUpdatedAt(a: ActionSortProps, b: ActionSortProps) {
  return b.updatedAt - a.updatedAt;
}
