import {
  Stack,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import { FC, useMemo } from 'react';
import {
  useEventHistoryStore,
  setFiltersData,
} from '@/stores/EventHistoryStore';
import { useSimpleReducer } from '@/utils/useSimpleReducer';
import { makeTablePlayerName } from '@/stores/utils';
import { ACTION_TYPE_ID, Extras } from '@contract';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  OUTCOME_TYPE,
  OUTCOME_OPTION_LABEL,
} from '@/components/ActionOutcome/constants';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { DEFAULT_FILTER_VALUE, FilterValue, FILTER_NAME } from './constants';

export interface FilterFormProps {
  onClose: VoidFunction;
}

const extrasOptions: Extras[] = Object.keys(Extras)
  .filter((key) => isNaN(parseInt(key)))
  .sort()
  .map((key) => Extras[key as keyof typeof Extras]);

const eventOptions: ACTION_TYPE_ID[] = Object.keys(ACTION_TYPE_ID)
  .filter((key) => isNaN(parseInt(key)))
  .sort()
  .map((key) => ACTION_TYPE_ID[key as keyof typeof ACTION_TYPE_ID]);

export const FilterForm: FC<FilterFormProps> = ({ onClose }) => {
  const lineup = useLineupStore((state) => state.lineup);
  const storeFiltersData = useEventHistoryStore((state) => state.filterValues);

  const [selectedFiltersValues, setSelectedFiltersValues] =
    useSimpleReducer<FilterValue>(storeFiltersData);

  const playerOptions = useMemo(() => {
    return lineup.map(({ player }) => player);
  }, [lineup]);

  const handleSubmit = () => {
    setFiltersData(selectedFiltersValues);
    onClose();
  };

  const clearFilters = () => {
    setSelectedFiltersValues(DEFAULT_FILTER_VALUE);
  };

  const resetFilters = () => {
    setSelectedFiltersValues(storeFiltersData);
  };

  return (
    <Stack gap={2}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>Filters</Typography>
        <Stack direction='row' gap={2}>
          <Button onClick={resetFilters} variant='outlined' color='primary'>
            Restore
          </Button>
          <Button onClick={clearFilters} variant='outlined' color='primary'>
            Clear all
          </Button>
        </Stack>
      </Stack>

      <Autocomplete
        multiple
        disableCloseOnSelect
        onChange={(_, players) => {
          setSelectedFiltersValues({ players });
        }}
        options={playerOptions}
        autoHighlight
        getOptionLabel={(option) => makeTablePlayerName(option)}
        renderInput={(params) => (
          <TextField
            sx={{ textTransform: 'capitalize' }}
            {...params}
            label={FILTER_NAME.PLAYERS}
          />
        )}
        value={selectedFiltersValues.players}
      />

      <Autocomplete
        multiple
        disableCloseOnSelect
        onChange={(_, extras) => setSelectedFiltersValues({ extras })}
        options={extrasOptions}
        autoHighlight
        getOptionLabel={(option) => Extras[option]}
        renderInput={(params) => (
          <TextField
            sx={{ textTransform: 'capitalize' }}
            {...params}
            label={FILTER_NAME.EXTRAS}
          />
        )}
        value={selectedFiltersValues.extras}
      />

      <Autocomplete
        multiple
        disableCloseOnSelect
        onChange={(_, events) => setSelectedFiltersValues({ events })}
        options={eventOptions}
        autoHighlight
        getOptionLabel={(option) => ACTION_TYPE_ID[option]}
        renderInput={(params) => (
          <TextField
            sx={{ textTransform: 'capitalize' }}
            {...params}
            label={FILTER_NAME.EVENTS}
          />
        )}
        value={selectedFiltersValues.events}
      />

      <RadioButtons
        controlLabel={FILTER_NAME.OUTCOME}
        name={FILTER_NAME.OUTCOME}
        value={
          selectedFiltersValues.outcome === null
            ? null
            : Number(selectedFiltersValues.outcome)
        }
        onChange={(value) => {
          setSelectedFiltersValues({
            [FILTER_NAME.OUTCOME]: !!value,
          });
        }}
        onClear={() =>
          setSelectedFiltersValues({
            [FILTER_NAME.OUTCOME]: null,
          })
        }
        fields={{
          0: OUTCOME_OPTION_LABEL[OUTCOME_TYPE.UNSUCCESSFUL],
          1: OUTCOME_OPTION_LABEL[OUTCOME_TYPE.SUCCESSFUL],
        }}
      />

      <RadioButtons
        controlLabel={FILTER_NAME.FLAGS}
        name={FILTER_NAME.FLAGS}
        value={
          selectedFiltersValues.flags === null
            ? null
            : Number(selectedFiltersValues.flags)
        }
        onChange={(value) => {
          setSelectedFiltersValues({
            [FILTER_NAME.FLAGS]: !!value,
          });
        }}
        onClear={() =>
          setSelectedFiltersValues({
            [FILTER_NAME.FLAGS]: null,
          })
        }
        fields={{
          0: 'Unflagged events',
          1: 'Flagged events',
        }}
      />

      <RadioButtons
        controlLabel={FILTER_NAME.WARNINGS}
        name={FILTER_NAME.WARNINGS}
        value={
          selectedFiltersValues.warnings === null
            ? null
            : Number(selectedFiltersValues.warnings)
        }
        onChange={(value) => {
          setSelectedFiltersValues({
            [FILTER_NAME.WARNINGS]: !!value,
          });
        }}
        onClear={() =>
          setSelectedFiltersValues({
            [FILTER_NAME.WARNINGS]: null,
          })
        }
        fields={{
          0: 'Without warnings',
          1: 'With warnings',
        }}
      />

      <Stack direction='row' justifyContent='flex-end' gap={2}>
        <Button onClick={onClose} variant='outlined' color='secondary'>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant='contained'>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};
