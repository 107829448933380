import type { FC } from 'react';
import { MenuItem, ListItemText, Chip, ListItemIcon } from '@mui/material';
import DoNotTouch from '@mui/icons-material/DoNotTouch';
import PanTool from '@mui/icons-material/PanTool';
import { PlayerName } from '@/components/PlayerName/PlayerName';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import { getRelativePreviousAction } from '@/stores/FixtureStore';
import type { ShotAction } from '@/types/action/action';
import { useUpdateShotAssist } from '../actions';

export type UpdateAssistPlayerProps = {
  action: ShotAction;
};

export const UPDATE_ASSIST_LABEL = 'Update assist';

export const UpdateAssistPlayer: FC<UpdateAssistPlayerProps> = ({ action }) => {
  const previousAction = getRelativePreviousAction(action.actionId);
  const { canUpdateAssist, updatePlayer, updateAssist } = useUpdateShotAssist(
    action,
    previousAction,
  );

  if (!canUpdateAssist) return;

  return (
    <MenuItem
      aria-label={UPDATE_ASSIST_LABEL}
      onClick={() => {
        updateAssist();
        setContextAction(null);
      }}
    >
      <ListItemIcon>{updatePlayer ? <PanTool /> : <DoNotTouch />}</ListItemIcon>
      <ListItemText>{'Update assist to: '}</ListItemText>
      <Chip
        sx={{ marginLeft: 1 }}
        size='small'
        label={
          updatePlayer ? <PlayerName player={updatePlayer} /> : 'No assist'
        }
      />
    </MenuItem>
  );
};
