import { VARReason } from '@contract';

export const VAR_PENDING_REASON: Record<VARReason, string> = {
  [VARReason.Other]: 'Other',
  [VARReason.PotentialDisallowedGoal]: 'Potential Disallowed Goal',
  [VARReason.PotentialPenalty]: 'Potential Penalty',
  [VARReason.PotentialRedCard]: 'Potential Red Card',
};

export const VAR_PENDING_REASON_OPTIONS = Object.keys(VAR_PENDING_REASON).map(
  (reason) => {
    const key = Number(reason) as VARReason;
    return {
      value: key,
      label: VAR_PENDING_REASON[key],
    };
  },
);
