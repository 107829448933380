import { MouseEvent, useCallback } from 'react';
import { METADATA_KEY } from '@contract';
import {
  resetToPitchMode,
  setDialogAction,
  useActionStore,
} from '@/stores/ActionStore/ActionStore';
import { ShotDialog } from '@/components/Dialogs/ShotDialog/ShotDialog';
import { Action } from '@/types/action/action';
import { PlayStoppedDialog } from '@/components/Dialogs/PlayStoppedDialog/PlayStoppedDialog';
import { GoalDialog } from './GoalDialog/GoalDialog';
import { OwnGoalDialog } from './OwnGoalDialog/OwnGoalDialog';
import { PassDialog } from './PassDialog/PassDialog';
import { CardDialog } from './CardDialog/CardDialog';
import { VARPendingDialog } from './VARPendingDialog/VARPendingDialog';
import { VARDecisionDialog } from './VARDecisionDialog/VARDecisionDialog';
import { SubstitutionDialog } from './SubstitutionDialog/SubstitutionDialog';
import { FreeKickDialog } from './FreeKickDialog/FreeKickDialog';
import { GenericEditDialog } from './GenericEditDialog/GenericEditDialog';
import { GoalkeeperSaveDialog } from './GoalkeeperSaveDialog/GoalkeeperSaveDialog';
import { GoalkeeperPenaltyFacedDialog } from './GoalkeeperPenaltyFacedDialog/GoalkeeperPenaltyFacedDialog';

function getActionDialog(
  dialogAction: Action,
  action: Action,
  onClose: (e?: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void,
) {
  const dm = dialogAction.actionTypeMetadata;
  const am = action.actionTypeMetadata;
  if (
    (dm === METADATA_KEY.pass && am === METADATA_KEY.pass) ||
    (dm === METADATA_KEY.launch && am === METADATA_KEY.launch) ||
    (dm === METADATA_KEY.cross && am === METADATA_KEY.cross) ||
    (dm === METADATA_KEY.goalkeeperThrow &&
      am === METADATA_KEY.goalkeeperThrow) ||
    (dm === METADATA_KEY.throwInTaken && am === METADATA_KEY.throwInTaken)
  ) {
    return <PassDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.shot && am === METADATA_KEY.shot) {
    return <ShotDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.goal && am === METADATA_KEY.goal) {
    return <GoalDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.ownGoal && am === METADATA_KEY.ownGoal) {
    return <OwnGoalDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.card && am === METADATA_KEY.card) {
    return <CardDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.varPending && am === METADATA_KEY.varPending) {
    return <VARPendingDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.varDecision && am === METADATA_KEY.varDecision) {
    return <VARDecisionDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.substitution && am === METADATA_KEY.substitution) {
    return <SubstitutionDialog onClose={onClose} action={action} />;
  }
  if (
    dm === METADATA_KEY.freeKickAwarded &&
    am === METADATA_KEY.freeKickAwarded
  ) {
    return <FreeKickDialog onClose={onClose} action={action} />;
  }
  if (dm === METADATA_KEY.playStopped && am === METADATA_KEY.playStopped) {
    return <PlayStoppedDialog onClose={onClose} action={action} />;
  }
  if (
    dm === METADATA_KEY.goalkeeperPenaltyFaced &&
    am === METADATA_KEY.goalkeeperPenaltyFaced
  ) {
    return <GoalkeeperPenaltyFacedDialog onClose={onClose} action={action} />;
  }
  if (
    dm === METADATA_KEY.goalkeeperSave &&
    am === METADATA_KEY.goalkeeperSave
  ) {
    return <GoalkeeperSaveDialog onClose={onClose} action={action} />;
  }
  if (dm === am) {
    return <GenericEditDialog onClose={onClose} action={action} />;
  }
  return;
}

export const ActionDialog = () => {
  const dialogAction = useActionStore((state) => state.dialogAction);
  const action = useActionStore((state) => state.action);

  const onClose = useCallback(() => {
    setDialogAction(null);
    resetToPitchMode();
  }, []);

  if (!dialogAction) return;
  if (!action) return;

  return getActionDialog(dialogAction, action, onClose);
};
