import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { CollectionDto, UserRole } from '@contract';
import { Label } from '@/components/Label/Label';
import { DATE_FORMAT, formatDate } from '@/utils/formatDate';
import { setCurrentCollection } from '@/stores/CollectionStore';
import {
  FixtureCollectionDataStatusColor,
  FixtureCollectionDataStatusLabel,
  FixtureCollectionStatusColor,
  FixtureCollectionStatusLabel,
  NO_FIXTURES_LABEL,
  UserRoleLabel,
} from './constants';
import { AssignedChip } from './AssignedChip';
import { EmptyTableBody } from './EmptyTableBody';

export interface FixturesCollectionsTableBodyProps {
  collections: CollectionDto[];
  isLoading: boolean;
}

export const FixturesCollectionsTableBody: FC<
  FixturesCollectionsTableBodyProps
> = ({ collections, isLoading }) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <EmptyTableBody>
        <CircularProgress size='2rem' />
      </EmptyTableBody>
    );
  }

  if (!collections.length) {
    return <EmptyTableBody>{NO_FIXTURES_LABEL}</EmptyTableBody>;
  }

  const onRowClick = (collection: CollectionDto) => {
    setCurrentCollection(collection);
    if (collection.role === UserRole.Qa) {
      navigate(`${collection.id}/qa`);
      return;
    }

    navigate(
      `${collection.id}/collector/${
        collection.isHomeTeamAssigned ? 'home' : 'away'
      }`,
    );
  };

  return (
    <TableBody>
      {collections.map((collection) => (
        <TableRow
          key={`${collection.id}`}
          onClick={() => onRowClick(collection)}
          sx={{
            cursor: 'pointer',
          }}
        >
          <TableCell>
            {formatDate(
              collection.fixture.startDateUtc,
              `${DATE_FORMAT.DAY}/${DATE_FORMAT.MONTH}/${DATE_FORMAT.YEAR}`,
            )}
          </TableCell>
          <TableCell>
            {formatDate(
              collection.fixture.startDateUtc,
              `${DATE_FORMAT.HOUR}:${DATE_FORMAT.MINUTE}`,
            )}
          </TableCell>
          <TableCell>
            {collection.fixture.homeTeam}
            {collection.isHomeTeamAssigned && <AssignedChip />}
          </TableCell>
          <TableCell>
            {collection.fixture.awayTeam}
            {!collection.isHomeTeamAssigned && <AssignedChip />}
          </TableCell>
          <TableCell>{UserRoleLabel[collection.role]}</TableCell>
          <TableCell>{collection.fixture.sportName}</TableCell>
          <TableCell>{collection.fixture.competitionName}</TableCell>
          <TableCell>
            <Label
              backgroundColor={
                FixtureCollectionDataStatusColor[collection.status]
              }
            >
              {FixtureCollectionDataStatusLabel[collection.status]}
            </Label>
          </TableCell>
          <TableCell>
            <Label
              backgroundColor={
                FixtureCollectionStatusColor[collection.fixture.status]
              }
            >
              {FixtureCollectionStatusLabel[collection.fixture.status]}
            </Label>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
