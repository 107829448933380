import { Box } from '@mui/material';
import { FC, useCallback } from 'react';
import { ClickableDots, type DotProps } from '@/components/Pitch/ClickableDots';
import { getActionMetadata } from '@/utils/actions';
import PitchHorizontalSVG from '@/assets/football-pitch.svg?react';
import { CoordsLine } from '@/components/Pitch/CoordsLine';
import { setPartialPassMetadata } from '@/stores/ActionStore/ActionStore';
import type {
  CrossAction,
  GoalkeeperThrowAction,
  LaunchAction,
  PassAction,
  ThrowInTakenAction,
} from '@/types/action/action';
import { PITCH_POSITION } from '../constants';

type DestinationPitchProps = {
  action:
    | PassAction
    | CrossAction
    | LaunchAction
    | GoalkeeperThrowAction
    | ThrowInTakenAction;
};

export const DestinationPitch: FC<DestinationPitchProps> = ({ action }) => {
  const metadata = getActionMetadata(action);

  const setCoords = useCallback((dot: DotProps) => {
    if (!dot.positionId) return;

    if (dot.positionId === PITCH_POSITION.PASS_FROM) {
      setPartialPassMetadata({ position: dot.coords });
    }

    if (dot.positionId === PITCH_POSITION.PASS_TO) {
      setPartialPassMetadata({ targetPosition: dot.coords });
    }
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        isolation: 'isolate',
        flex: '1 0 auto',
      }}
    >
      <ClickableDots
        dots={[
          {
            positionId: PITCH_POSITION.PASS_FROM,
            coords: {
              ...metadata.position,
              xDraw: metadata.position.x,
              yDraw: 100 - metadata.position.y,
            },
          },
          {
            positionId: PITCH_POSITION.PASS_TO,
            coords: {
              ...metadata.targetPosition,
              xDraw: metadata.targetPosition.x,
              yDraw: 100 - metadata.targetPosition.y,
            },
          },
        ]}
        setCoords={setCoords}
      >
        <PitchHorizontalSVG />
      </ClickableDots>
      <CoordsLine
        coordsStart={{
          xDraw: metadata.position.x,
          yDraw: 100 - metadata.position.y,
        }}
        coordsEnd={{
          xDraw: metadata.targetPosition.x,
          yDraw: 100 - metadata.targetPosition.y,
        }}
        asArrow
      />
    </Box>
  );
};
