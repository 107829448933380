import { FC, useEffect, useRef, useState } from 'react';
import { Button, ClickAwayListener, Paper, Popper, Stack } from '@mui/material';
import { useFixtureStore } from '@/stores/FixtureStore';
import { FixturePhase } from '@contract';
import { EditPeriod } from './EditPeriod';
import { GAME_STATE, TOGGLE_GAME_DETAILS_LABEL } from './constants';

export const Periods: FC = () => {
  const collectionState = useFixtureStore((state) => state.collectionState);
  const [isMatchDetailOpen, setIsMatchDetailsOpen] = useState(false);
  const toggleMatchDetailsOpen = () =>
    setIsMatchDetailsOpen((current) => !current);
  const closeMatchDetails = () => setIsMatchDetailsOpen(false);
  const $popperAnchor = useRef(null);
  const [isKickOffDisabled, setIsKickOffDisabled] = useState(false);

  useEffect(() => {
    if (collectionState && collectionState.phase === FixturePhase.PostGame) {
      setIsKickOffDisabled(true);
    }
  }, [collectionState]);

  return (
    <Stack direction='row'>
      <Button
        aria-label={TOGGLE_GAME_DETAILS_LABEL}
        ref={$popperAnchor}
        onClick={toggleMatchDetailsOpen}
        variant='text'
        color='inherit'
        disabled={isKickOffDisabled}
      >
        {
          GAME_STATE[
            collectionState ? collectionState.phase : FixturePhase.Unknown
          ]
        }
      </Button>
      <Popper anchorEl={$popperAnchor.current} open={isMatchDetailOpen}>
        <ClickAwayListener onClickAway={closeMatchDetails}>
          <Paper elevation={6}>
            <EditPeriod onClose={closeMatchDetails} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Stack>
  );
};
