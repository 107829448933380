import {
  Avatar,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import { FC } from 'react';
import Delete from '@mui/icons-material/Delete';
import { Player } from '@/service/lineups';
import {
  removeAddedPlayer,
  selectEntry,
  useIsAddedPlayer,
  useLineupStore,
} from '@/stores/LineupStore/LineupStore';
import { type LineupPosition as LineupNamedPosition } from '@/stores/LineupStore/constants';

export type PlayerListItemProps = {
  player: Player;
  lineupPosition: LineupNamedPosition;
};
export const PlayerListItem: FC<PlayerListItemProps> = ({
  player,
  lineupPosition,
}) => {
  const isAddedPlayer = useIsAddedPlayer(player);
  const selectedEntry = useLineupStore((state) => state.selectedEntry);
  const isSelected =
    !!selectedEntry && !!selectedEntry[1] && selectedEntry[1].id === player.id;

  return (
    <Stack direction='row' alignItems={'center'}>
      <ListItemButton
        selected={isSelected}
        disableRipple
        onClick={() => selectEntry(lineupPosition, player)}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'currentColor',
              transition: (theme) =>
                theme.transitions.create('background-color'),
              backgroundColor: (theme) =>
                isSelected
                  ? theme.palette.primary.main
                  : theme.palette.grey[800],
            }}
          >
            {player.shirtNumber}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={player.fullName} />
      </ListItemButton>
      {isAddedPlayer && (
        <IconButton onClick={() => removeAddedPlayer(player)}>
          <Delete />
        </IconButton>
      )}
    </Stack>
  );
};
