/* eslint-disable @typescript-eslint/no-explicit-any */
import { HubConnectionState } from '@microsoft/signalr';
import type { Action } from '@/types/action/action';
/* Path aliases in worker bundle causes build issues :/ */
import {
  SocketMessageType,
  type SaveLineupDto,
  type LineupSubscriptionDto,
  type FixtureState,
} from '../../types/contract';

// Messages from worker to host
/**
 * This enum contains action/message names dispatched by WebWorker.
 * Those messages are handled by main thread (worker host).
 * Most of them directly pass the corresponding data coming from WebSocket.
 */
export const SCORING_WORKER_ACTION = {
  WORKER_READY: 'workerReady',
  ACTIONS_SUBSCRIBED: 'actionsSubscribed',
  LINEUPS_SUBSCRIBED: 'lineupsSubscribed',
  STATE_SUBSCRIBED: 'stateSubscribed',
  WS_CONNECTION: 'wsConnection',
  ACTIONS_RECEIVED: SocketMessageType.actionsHistoryReceived,
  ACTION_ADDED: SocketMessageType.actionAdded,
  LINEUP_RECEIVED: SocketMessageType.lineupReceived,
  LINEUP_MODIFIED: SocketMessageType.lineupModified,
  STATE_RECEIVED: SocketMessageType.stateReceived,
  STATE_MODIFIED: SocketMessageType.stateModified,
} as const;

export type ScoringWorkerAction = ConstType<typeof SCORING_WORKER_ACTION>;
export type WorkerReadyMsg = {
  action: typeof SCORING_WORKER_ACTION.WORKER_READY;
  payload: { isWorkerReady: boolean };
};
export type ActionsSubscribedMsg = {
  action: typeof SCORING_WORKER_ACTION.ACTIONS_SUBSCRIBED;
  payload: { actionsSubscribed: boolean };
};
export type LineupsSubscribedMsg = {
  action: typeof SCORING_WORKER_ACTION.LINEUPS_SUBSCRIBED;
  payload: { lineupsSubscribed: boolean };
};
export type StateSubscribedMsg = {
  action: typeof SCORING_WORKER_ACTION.STATE_SUBSCRIBED;
  payload: { stateSubscribed: boolean };
};
export type WebSocketConnectionMsg = {
  action: typeof SCORING_WORKER_ACTION.WS_CONNECTION;
  payload: { wsConnection: HubConnectionState };
};
export type ActionsReceivedMsg = {
  action: typeof SCORING_WORKER_ACTION.ACTIONS_RECEIVED;
  payload: { actions: Map<string, Action[]> };
};
export type ActionAddedMsg = {
  action: typeof SCORING_WORKER_ACTION.ACTION_ADDED;
  payload: { action: Action };
};
export type LineupChangeMsg = {
  action:
    | typeof SCORING_WORKER_ACTION.LINEUP_MODIFIED
    | typeof SCORING_WORKER_ACTION.LINEUP_RECEIVED;
  payload: SaveLineupDto | null;
};

export type StateChangeMsg = {
  action:
    | typeof SCORING_WORKER_ACTION.STATE_MODIFIED
    | typeof SCORING_WORKER_ACTION.STATE_RECEIVED;
  payload: FixtureState;
};

export type ScoringWorkerMsg =
  | WorkerReadyMsg
  | ActionsSubscribedMsg
  | LineupsSubscribedMsg
  | StateSubscribedMsg
  | ActionsReceivedMsg
  | ActionAddedMsg
  | LineupChangeMsg
  | WebSocketConnectionMsg
  | StateChangeMsg;

export type ScoringWorkerMsgEvent = MessageEvent<ScoringWorkerMsg>;
// END: Messages from worker to host
// Messages from host to worker
/**
 * This enum contains action/message names dispatched by host.
 * Those messages are received by WebWorker and most of them
 * directly invoke a WebSocket action/message.
 */
export enum SCORING_WORKER_HOST_ACTION {
  TOKEN = 'token',
  /**
   * An unified action which initially invokes many `fixtureId` related socket methods.
   */
  FIXTURE_ID = 'fixtureId',
  /**
   * Subscription methods
   */
  ACTIONS_SUBSCRIBE = 'subscribeActions',
  ACTIONS_UNSUBSCRIBE = 'unsubscribeActions',
  LINEUPS_SUBSCRIBE = 'subscribeLineups',
  LINEUPS_UNSUBSCRIBE = 'unsubscribeLineups',
  STATE_SUBSCRIBE = 'subscribeState',
  STATE_UNSUBSCRIBE = 'unsubscribeState',
  SAVE_LINEUP = 'saveLineup',
}

export interface PayloadBase {
  fixtureId: string;
}
export type TokenMsg = {
  action: SCORING_WORKER_HOST_ACTION.TOKEN;
  payload: string;
};
export type FixtureIdMsg = {
  action: SCORING_WORKER_HOST_ACTION.FIXTURE_ID;
  payload: PayloadBase & { oldFixtureId?: string };
};
export type FixtureActionsSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.ACTIONS_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.ACTIONS_UNSUBSCRIBE;
  payload: PayloadBase;
};

export type LineupsSubscriptionMsg = {
  action:
    | SCORING_WORKER_HOST_ACTION.LINEUPS_SUBSCRIBE
    | SCORING_WORKER_HOST_ACTION.LINEUPS_UNSUBSCRIBE;
  payload: LineupSubscriptionDto;
};

export type SaveLineupMsg = {
  action: SCORING_WORKER_HOST_ACTION.SAVE_LINEUP;
  payload: SaveLineupDto;
};

export type ScoringWorkerHostMsg =
  | TokenMsg
  | FixtureIdMsg
  | FixtureActionsSubscriptionMsg
  | LineupsSubscriptionMsg
  | SaveLineupMsg;

export type ScoringWorkerHostEvent = MessageEvent<ScoringWorkerHostMsg>;
// END: Messages from host to worker

// interface for main thread instance
export interface ScoringAPIWorker
  extends Omit<
    Worker,
    'postMessage' | 'addEventListener' | 'removeEventListener'
  > {
  addEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | AddEventListenerOptions,
  ): void;
  removeEventListener(
    type: 'message',
    listener: (this: ScoringAPIWorker, ev: ScoringWorkerMsgEvent) => any,
    options?: boolean | EventListenerOptions,
  ): void;

  postMessage(message: ScoringWorkerHostMsg, transfer: Transferable[]): void;
  postMessage(
    message: ScoringWorkerHostMsg,
    options?: StructuredSerializeOptions,
  ): void;
}
