import { FC } from 'react';
import { type Player } from '@/service/lineups';
import { makeTablePlayerName } from '@/stores/utils';
import { findPlayerById } from '@/stores/LineupStore/utils';

type PlayerNameByIdProps = {
  playerId: string | null;
  player?: never;
};
type PlayerNameByPlayerProps = {
  playerId?: never;
  player: Player | null;
};

export type PlayerNameProps = PlayerNameByPlayerProps | PlayerNameByIdProps;

const PlayerNameById: FC<PlayerNameByIdProps> = ({ playerId }) => {
  if (!playerId) return;

  const playerName = makeTablePlayerName(findPlayerById(playerId));
  return playerName || playerId;
};

export const PlayerName: FC<PlayerNameProps> = ({ playerId, player }) => {
  if (player !== undefined) {
    if (player === null) return;
    return makeTablePlayerName(player);
  }
  return <PlayerNameById playerId={playerId} />;
};
