import { Button, Stack, type StackProps } from '@mui/material';
import { FC } from 'react';
import { CANCEL_BUTTON_LABEL, COMMIT_BUTTON_LABEL } from '@/constants';

interface Controls {
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  disabled: boolean;
  stackProps?: StackProps;
}

export const Controls: FC<Controls> = ({
  onClose,
  onSubmit,
  disabled,
  stackProps,
}) => {
  return (
    <Stack flexDirection='row' justifyContent='end' gap={1} {...stackProps}>
      <Button variant='outlined' color='inherit' onClick={onClose}>
        {CANCEL_BUTTON_LABEL}
      </Button>
      <Button
        disabled={disabled}
        variant='contained'
        color='primary'
        onClick={onSubmit}
      >
        {COMMIT_BUTTON_LABEL}
      </Button>
    </Stack>
  );
};
