import { ChangeEvent, FC, useState } from 'react';
import {
  Box,
  Stack,
  Table,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { CollectionDto } from '@contract';
import { getFixturesCollectionsDateRange } from './utils';
import { FixturesCollectionsTableHeader } from './FixturesCollectionsTableHeader';
import { FixturesCollectionsTableBody } from './FixturesCollectionsTableBody';
import {
  FixturesCollectionsTableTitle,
  FixturesCollectionsType,
} from './constants';

interface FixturesCollectionsTableProps {
  type: FixturesCollectionsType;
  collections: CollectionDto[];
  isLoading: boolean;
}

export const FixturesCollectionsTable: FC<FixturesCollectionsTableProps> = ({
  type,
  collections,
  isLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const onPageChange = (_: unknown, pageNumber: number) => {
    setPage(pageNumber);
  };

  const onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Stack>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        p={2}
      >
        <Typography variant='h5'>
          {FixturesCollectionsTableTitle[type]}
        </Typography>
        <Typography variant='h6' align='right'>
          {getFixturesCollectionsDateRange(collections)}
        </Typography>
      </Box>
      <TableContainer id={`${type}-fixtures-table`}>
        <Table sx={{ minWidth: 900 }}>
          <FixturesCollectionsTableHeader />
          <FixturesCollectionsTableBody
            collections={collections.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage,
            )}
            isLoading={isLoading}
          />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component='div'
        count={collections.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Stack>
  );
};
