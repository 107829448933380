import { FC, useCallback } from 'react';
import { Divider, Popover, Stack, Typography } from '@mui/material';
import {
  setContextAction,
  useActionStore,
} from '@/stores/ActionStore/ActionStore';
import { ACTION_TYPE } from '@/types/action/actionType';
import { makeTablePlayerName } from '@/stores/utils';
import type { Action } from '@/types/action/action';
import { ActionContextMenuContent } from './ActionContextMenuContent';

interface ActionContextMenuProps {
  position: { top: number; left: number };
}
function getMenuTitle(action: Action) {
  let title = `${action.clockTime}, ${ACTION_TYPE[action.actionTypeId]}`;
  if (action.player) {
    title += `, ${makeTablePlayerName(action.player)}`;
  }
  return title;
}

export const ActionContextMenu: FC<ActionContextMenuProps> = ({ position }) => {
  const action = useActionStore((store) => store.contextAction);

  const close = useCallback(() => {
    setContextAction(null);
  }, []);

  if (!action) return;

  return (
    <Popover
      open
      onClose={close}
      anchorReference='anchorPosition'
      anchorPosition={position}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      transitionDuration={0}
    >
      <Stack data-action-context-menu={action.actionId}>
        <Typography variant='subtitle2' p={1}>
          {getMenuTitle(action)}
        </Typography>
        <Divider flexItem />
        <ActionContextMenuContent action={action} />
      </Stack>
    </Popover>
  );
};
