import { FC } from 'react';
import { MenuList } from '@mui/material';
import type { Action } from '@/types/action/action';
import { ACTION_TYPE_ID } from '@contract';
import { CommonContextMenuItems } from './CommonContextMenuItems';
import { PassContextMenu } from './PassContextMenu';
import { ShotContextMenu } from './ShotContextMenu';
import { ToggleAssist } from './MenuItems/ToggleAssist';
import { ClearanceContextMenu } from './ClearanceContextMenu';
import { InterceptionContextMenu } from './InterceptionContextMenu';

export type PropsWithAction = {
  action: Action;
};

export const ActionSpecificMenuItems: FC<PropsWithAction> = ({ action }) => {
  switch (action.actionTypeId) {
    case ACTION_TYPE_ID.Pass:
    case ACTION_TYPE_ID.Cross:
    case ACTION_TYPE_ID.Launch:
    case ACTION_TYPE_ID.GoalkeeperThrow:
      return <PassContextMenu action={action} />;
    case ACTION_TYPE_ID.Shot:
      return <ShotContextMenu action={action} />;
    case ACTION_TYPE_ID.Tackle:
    case ACTION_TYPE_ID.BadTouch:
      return <ToggleAssist action={action} />;
    case ACTION_TYPE_ID.Clearance:
      return <ClearanceContextMenu action={action} />;
    case ACTION_TYPE_ID.Interception:
      return <InterceptionContextMenu action={action} />;
    default:
      return;
  }
};

export const ActionContextMenuContent: FC<PropsWithAction> = (props) => {
  return (
    <MenuList variant='menu'>
      <ActionSpecificMenuItems {...props} />
      <CommonContextMenuItems {...props} />
    </MenuList>
  );
};
