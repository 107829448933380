import { List, type ListProps } from '@mui/material';
import type { FC } from 'react';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import type { Player } from '@/service/lineups';
import type {
  LineupNamedPosition,
  LineupStore,
} from '@/stores/LineupStore/constants';
import { PlayerListItem } from './PlayerListItem';

export type PlayersListProps = ListProps & {
  listSelector: (state: LineupStore) => Player[];
  lineupPosition: LineupNamedPosition;
};
export const PlayerList: FC<PlayersListProps> = (props) => {
  const { listSelector, lineupPosition, ...listProps } = props;
  const outPlayers = useLineupStore(listSelector);

  return (
    <List {...listProps}>
      {outPlayers.map((player) => (
        <PlayerListItem
          key={player.id}
          player={player}
          lineupPosition={lineupPosition}
        />
      ))}
    </List>
  );
};
