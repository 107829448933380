import { setMode } from '@/stores/ActionStore/ActionStore';
import { commitAction } from '@/stores/ActionStore/commitActions';
import { MODE } from '@/stores/ActionStore/constants';
import { ACTION_TYPE_ID } from '@contract';

export const buttons = [
  { onClick: () => setMode(MODE.PASS), label: 'Pass' },
  { onClick: () => setMode(MODE.CROSS), label: 'Cross' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.BallRecovery),
    label: 'Ball recovery',
  },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.ThrowInAwarded),
    label: 'Throw in awarded',
  },
  { onClick: () => setMode(MODE.THROW_IN), label: 'Throw in taken' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.CornerAwarded),
    label: 'Corner awarded',
  },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.GoalKickAwarded),
    label: 'Goal kick awarded',
  },
  { onClick: () => setMode(MODE.LAUNCH), label: 'Launch' },
  { onClick: () => setMode(MODE.FREE_KICK), label: 'Free kick awarded' },
  { onClick: () => setMode(MODE.GOALKEEPER_THROW), label: 'Goalkeeper throw' },
  {
    onClick: () => setMode(MODE.GOALKEEPER_SAVE),
    label: 'Goalkeeper save',
  },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperCatch),
    label: 'Goalkeeper catch',
  },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperPunch),
    label: 'Goalkeeper punch',
  },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperCollection),
    label: 'Goalkeeper collection',
  },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.GoalkeeperDrop),
    label: 'Goalkeeper drop',
  },
  {
    onClick: () => setMode(MODE.GOALKEEPER_PENALTY_FACED),
    label: 'Goalkeeper penalty faced',
  },
  { onClick: () => setMode(MODE.SHOT), label: 'Shot' },
  { onClick: () => setMode(MODE.GOAL), label: 'Goal' },
  { onClick: () => setMode(MODE.OWN_GOAL), label: 'Own goal' },
  { onClick: () => commitAction(ACTION_TYPE_ID.Block), label: 'Block' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.Interception),
    label: 'Interception',
  },
  { onClick: () => commitAction(ACTION_TYPE_ID.Tackle), label: 'Tackle' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.Dispossessed),
    label: 'Dispossessed',
  },
  { label: 'Dribble' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.BallTouch),
    label: 'Ball touch',
  },
  { onClick: () => commitAction(ACTION_TYPE_ID.BadTouch), label: 'Bad touch' },
  { onClick: () => commitAction(ACTION_TYPE_ID.Aerial), label: 'Aerial' },
  { onClick: () => commitAction(ACTION_TYPE_ID.Clearance), label: 'Clearance' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.Offside),
    label: 'Offside Against',
  },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.OffsideFor),
    label: 'Offside for',
  },
  { onClick: () => setMode(MODE.CARD), label: 'Card' },
  { onClick: () => setMode(MODE.SUBSTITUTION), label: 'Substitution' },
  { onClick: () => setMode(MODE.VAR_PENDING), label: 'VAR pending' },
  { onClick: () => setMode(MODE.VAR_DECISION), label: 'VAR decision' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.BigChance),
    label: 'Big chance',
  },
  { onClick: () => setMode(MODE.PLAY_STOPPED), label: 'Play stopped' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.PlayResumed),
    label: 'Play resumed',
  },
  { label: 'Added time' },
  {
    onClick: () => commitAction(ACTION_TYPE_ID.PlayerOff),
    label: 'Player off',
  },
  { onClick: () => commitAction(ACTION_TYPE_ID.PlayerOn), label: 'Player on' },
];
