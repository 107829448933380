import type { Action } from '@/types/action/action';
import {
  ACTION_TYPE_ID,
  AttemptType,
  EndPossessionEvents,
  InPossessionEvents,
} from '@contract';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import type { ActionStore } from './ActionStore/ActionStore';

export function makeTablePlayerName(player: ActionStore['player']) {
  return player ? `${player.shirtNumber}. ${player.lastName}` : '';
}

export function isExactAction(a: Action, b: Action) {
  return (
    a.actionId === b.actionId &&
    a.createdAt === b.createdAt &&
    a.updatedAt === b.updatedAt
  );
}

export function findFirstActionOfType(
  actions: Action[],
  actionTypeId: ACTION_TYPE_ID | ACTION_TYPE_ID[],
) {
  if (Array.isArray(actionTypeId)) {
    return actions.findIndex((action) =>
      actionTypeId.includes(action.actionTypeId),
    );
  }

  return actions.findIndex((action) => action.actionTypeId === actionTypeId);
}

export function checkUnresolvedAction(
  actions: Action[],
  awardedTypeId: ACTION_TYPE_ID,
  resolvingTypeIds: ACTION_TYPE_ID[],
) {
  const actionIndex = findFirstActionOfType(actions, awardedTypeId);
  const resolvingActionIndex = findFirstActionOfType(actions, resolvingTypeIds);

  return (actionIndex >= 0 && resolvingActionIndex === -1) ||
    (actionIndex >= 0 && actionIndex < resolvingActionIndex)
    ? actions[actionIndex]
    : null;
}

export function isInPossessionAction(action: Action): boolean {
  if (!InPossessionEvents[action.actionTypeId]) return false;

  return (
    (action.actionTypeId === ACTION_TYPE_ID.Pass && action.isSuccessful) ||
    (action.actionTypeId === ACTION_TYPE_ID.Dribble && action.isSuccessful) ||
    action.actionTypeId === ACTION_TYPE_ID.BallRecovery ||
    action.actionTypeId === ACTION_TYPE_ID.BadTouch ||
    (action.actionTypeId === ACTION_TYPE_ID.Clearance && action.isSuccessful) ||
    (action.actionTypeId === ACTION_TYPE_ID.Cross && action.isSuccessful) ||
    (action.actionTypeId === ACTION_TYPE_ID.Tackle && action.isSuccessful) ||
    action.actionTypeId === ACTION_TYPE_ID.GoalkeeperCollection ||
    action.actionTypeId === ACTION_TYPE_ID.GoalkeeperCatch
  );
}

export function isEndPossessionAction(action: Action): boolean {
  if (!EndPossessionEvents[action.actionTypeId]) return false;

  return (
    action.actionTypeId === ACTION_TYPE_ID.ThrowInAwarded ||
    action.actionTypeId === ACTION_TYPE_ID.CornerAwarded ||
    action.actionTypeId === ACTION_TYPE_ID.GoalKickAwarded ||
    action.actionTypeId === ACTION_TYPE_ID.FreeKickAwarded ||
    action.actionTypeId === ACTION_TYPE_ID.PenaltyAwarded ||
    (action.actionTypeId === ACTION_TYPE_ID.BallTouch &&
      !action.isSuccessful) ||
    (action.actionTypeId === ACTION_TYPE_ID.Shot &&
      action.metadata.shot.attemptType === AttemptType.OnTarget) ||
    (action.actionTypeId === ACTION_TYPE_ID.Shot &&
      action.metadata.shot.attemptType === AttemptType.OffTarget) ||
    (action.actionTypeId === ACTION_TYPE_ID.Shot &&
      action.metadata.shot.attemptType === AttemptType.Blocked) ||
    action.actionTypeId === ACTION_TYPE_ID.Goal ||
    action.actionTypeId === ACTION_TYPE_ID.OwnGoal ||
    (action.actionTypeId === ACTION_TYPE_ID.Tackle && !action.isSuccessful) ||
    action.actionTypeId === ACTION_TYPE_ID.Interception ||
    (action.actionTypeId === ACTION_TYPE_ID.Dribble && !action.isSuccessful) ||
    action.actionTypeId === ACTION_TYPE_ID.Offside
  );
}

export function deriveDirectionOfPlay(
  isHomeTeamLeft: boolean,
  isHomeTeamAssigned: boolean,
) {
  if (isHomeTeamAssigned) {
    return isHomeTeamLeft
      ? DIRECTION_OF_PLAY.LEFT_TO_RIGHT
      : DIRECTION_OF_PLAY.RIGHT_TO_LEFT;
  }

  return isHomeTeamLeft
    ? DIRECTION_OF_PLAY.RIGHT_TO_LEFT
    : DIRECTION_OF_PLAY.LEFT_TO_RIGHT;
}
