import { Button } from '@mui/material';
import { useState } from 'react';
import {
  resyncBackupActions,
  useBackupActions,
} from '@/stores/UnsyncedActionsStore';
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';

export const RESEND_BUTTON_LABEL = 'resend';

export const ResendActionsButton = () => {
  const unsyncedActions = useBackupActions();
  const unsyncedActionsCount = Object.keys(unsyncedActions).length;

  const [isLoading, setIsLoading] = useState(false);

  const resendActions = () => {
    setIsLoading(true);
    resyncBackupActions().finally(() => setIsLoading(false));
  };

  return (
    <Button
      disabled={isLoading || unsyncedActionsCount === 0}
      variant='outlined'
      onClick={resendActions}
      aria-label={RESEND_BUTTON_LABEL}
    >
      {RESEND_BUTTON_LABEL}
      {unsyncedActionsCount > 0 && `: ${unsyncedActionsCount}`}
      <LoadingOverlay open={isLoading} />
    </Button>
  );
};
