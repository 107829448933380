import HttpRequestMock from 'http-request-mock';
import { SERVICE_ENDPOINT } from '@/service/constants/endpoint';
import { currentEnvironment } from '@/utils/environment';
import { ENVIRONMENT } from '@root/env/environment';

const mocker = HttpRequestMock.setup();

if (currentEnvironment !== ENVIRONMENT.TEST) {
  mocker.mock({
    url: SERVICE_ENDPOINT.Lineups.get,
    method: 'get',
    headers: {
      'content-type': 'application/json',
    },
    response: async function () {
      return (await import('./lineups.json')).default;
    },
  });
}
