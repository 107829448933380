import { Stack, Drawer } from '@mui/material';
import { FC } from 'react';
import { FilterForm } from './FilterForm';
interface FilterDrawer {
  filtersDrawerOpen: boolean;
  toggleDrawer: () => void;
}

export const FilterDrawer: FC<FilterDrawer> = ({
  filtersDrawerOpen,
  toggleDrawer,
}) => {
  return (
    <Drawer
      id='filters-drawer'
      elevation={2}
      anchor='right'
      open={filtersDrawerOpen}
    >
      <Stack
        justifyContent='space-between'
        sx={{ p: 2, width: '500px', height: '100%' }}
      >
        <Stack gap={2}>
          <FilterForm onClose={toggleDrawer} />
        </Stack>
      </Stack>
    </Drawer>
  );
};
