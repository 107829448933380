import { Divider, Paper, Stack, Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useGetLineups } from '@/service/lineups';
import { setSquads } from '@/stores/LineupStore/LineupStore';
import { PlayerList } from '@/components/LineupEditor/PlayerList';
import { Pitch } from '@/components/LineupEditor/Pitch';
import { SubmitButton } from '@/components/LineupEditor/SubmitButton';
import { SquadInfo } from '@/components/LineupEditor/SquadInfo';
import { DropZone } from '@/components/LineupEditor/DropZone';
import { LINEUP_NAMED_POSITION } from '@/stores/LineupStore/constants';
import { AddPlayer } from '@/components/LineupEditor/AddPlayer';

export const ELEMENT_ID = {
  PITCH: 'lineup-editor-pitch',
  OUT_PLAYERS: 'lineup-editor-out-players',
  BENCH_PLAYERS: 'lineup-editor-bench-players',
};

export const LineupEditorView: FC = () => {
  const { data: lineupsData, error } = useGetLineups();
  const squads = useMemo(() => {
    if (!lineupsData) return undefined;
    return [
      ...lineupsData.lineups.startingLineup,
      ...lineupsData.lineups.substitutes,
    ];
  }, [lineupsData]);

  useEffect(() => {
    if (squads) {
      setSquads(squads);
    }
  }, [squads]);

  useEffect(() => {
    if (error) {
      setSquads([]);
    }
  }, [error]);

  return (
    <Stack flex={'1'} gap={1} padding={1}>
      <Stack
        direction='row'
        gap={1}
        flex={'1 1 0'}
        overflow='hidden'
        justifyContent='center'
      >
        <Stack
          id={ELEMENT_ID.PITCH}
          flex='1'
          maxWidth='840px'
          position='relative'
        >
          <Pitch />
        </Stack>

        <Paper
          component={Stack}
          id={ELEMENT_ID.OUT_PLAYERS}
          gap={1}
          elevation={2}
          p={2}
          overflow={'hidden'}
          minWidth={'50ch'}
        >
          <Stack
            direction='row'
            justifyContent={'center'}
            alignItems={'center'}
            gap={2}
          >
            <Typography
              fontSize={'1.2em'}
              textAlign={'center'}
              variant='overline'
            >
              Players
            </Typography>
            <AddPlayer color='primary' />
          </Stack>
          <Divider flexItem />

          <Stack overflow={'hidden'}>
            <DropZone lineupPosition={LINEUP_NAMED_POSITION.OUT} />
            <Stack flex='1' overflow={'auto'}>
              <PlayerList
                lineupPosition={LINEUP_NAMED_POSITION.OUT}
                listSelector={(state) => state.outPlayers}
              />
            </Stack>
          </Stack>
          <Stack marginTop='auto' gap={1}>
            <Divider flexItem />
            <SquadInfo />
            <Divider flexItem />
            <SubmitButton sx={{ alignSelf: 'flex-end' }} />
          </Stack>
        </Paper>

        <Paper
          id={ELEMENT_ID.BENCH_PLAYERS}
          component={Stack}
          gap={1}
          elevation={2}
          p={2}
          overflow={'hidden'}
          minWidth='40ch'
        >
          <Typography
            fontSize={'1.2em'}
            textAlign={'center'}
            variant='overline'
          >
            Bench
          </Typography>
          <Divider flexItem />

          <DropZone lineupPosition={LINEUP_NAMED_POSITION.BENCH} />
          <Stack flex='1' overflow={'auto'}>
            <PlayerList
              lineupPosition={LINEUP_NAMED_POSITION.BENCH}
              listSelector={(state) => state.benchPlayers}
            />
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};
