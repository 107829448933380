import { Box } from '@mui/material';
import PitchSVG from '@/assets/football-pitch-vertical.svg?react';
import { PitchGrid } from './PitchGrid';

export const Pitch = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        maxWidth: '100%',
        maxHeight: '100%',
        aspectRatio: '686 / 1056',
        margin: 'auto 0 auto auto',
        inset: 0,
      }}
    >
      <PitchSVG
        style={{
          width: '100%',
          height: '100%',
        }}
      />
      <PitchGrid />
    </Box>
  );
};
