import hotkeysJs, { KeyHandler } from 'hotkeys-js';
import { Key } from 'ts-key-enum';
import { SPORT, Sport } from '@/service/constants';
import {
  buildPlayerNumber,
  cancelPass,
  reset,
  resetToPitchMode,
  setMode,
  tryToggleHeaded,
} from '@/stores/ActionStore/ActionStore';
import {
  commitAction,
  toggleActionOutcome,
} from '@/stores/ActionStore/commitActions';
import { MODE, Mode } from '@/stores/ActionStore/constants';
import { ACTION_TYPE_ID } from '@contract';
import { onNumericKeyInput, onSingleModifierKey } from './helpers';

export const HOTKEYS_VARIANT = {
  COLLECTOR: 'collector',
  QA: 'qa',
} as const;

export type HotkeysVariant = ConstType<typeof HOTKEYS_VARIANT>;
type HotkeyOptions = Parameters<typeof hotkeysJs>[1] | undefined;

export type Hotkey = [string, KeyHandler] | [string, KeyHandler, HotkeyOptions];
export type HotkeyConfig<T extends string = string> = Record<T, Hotkey[]>;

export const collectorHotkeys: HotkeyConfig<Mode> = {
  [MODE.PITCH]: [
    [Key.Escape, reset],
    [Key.Delete, toggleActionOutcome],
    [Key.Backspace, toggleActionOutcome],
    [Key.Enter, setMode.bind(null, MODE.PASS)],
    ['space', setMode.bind(null, MODE.SHOT)],
    ['g', setMode.bind(null, MODE.GOAL)],
    ['o', setMode.bind(null, MODE.OWN_GOAL)],
    ['up', setMode.bind(null, MODE.LAUNCH)],
    ['down', commitAction.bind(null, ACTION_TYPE_ID.Tackle)],
    ['left', commitAction.bind(null, ACTION_TYPE_ID.BallRecovery)],
    ['right', commitAction.bind(null, ACTION_TYPE_ID.Dispossessed)],
    [Key.Home, commitAction.bind(null, ACTION_TYPE_ID.Clearance)],
    [Key.PageUp, commitAction.bind(null, ACTION_TYPE_ID.Aerial)],
    [Key.PageDown, commitAction.bind(null, ACTION_TYPE_ID.Interception)],
    ['num_subtract', setMode.bind(null, MODE.CROSS)],
    ['b', commitAction.bind(null, ACTION_TYPE_ID.Block)],
    ['s', commitAction.bind(null, ACTION_TYPE_ID.GoalkeeperSave)],
    ['c', commitAction.bind(null, ACTION_TYPE_ID.CornerAwarded)],
    [
      '*',
      onSingleModifierKey(Key.Shift, setMode.bind(null, MODE.FREE_KICK)),
      { keyup: true },
    ],
    ['*', onSingleModifierKey(Key.Control, tryToggleHeaded), { keyup: true }],
    ['*', onNumericKeyInput(buildPlayerNumber)],
  ],
  [MODE.PASS]: [
    ['p', cancelPass],
    [Key.Escape, cancelPass],
    ['*', onNumericKeyInput(buildPlayerNumber)],
  ],
  [MODE.CROSS]: [
    ['p', cancelPass],
    [Key.Escape, cancelPass],
    ['*', onNumericKeyInput(buildPlayerNumber)],
  ],
  [MODE.LAUNCH]: [
    ['p', cancelPass],
    [Key.Escape, cancelPass],
    ['*', onNumericKeyInput(buildPlayerNumber)],
  ],
  [MODE.GOALKEEPER_THROW]: [
    ['p', cancelPass],
    [Key.Escape, cancelPass],
    ['*', onNumericKeyInput(buildPlayerNumber)],
  ],
  [MODE.SHOT]: [],
  [MODE.GOAL]: [],
  [MODE.OWN_GOAL]: [],
  [MODE.THROW_IN]: [[Key.Escape, resetToPitchMode]],
  [MODE.CARD]: [],
  [MODE.VAR_PENDING]: [],
  [MODE.VAR_DECISION]: [],
  [MODE.SUBSTITUTION]: [],
  [MODE.OFFSIDE]: [],
  [MODE.FREE_KICK]: [],
  [MODE.PLAY_STOPPED]: [],
  [MODE.GOALKEEPER_PENALTY_FACED]: [],
  [MODE.GOALKEEPER_SAVE]: [],
};

export const qaHotkeys: HotkeyConfig = {};

export const HOTKEY_CONFIG = {
  [SPORT.SOCCER]: {
    [HOTKEYS_VARIANT.COLLECTOR]: collectorHotkeys,
    [HOTKEYS_VARIANT.QA]: qaHotkeys,
  },
} as const;

export function getHotkeysConfig(sport: Sport, hotkeysVariant: HotkeysVariant) {
  return HOTKEY_CONFIG[sport][hotkeysVariant];
}

export type HotkeyRegisterEntry = {
  key: string;
  scope: string;
  callback: KeyHandler;
  options?: HotkeyOptions;
};

export function registerHotkey({
  key,
  scope,
  options,
  callback,
}: HotkeyRegisterEntry) {
  hotkeysJs(key, { scope, ...options }, callback);
}
export function registerHotkeys(hotkeysConfig: HotkeyConfig) {
  Object.entries(hotkeysConfig).forEach(([mode, hotkeys]) => {
    hotkeys.forEach(([key, callback, options]) => {
      registerHotkey({ key, scope: mode, options, callback });
    });
  });
}

export function unregisterHotkey({
  key,
  scope,
  callback,
}: HotkeyRegisterEntry) {
  hotkeysJs.unbind(key, scope, callback);
}
export function unregisterHotkeys(hotkeysConfig: HotkeyConfig) {
  Object.entries(hotkeysConfig).forEach(([mode, hotkeys]) => {
    hotkeys.forEach(([key, callback]) => {
      unregisterHotkey({ key, scope: mode, callback });
    });
  });
}
