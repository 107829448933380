/* eslint-disable react-hooks/exhaustive-deps */
import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { Dialog } from '@/components/Dialog/Dialog';
import {
  setPartialShotMetadata,
  setRecentlyAwardedAction,
  updatePreviousPassIsAssist,
} from '@/stores/ActionStore/ActionStore';
import { MODE } from '@/stores/ActionStore/constants';
import { ShotAction } from '@/types/action/action';
import { AttemptType, BodyPart, Extras, PatternOfPlay } from '@contract';
import { postAction } from '@/service/helpers/postAction';
import { RadioButtons } from '@/components/Form/RadioButtons';
import {
  ASSIST_LABEL,
  ATTEMPT_BY_LABEL,
  ATTEMPT_TYPE_LABEL,
  BODY_PARTS_LABEL,
  EXTRAS_LABEL,
  PATTERN_OF_PLAY_LABEL,
} from '@/constants';
import { CheckboxGroup } from '@/components/Form/CheckboxGroup';
import { useCollectionStore } from '@/stores/CollectionStore';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { registerHotkey, unregisterHotkey } from '../../HotkeyManager/hotkeys';
import { Goal } from '../Common/Goal';
import { ShotPitch } from '../Common/ShotPitch';
import {
  ATTEMPT_TYPE,
  attemptExtrasOptions,
  BODY_PART,
  PATTERN_OF_PLAY,
  SHOT_DIALOG_TITLE,
} from '../constants';
import { AssistField } from '../Common/AssistField';
import { Controls } from '../Common/Controls';
import { ActionPlayerField } from '../Common/ActionPlayerField';
import { PeriodClockField } from '../Common/PeriodClockField';

interface ShotDialogProps {
  action: ShotAction;
  onClose: VoidFunction;
}

const onAttemptTypeChange = (attemptType: AttemptType) => {
  setPartialShotMetadata({ attemptType });
};

const onPatternOfPlayChange = (patternOfPlay: PatternOfPlay) => {
  setPartialShotMetadata({ patternOfPlay });
};

const onBodyPartChange = (bodyPart: BodyPart) => {
  setPartialShotMetadata({ bodyPart });
};

const onExtrasChange = (extras: Extras[]) => {
  setPartialShotMetadata({ extras });
};

const onAssistPlayerChange = (
  assistPlayer: ActionMeta<ShotAction>['assistPlayer'],
) => {
  setPartialShotMetadata({ assistPlayer });
};

export const ShotDialog: FC<ShotDialogProps> = ({ action, onClose }) => {
  const directionOfPlay = useCollectionStore((state) => state.directionOfPlay);
  const initialAction = useRef(action);
  const initialActionJSON = useMemo(
    () => JSON.stringify(initialAction.current),
    [initialAction],
  );

  const [previousAttemptType, setPreviousAttemptType] = useState<AttemptType>(
    action?.metadata.shot.attemptType || AttemptType.TemptAttempt,
  );

  const isSubmitDisabled =
    !!action.messageId && JSON.stringify(action) === initialActionJSON;

  const handleClose = () => {
    onClose();
  };

  const onFormRightClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleClose();
  };

  const onSubmit = () => {
    postAction(action);
    updatePreviousPassIsAssist(action);
    if (!action.messageId) {
      setRecentlyAwardedAction(null);
    }
    onClose();
  };

  useEffect(() => {
    const onSpaceClick = () => {
      if (isSubmitDisabled) return;
      onSubmit();
    };

    unregisterHotkey({
      key: 'space',
      scope: MODE.SHOT,
      callback: onSpaceClick,
    });

    registerHotkey({
      key: 'space',
      scope: MODE.SHOT,
      callback: onSpaceClick,
    });

    return () => {
      unregisterHotkey({
        key: 'space',
        scope: MODE.SHOT,
        callback: onSpaceClick,
      });
    };
  }, [isSubmitDisabled]);

  useEffect(() => {
    const { attemptType } = action.metadata.shot;
    if (attemptType === previousAttemptType) return;
    setPreviousAttemptType(attemptType);

    switch (attemptType) {
      case AttemptType.OffTarget:
        setPartialShotMetadata({
          ballPosition: { x: 50, y: 75 },
          blockersPositions: [
            directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
              ? { x: 15, y: 50 }
              : { x: 85, y: 50 },
          ],
          patternOfPlay: PatternOfPlay.RegularPlay,
          bodyPart: BodyPart.RightFoot,
        });
        break;
      case AttemptType.OnTarget:
        setPartialShotMetadata({
          ballPosition: { x: 50, y: 50 },
          blockersPositions: [
            directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
              ? { x: 15, y: 50 }
              : { x: 85, y: 50 },
          ],
          patternOfPlay: PatternOfPlay.RegularPlay,
          bodyPart: BodyPart.RightFoot,
        });
        break;
      case AttemptType.Blocked:
        setPartialShotMetadata({
          ballPosition: null,
          blockersPositions: [
            directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
              ? { x: 15, y: 50 }
              : { x: 85, y: 50 },
          ],
          patternOfPlay: PatternOfPlay.RegularPlay,
          bodyPart: BodyPart.RightFoot,
        });
        break;
      case AttemptType.TemptAttempt:
        setPartialShotMetadata({
          ballPosition: null,
          patternOfPlay: null,
          blockersPositions: null,
          bodyPart: null,
          extras: null,
        });
        break;
    }
  }, [action.metadata.shot.attemptType]);

  return (
    <Dialog onRightClick={onFormRightClick} isOpen title={SHOT_DIALOG_TITLE}>
      <Stack direction='row' gap={2}>
        <Stack gap={2}>
          <RadioButtons
            name='attemptType'
            controlLabel={ATTEMPT_TYPE_LABEL}
            fields={ATTEMPT_TYPE}
            value={action?.metadata.shot.attemptType}
            onChange={onAttemptTypeChange}
          />
          <Goal
            ballPosition={action.metadata.shot.ballPosition}
            offTargetDisabled={[
              AttemptType.TemptAttempt,
              AttemptType.OnTarget,
              AttemptType.Blocked,
            ].includes(action.metadata.shot.attemptType)}
            onTargetDisabled={[
              AttemptType.TemptAttempt,
              AttemptType.OffTarget,
              AttemptType.Blocked,
            ].includes(action.metadata.shot.attemptType)}
            setPartialMetadata={setPartialShotMetadata}
          />
          <ShotPitch
            attemptType={action.metadata.shot.attemptType}
            position={action.metadata.shot.position}
            blockersPositions={
              action.metadata.shot.attemptType === AttemptType.TemptAttempt
                ? null
                : action.metadata.shot.blockersPositions
            }
            setPartialMetadata={setPartialShotMetadata}
          />
        </Stack>
        <Stack gap={2}>
          <ActionPlayerField
            action={action}
            playerAutoCompleteProps={{ label: ATTEMPT_BY_LABEL }}
          />
          <AssistField
            action={action.messageId ? action : undefined}
            controlLabel={ASSIST_LABEL}
            onChange={onAssistPlayerChange}
            player={action.player}
            assistPlayer={action.metadata.shot.assistPlayer}
          />
          <PeriodClockField action={action} />
          <RadioButtons
            name='patternOfPlay'
            onChange={onPatternOfPlayChange}
            controlLabel={PATTERN_OF_PLAY_LABEL}
            fields={PATTERN_OF_PLAY}
            value={action?.metadata.shot.patternOfPlay}
            disabled={
              action.metadata.shot.attemptType === AttemptType.TemptAttempt
            }
          />
          <RadioButtons
            name='bodyPart'
            onChange={onBodyPartChange}
            controlLabel={BODY_PARTS_LABEL}
            fields={BODY_PART}
            value={action?.metadata.shot.bodyPart}
            disabled={
              action.metadata.shot.attemptType === AttemptType.TemptAttempt
            }
          />
          <CheckboxGroup
            controlLabel={EXTRAS_LABEL}
            fields={attemptExtrasOptions}
            onChange={onExtrasChange}
            values={action?.metadata.shot.extras || []}
            disabled={
              action.metadata.shot.attemptType === AttemptType.TemptAttempt
            }
          />
          <Controls
            onClose={onClose}
            onSubmit={onSubmit}
            disabled={isSubmitDisabled}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
