import { FC, FocusEventHandler, FormEventHandler } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { setClockTicks, useClockStore } from '@/stores/ClockStore';

export type EditClockProps = {
  autoFocus?: boolean;
  afterSubmit?: (newTime: number) => unknown;
};
export const EditClock: FC<EditClockProps> = ({
  autoFocus = true,
  afterSubmit,
}) => {
  const clockTime = useClockStore.getState().clockTime;
  const [minutes, seconds] = clockTime.split(':').map(Number);

  const onSave: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const minutes = data.get('minutes');
    const seconds = data.get('seconds');
    const newTicks = Number(minutes) * 60 + Number(seconds);
    setClockTicks(newTicks);
    afterSubmit && typeof afterSubmit === 'function' && afterSubmit(newTicks);
  };
  const onInputFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.currentTarget.select();
  };

  return (
    <form onSubmit={onSave}>
      <Stack direction='row' alignItems={'center'}>
        <TextField
          autoFocus={autoFocus}
          sx={{ flex: '1 1 0', maxWidth: '9ch' }}
          size='small'
          name='minutes'
          defaultValue={minutes}
          type='number'
          inputProps={{ min: 0 }}
          onFocus={onInputFocus}
          InputProps={{
            onFocus: onInputFocus,
          }}
        ></TextField>
        <Typography fontSize='1.5em'>:</Typography>
        <TextField
          sx={{ flex: '1 1 0', maxWidth: '9ch' }}
          size='small'
          name='seconds'
          defaultValue={seconds}
          type='number'
          inputProps={{ min: 0, max: 59 }}
          InputProps={{
            onFocus: onInputFocus,
          }}
          onFocus={onInputFocus}
        ></TextField>
        <Button type='submit'>
          <Check />
        </Button>
      </Stack>
    </form>
  );
};
