import { Stack, Typography, Button } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import DownloadIcon from '@mui/icons-material/Download';
import { useState, useEffect, useCallback } from 'react';
import { ScoreView } from '@/components/EventHistory/ScoreView';
import { EventsTable } from '@/components/EventHistory/EventsTable/EventsTable';
import { useFixtureStore, TableAction } from '@/stores/FixtureStore';
import { FilterDrawer } from '@/components/EventHistory/FiltersDrawer/FilterDrawer';
import { ActionDialog } from '@/components/Dialogs/ActionDialog';
import { UserRole } from '@contract';
import { applyFilters, useEventHistoryStore } from '@/stores/EventHistoryStore';
import { SelectedFilterChips } from '@/components/EventHistory/SelectedFilterChips';
import { ResendActionsButton } from '@/components/EventHistory/ResendActionsButton';
import { useCurrentCollection } from '@/stores/CollectionStore';
import { downloadJSON } from '@/utils/export';
import { sortByCreatedAt } from '@/utils/sortByActionTimes';

export const EventHistoryView = () => {
  const [actionsState, setActionsState] = useState<TableAction[]>([]);
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);
  const [isQa, setIsQa] = useState(false);
  const [teamSelected, setTeamSelected] = useState<string[] | []>(['teamA']);
  const currentCollection = useCurrentCollection();
  const actions = useFixtureStore((state) => state.actions);
  const { filterValues, tableActions } = useEventHistoryStore();

  useEffect(() => {
    applyFilters();
  }, [filterValues, actions]);

  useEffect(() => {
    if (!currentCollection) return;
    setIsQa(currentCollection.role === UserRole.Qa);
  }, [currentCollection]);

  useEffect(() => {
    setActionsState(tableActions);
  }, [tableActions]);

  const handleTeamSelection = (value: string) => {
    if (!isQa) {
      return;
    }

    const isTeamAlreadySelected = teamSelected.some(
      (teamId) => teamId === value,
    );

    if (!isTeamAlreadySelected) {
      setTeamSelected((prev) => [...prev, value]);
    } else {
      setTeamSelected((prev) => prev.filter((teamId) => teamId !== value));
    }
  };

  const toggleDrawer = () => {
    setFiltersDrawerOpen(!filtersDrawerOpen);
  };

  const onExport = useCallback(() => {
    if (!currentCollection) return;
    const exportData = [...actions.values()].flat().sort(sortByCreatedAt);

    downloadJSON(exportData, `${currentCollection.id}-${new Date().toJSON()}`);
  }, [actions, currentCollection]);

  return (
    <Stack
      sx={{ gap: 2, pt: 2, pb: 0, px: 1, overflow: 'hidden', height: '100%' }}
    >
      <ScoreView
        teamSelected={teamSelected}
        setTeamSelected={handleTeamSelection}
      />
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h5'>
          Events table ({actionsState.length})
        </Typography>
        <Stack direction='row' gap={2}>
          <Button onClick={onExport} startIcon={<DownloadIcon />}>
            Export
          </Button>
          <Button onClick={toggleDrawer} startIcon={<TuneIcon />}>
            Filters
          </Button>
          <ResendActionsButton />
        </Stack>
      </Stack>
      <SelectedFilterChips />
      <EventsTable actions={actionsState} />
      <ActionDialog />
      <FilterDrawer
        filtersDrawerOpen={filtersDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
    </Stack>
  );
};
