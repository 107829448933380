import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import {
  ACTION_STATE,
  AWARDED_ACTIONS,
  ActionState,
  TAKEN_ACTIONS,
} from '@/stores/constants';
import { Action } from '@/types/action/action';
import { sortByActionTimes, sortByUpdatedAt } from '@/utils/sortByActionTimes';
import {
  ACTION_TYPE_ID,
  type FixtureState,
  type PeriodState,
  type UserRole,
  ActionContext,
  type Config_PeriodDetailDto,
} from '@contract';
import { TableExtrasCellValue } from '@/components/TableExtrasCell/TableExtrasCell';
import type { Lineups, Player } from '@/service/lineups';
import { getActionMetadata } from '@/utils/actions';
import {
  deriveDirectionOfPlay,
  isEndPossessionAction,
  isExactAction,
  isInPossessionAction,
} from './utils';
import {
  sendFixtureId,
  subscribeLineups,
  unsubscribeLineups,
} from './SocketStore/SocketStore';
import { mapToTableAction } from './tableActionMapper';
import { setRecentlyAwardedAction } from './ActionStore/ActionStore';
import {
  backupAction,
  getBackedUpActionsMap,
  removeBackupAction,
  subscribeUnsyncedActions,
} from './UnsyncedActionsStore';
import {
  setFreeKickCoords,
  setThrowInCoords,
  setCornerCoords,
  setGoalKickCoords,
} from './ActionStore/utils';
import { useLineupStore } from './LineupStore/LineupStore';
import { setDirectionOfPlay, useCollectionStore } from './CollectionStore';

export type TableAction = {
  time: string;
  clockTimeTicks: number;
  type: string;
  typeId: ACTION_TYPE_ID;
  extras?: TableExtrasCellValue;
  player?: Player;
  targetPlayer?: Player;
  createdAt: number;
  updatedAt: number;
  isSuccessful: boolean | null;
  actionId: string;
  state: ActionState;
  isRemoved: boolean;
  messageId?: string;
  warnings: Action['warnings'];
};

type PossessionPhase = {
  id: number;
  shouldBump: boolean;
};

export type FixtureStore = {
  fixtureId?: string;
  collectionId?: string;
  role?: UserRole;
  isHomeTeamCollector: boolean | null;
  /**
   * Stores original actions received from Socket.
   * This map is an aggregate of all action entries by `key=actionId`.
   * The value is an array of Action, which NEEDS TO BE ALWAYS SORTED by `updatedAt` property.
   * First element in the array is the latest action (highest `updatedAt`).
   */
  actions: Map<string, Action[]>;
  /**
   * Stores mapped actions for table presentation.
   * Simplified, flat representation of an action.
   * This collection is originally created from `actions` map,
   * grabbing latest entry for each `actionId`.
   * Afterwards, this collection is maintained during app lifetime and
   * NEEDS TO BE ALWAYS SORTED by `clockTimeTicks` and `createdAt` property.
   */
  tableActions: TableAction[];
  lineups: Lineups | null;
  teamPossessionPhase: PossessionPhase;
  periodDetails: Config_PeriodDetailDto[] | null;
  collectionState: FixtureState | null;
  currentPeriod: Config_PeriodDetailDto | null;
  currentPeriodState: PeriodState | null;
};

export const DEFAULT_STATE: FixtureStore = {
  fixtureId: undefined,
  collectionId: undefined,
  role: undefined,
  isHomeTeamCollector: null,
  actions: new Map(),
  tableActions: [],
  lineups: null,
  teamPossessionPhase: {
    id: 0,
    shouldBump: false,
  },
  periodDetails: null,
  collectionState: null,
  currentPeriod: null,
  currentPeriodState: null,
};

export const useFixtureStore = create<FixtureStore>()(
  devtools(() => ({ ...DEFAULT_STATE }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'FixtureStore',
  }),
);

export function reset() {
  return useFixtureStore.setState({ ...DEFAULT_STATE });
}

export function setCollectionId(collectionId?: string) {
  return useFixtureStore.setState(
    (state) => {
      if (state.collectionId === collectionId) return state;
      return { collectionId };
    },
    false,
    'setCollectionId',
  );
}

export function setCollectionState(collectionState: FixtureState) {
  return useFixtureStore.setState(
    { collectionState },
    false,
    'setCollectionState',
  );
}

export function setCurrentPeriod(currentPeriod: Config_PeriodDetailDto) {
  return useFixtureStore.setState(
    { currentPeriod },
    false,
    'setCollectionState',
  );
}

export function setCurrentPeriodState(currentPeriodState: PeriodState) {
  return useFixtureStore.setState(
    { currentPeriodState },
    false,
    'setCurrentPeriodState',
  );
}

export const onCollectionStateChange = useFixtureStore.subscribe(
  (state, prevState) => {
    if (
      !state.periodDetails ||
      !state.collectionState ||
      (prevState.periodDetails === state.periodDetails &&
        prevState.collectionState === state.collectionState)
    )
      return;

    const currentPeriodState = state.collectionState.periods.length
      ? state.collectionState.periods.reduce((prev, current) => {
          return prev.sequence > current.sequence ? prev : current;
        })
      : null;

    if (!currentPeriodState) return;

    const { collection } = useCollectionStore.getState();

    if (collection) {
      setDirectionOfPlay(
        deriveDirectionOfPlay(
          currentPeriodState.isHomeTeamLeft,
          collection.isHomeTeamAssigned,
        ),
      );
    }

    const currentPeriod = state.periodDetails.find(
      (period) => period.seq === currentPeriodState.sequence,
    );

    if (!currentPeriod) return;

    setCurrentPeriodState(currentPeriodState);
    setCurrentPeriod(currentPeriod);
  },
);

export const onCollectionIdChange = useFixtureStore.subscribe(
  (state, prevState) => {
    if (state.collectionId === prevState.collectionId) return;
    sendFixtureId(state.collectionId, prevState.collectionId);
    subscribeUnsyncedActions(state.collectionId);
    const teamId = useLineupStore.getState().teamId;
    if (!teamId) return;

    if (prevState.collectionId) {
      unsubscribeLineups({ fixtureId: prevState.collectionId, teamId });
    }
    if (teamId && state.collectionId) {
      subscribeLineups({ fixtureId: state.collectionId, teamId });
    }
  },
);

export function setTeamPossessionPhase(teamPossessionPhase: {
  id: number;
  shouldBump: boolean;
}) {
  return useFixtureStore.setState({ teamPossessionPhase });
}

export function checkUnresolvedAwardedActions() {
  const { tableActions, actions } = useFixtureStore.getState();
  const actionContextValues = Object.values(ActionContext).filter(
    (val) => typeof val === 'number',
  );

  const backupActions = getBackedUpActionsMap();
  let awardedAction;

  for (const tableAction of tableActions) {
    let action = backupActions[tableAction.actionId];

    if (!action) {
      const tableActionsByActionId = actions.get(tableAction.actionId);
      if (!tableActionsByActionId) continue;

      action = tableActionsByActionId[0];
    }

    if (TAKEN_ACTIONS.includes(action.actionTypeId)) {
      const actionMetadata = getActionMetadata(action);

      if (!('actionContext' in actionMetadata)) continue;

      if (
        !!actionMetadata.actionContext &&
        actionContextValues.includes(actionMetadata.actionContext)
      ) {
        setRecentlyAwardedAction(null);
        return;
      }
    }

    if (AWARDED_ACTIONS.includes(action.actionTypeId)) {
      awardedAction = action;
      break;
    }
  }

  if (!awardedAction) return;

  const awardedActionMetadata = getActionMetadata(awardedAction);

  if (!('position' in awardedActionMetadata)) return;

  switch (awardedAction.actionTypeId) {
    case ACTION_TYPE_ID.FreeKickAwarded:
      setFreeKickCoords(awardedActionMetadata.position);
      break;
    case ACTION_TYPE_ID.ThrowInAwarded:
      setThrowInCoords(awardedActionMetadata.position);
      break;
    case ACTION_TYPE_ID.CornerAwarded:
      setCornerCoords(awardedActionMetadata.position);
      break;
    case ACTION_TYPE_ID.GoalKickAwarded:
      setGoalKickCoords(awardedActionMetadata.position);
      break;
    default:
      break;
  }

  setRecentlyAwardedAction(awardedAction);
}

export async function setPeriodDetails(
  periodDetails: Config_PeriodDetailDto[],
) {
  return useFixtureStore.setState(
    (state) => {
      if (state.periodDetails === periodDetails)
        return { periodDetails: state.periodDetails };

      return { periodDetails };
    },
    false,
    'setPeriodDetails',
  );
}

/**
 * Should be only called when received actions
 */
export function setActions(actions: Map<string, Action[]>) {
  return useFixtureStore.setState(
    (state) => {
      const currentActions = state.actions;
      if (!currentActions) {
        return { actions };
      }
      return { actions: new Map([...actions, ...currentActions]) };
    },
    false,
    'setActions',
  );
}
/**
 * Should be only called when received actions
 */
export function setTableActions(tableActions: TableAction[]) {
  return useFixtureStore.setState(
    (state) => {
      const notPostedActions = state.tableActions.filter(
        ({ state }) => state === ACTION_STATE.NONE,
      );
      const newTableActions = [...notPostedActions, ...tableActions];
      return { tableActions: newTableActions };
    },
    false,
    'setTableActions',
  );
}

/**
 * Stores the action.
 * This function is called before and after sync process.
 * New action is stored and backed up.
 * Confirmed action removes a backup and changes the state to success.
 */
export function storeAction(action: Action) {
  useFixtureStore.setState(
    (state) => {
      if (action.state === ACTION_STATE.NONE) {
        return { actions: state.actions };
      }

      if (action.state === ACTION_STATE.SUCCESS) {
        removeBackupAction(action);
      } else {
        backupAction(action);
      }

      const newActions = new Map(state.actions);
      const actionHistory = newActions.get(action.actionId) || [];
      const confirmedActionIdx = actionHistory.findIndex((a) =>
        isExactAction(a, action),
      );

      const needsNewEntry =
        !!actionHistory[0] && actionHistory[0].state !== ACTION_STATE.ERROR;

      if (confirmedActionIdx >= 0) {
        actionHistory[confirmedActionIdx] = action;
      } else if (needsNewEntry) {
        actionHistory.unshift(action);
      } else {
        actionHistory[0] = action;
      }

      const newHistory = actionHistory.sort(sortByUpdatedAt);
      newActions.set(action.actionId, newHistory);

      if (action.updatedAt >= newHistory[0].updatedAt) {
        updateTableAction(action);
      }
      return {
        actions: newActions,
      };
    },
    false,
    'storeAction',
  );
}

/**
 * Use this to manipulate table actions.
 * This is called whenever a new action is added (received from socket).
 * This can be called to control action state and display the updates.
 * Using this causes optimistic updates to actions table,
 * unfortunately, for now, we do not revert the state in case of failure.
 */
export function updateTableAction(
  action: Action,
  actionUpdate?: Partial<TableAction>,
) {
  if (action.isRemoved && action.state === ACTION_STATE.SUCCESS) {
    return removeTableAction(action.actionId);
  }

  return useFixtureStore.setState(
    (state) => {
      const newActions = [...state.tableActions];
      const updated = { ...mapToTableAction(action), ...actionUpdate };

      const originalIndex = state.tableActions.findIndex(
        ({ actionId }) => actionId === action.actionId,
      );
      if (originalIndex < 0) {
        const originalFirstItem = state.tableActions[0];
        newActions.unshift(updated);

        if (
          originalFirstItem &&
          originalFirstItem.clockTimeTicks > updated.clockTimeTicks
        ) {
          newActions.sort(sortByActionTimes);
        }
      } else {
        newActions[originalIndex] = updated;
        if (
          updated.clockTimeTicks !==
          state.tableActions[originalIndex].clockTimeTicks
        ) {
          newActions.sort(sortByActionTimes);
        }
      }

      return {
        tableActions: newActions,
      };
    },
    false,
    'updateTableAction',
  );
}

/**
 * Removes the action from the table.
 * Use this to remove actions that are not synced.
 * For example, canceling a new pass which was not yet posted.
 *
 * Removing from actions table is not the same as
 * marking the action as `isRemoved` for the system.
 */
export function removeTableAction(actionId: string) {
  return useFixtureStore.setState(
    (state) => {
      const index = state.tableActions.findIndex(
        (tableAction) => tableAction.actionId === actionId,
      );
      if (index < 0) {
        return state;
      }
      const newTableActions = [...state.tableActions];
      newTableActions.splice(index, 1);
      return { tableActions: newTableActions };
    },
    false,
    'removeTableAction',
  );
}

export function getLatestActionByActionId(actionId: string) {
  const actions = useFixtureStore.getState().actions.get(actionId);
  return actions ? actions[0] : null;
}

/**
 * Based on tableActions which needs to be always sorted
 * desc by clock time and createdAt properties.
 * Looks for index of given `actionId` and returns
 * the very next action.
 * The result is a previous action relative to given actionId.
 */
export function getRelativePreviousAction(
  actionId: string | undefined | null,
): Action | null {
  const { tableActions, actions } = useFixtureStore.getState();
  if (!actionId) {
    const latestAction = tableActions[0];
    if (!latestAction) return null;
    const latestActionHistory = actions.get(latestAction.actionId);
    if (!latestActionHistory) return null;
    return latestActionHistory[0];
  }
  const actionIdx = tableActions.findIndex((a) => a.actionId === actionId);
  if (actionIdx < 0) return null;
  const previousActionIdx = actionIdx + 1;
  if (!tableActions[previousActionIdx]) return null;
  const previousAction = actions.get(tableActions[previousActionIdx].actionId);
  if (!previousAction) return null;
  if (previousAction[0].isRemoved) {
    return getRelativePreviousAction(previousAction[0].actionId);
  }
  return previousAction[0];
}

export function getInitialPossessionPhaseId(actionsMap: Map<string, Action[]>) {
  const actions = [...actionsMap.values()]
    .map(([latestAction]) => latestAction)
    .sort(sortByActionTimes);

  if (actions.length === 0) {
    return DEFAULT_STATE.teamPossessionPhase;
  }

  const lastInPossessionActionIdx = actions.findIndex(isInPossessionAction);
  const lastEndPossessionActionIdx = actions.findIndex(isEndPossessionAction);

  if (lastEndPossessionActionIdx === -1 && lastInPossessionActionIdx === -1) {
    return DEFAULT_STATE.teamPossessionPhase;
  }

  if (
    lastInPossessionActionIdx > lastEndPossessionActionIdx &&
    lastEndPossessionActionIdx !== -1
  ) {
    return {
      id: actions[lastEndPossessionActionIdx].teamPossessionPhaseId || 0,
      shouldBump: true,
    };
  }

  return {
    id: actions[lastInPossessionActionIdx]?.teamPossessionPhaseId || 0,
    shouldBump: false,
  };
}

export function getTeamPossessionPhaseId(action: Action): number | null {
  const { id, shouldBump } = useFixtureStore.getState().teamPossessionPhase;

  if (isInPossessionAction(action) && shouldBump) {
    const bumpedId = id + 1;
    useFixtureStore.setState({
      teamPossessionPhase: { id: bumpedId, shouldBump: false },
    });

    return bumpedId;
  }

  if (isEndPossessionAction(action)) {
    useFixtureStore.setState({ teamPossessionPhase: { id, shouldBump: true } });
  }

  return id;
}
