import { ACTION_TYPE_ID } from '@contract';

export const ACTION_TYPE: Record<ACTION_TYPE_ID, string> = {
  [ACTION_TYPE_ID.Pass]: 'Pass',
  [ACTION_TYPE_ID.Shot]: 'Shot',
  [ACTION_TYPE_ID.Goal]: 'Goal',
  [ACTION_TYPE_ID.OwnGoal]: 'Own goal',

  [ACTION_TYPE_ID.Lineup]: 'Lineup',

  [ACTION_TYPE_ID.StartClock]: 'Start clock',
  [ACTION_TYPE_ID.EditClock]: 'Edit clock',
  [ACTION_TYPE_ID.StopClock]: 'Stop clock',

  [ACTION_TYPE_ID.StartDelay]: 'Start delay',
  [ACTION_TYPE_ID.EndDelay]: 'End delay',

  [ACTION_TYPE_ID.StartPeriod]: 'Start period',
  [ACTION_TYPE_ID.EndPeriod]: 'End period',

  [ACTION_TYPE_ID.EndFixture]: 'End fixture',
  [ACTION_TYPE_ID.StartFixture]: 'Start fixture',

  [ACTION_TYPE_ID.Aerial]: 'Aerial',
  [ACTION_TYPE_ID.BallRecovery]: 'Ball recovery',
  [ACTION_TYPE_ID.BallTouch]: 'Ball touch',
  [ACTION_TYPE_ID.Block]: 'Block',
  [ACTION_TYPE_ID.Dispossessed]: 'Dispossessed',
  [ACTION_TYPE_ID.GoalkeeperPenaltyFaced]: 'Goalkeeper penalty faced',
  [ACTION_TYPE_ID.GoalkeeperSave]: 'Goalkeeper save',
  [ACTION_TYPE_ID.OffsideFor]: 'Offside for',
  [ACTION_TYPE_ID.Offside]: 'Offside',
  [ACTION_TYPE_ID.Clearance]: 'Clearance',
  [ACTION_TYPE_ID.Cross]: 'Cross',
  [ACTION_TYPE_ID.Launch]: 'Launch',
  [ACTION_TYPE_ID.CornerAwarded]: 'Corner awarded',
  [ACTION_TYPE_ID.CornerTaken]: 'Corner taken',
  [ACTION_TYPE_ID.FreeKickAwarded]: 'Free kick',
  [ACTION_TYPE_ID.FreeKickTaken]: 'Free kick taken',
  [ACTION_TYPE_ID.ThrowInAwarded]: 'Throw in awarded',
  [ACTION_TYPE_ID.ThrowInTaken]: 'Throw in taken',
  [ACTION_TYPE_ID.GoalKickAwarded]: 'Goal kick awarded',
  [ACTION_TYPE_ID.GoalKickTaken]: '',
  [ACTION_TYPE_ID.Card]: 'Card',
  [ACTION_TYPE_ID.Dribble]: 'Dribble',
  [ACTION_TYPE_ID.Tackle]: 'Tackle',
  [ACTION_TYPE_ID.Interception]: 'Interception',
  [ACTION_TYPE_ID.GoalkeeperCollection]: 'Goalkeeper collection',
  [ACTION_TYPE_ID.GoalkeeperPunch]: 'Goalkeeper punch',
  [ACTION_TYPE_ID.GoalkeeperCatch]: 'Goalkeeper catch',
  [ACTION_TYPE_ID.GoalkeeperDrop]: 'Goalkeeper drop',
  [ACTION_TYPE_ID.VARPending]: 'VAR pending',
  [ACTION_TYPE_ID.VARDecision]: 'VAR decision',
  [ACTION_TYPE_ID.BigChance]: 'Big chance',
  [ACTION_TYPE_ID.PlayerOn]: 'Player on',
  [ACTION_TYPE_ID.PlayerOff]: 'Player off',
  [ACTION_TYPE_ID.Substitution]: 'Substitution',
  [ACTION_TYPE_ID.AddedTime]: '',
  [ACTION_TYPE_ID.PostMatchChecks]: '',
  [ACTION_TYPE_ID.PlayResumed]: 'Play resumed',
  [ACTION_TYPE_ID.PlayStopped]: 'Play stopped',
  [ACTION_TYPE_ID.PenaltyAwarded]: '',
  [ACTION_TYPE_ID.PenaltyTaken]: '',
  [ACTION_TYPE_ID.BadTouch]: 'Bad Touch',
  [ACTION_TYPE_ID.GoalkeeperThrow]: 'Goalkeeper throw',
};

export type ActionType = typeof ACTION_TYPE;
