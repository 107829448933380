import { Checkbox, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import type { Action } from '@/types/action/action';
import { getActionMetadata } from '@/utils/actions';
import { ASSIST_LABEL } from '@/constants';

type IsAssistActionFieldProps = {
  action: Action;
};

export const IsAssistActionField: FC<IsAssistActionFieldProps> = ({
  action,
}) => {
  const metadata = getActionMetadata(action);
  const hasIsAssistMeta =
    'isAssist' in metadata && typeof metadata.isAssist === 'boolean';
  if (!hasIsAssistMeta) return;

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={(_, checked) =>
            setActionPartialMetadata({ isAssist: checked })
          }
          checked={metadata.isAssist}
        />
      }
      label={ASSIST_LABEL}
    />
  );
};
