/* eslint-disable no-restricted-imports */
export * from '@img-arena/sps-msed-contract';
import { MetadataDto } from '@img-arena/sps-msed-contract';
export { FixtureActionType as ACTION_TYPE_ID } from '@img-arena/sps-msed-contract';

export const METADATA_KEY: { [K in keyof MetadataDto]: K } = {
  addedTime: 'addedTime',
  aerial: 'aerial',
  ballRecovery: 'ballRecovery',
  ballTouch: 'ballTouch',
  bigChance: 'bigChance',
  block: 'block',
  card: 'card',
  clearance: 'clearance',
  clock: 'clock',
  cornerAwarded: 'cornerAwarded',
  cross: 'cross',
  dispossessed: 'dispossessed',
  dribble: 'dribble',
  freeKickAwarded: 'freeKickAwarded',
  goal: 'goal',
  goalkeeperCatch: 'goalkeeperCatch',
  goalkeeperCollection: 'goalkeeperCollection',
  goalkeeperDrop: 'goalkeeperDrop',
  goalkeeperPenaltyFaced: 'goalkeeperPenaltyFaced',
  goalkeeperPunch: 'goalkeeperPunch',
  goalkeeperSave: 'goalkeeperSave',
  goalKickAwarded: 'goalKickAwarded',
  interception: 'interception',
  launch: 'launch',
  lineup: 'lineup',
  offside: 'offside',
  offsideFor: 'offsideFor',
  ownGoal: 'ownGoal',
  pass: 'pass',
  penaltyAwarded: 'penaltyAwarded',
  penaltyTaken: 'penaltyTaken',
  playerOff: 'playerOff',
  playerOn: 'playerOn',
  playResumed: 'playResumed',
  playStopped: 'playStopped',
  postMatchChecks: 'postMatchChecks',
  shot: 'shot',
  substitution: 'substitution',
  tackle: 'tackle',
  throwInAwarded: 'throwInAwarded',
  throwInTaken: 'throwInTaken',
  varDecision: 'varDecision',
  varPending: 'varPending',
  badTouch: 'badTouch',
  goalkeeperThrow: 'goalkeeperThrow',
  endFixture: 'endFixture',
  endPeriod: 'endPeriod',
  startFixture: 'startFixture',
  startPeriod: 'startPeriod',
};

export type MetadataKey = Pretty<keyof typeof METADATA_KEY>;
