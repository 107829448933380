import { Button, type ButtonProps } from '@mui/material';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import {
  submitStartingLineup,
  useLineupStore,
} from '@/stores/LineupStore/LineupStore';
import { PITCH_PLAYERS_MAX_LEN } from '@/stores/LineupStore/constants';

export const SubmitButton: FC<ButtonProps> = (props) => {
  const squadLength = useLineupStore((state) => state.pitchPlayersLenght);
  const isGoalKeeperSelected = useLineupStore((state) => state.isGKSelected);
  const isDisabled =
    squadLength !== PITCH_PLAYERS_MAX_LEN || !isGoalKeeperSelected;
  const navigate = useNavigate();

  const submit = () => {
    submitStartingLineup();
    navigate('..', { relative: 'route' });
  };

  return (
    <Button
      {...props}
      onClick={submit}
      disabled={props.disabled || isDisabled}
      variant='contained'
    >
      Submit
    </Button>
  );
};
