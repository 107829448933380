import { addLeadingZero } from './formatDate';

export const formatTime = (time: string) => {
  if (!time) return '';

  const [m, s] = time.split(':');
  const minutesAsNumber = parseInt(m);
  const secondsAsNumber = parseInt(s);
  const minutes = addLeadingZero(minutesAsNumber);
  const seconds = addLeadingZero(secondsAsNumber);

  if (!s) {
    return `00:${minutes}`;
  }

  return `${minutes}:${seconds}`;
};
