import { Box, Stack, colors, styled } from '@mui/material';
import {
  FC,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebounce, useWindowSize } from 'usehooks-ts';
import { OutPortal } from 'react-reverse-portal';
import PitchHorizontalSVG from '@/assets/football-pitch.svg?react';
import {
  cancelPass,
  cancelPitch,
  resetToPitchMode,
  setCoords,
  setPassCoords,
  useActionStore,
} from '@/stores/ActionStore/ActionStore';
import { commitAction } from '@/stores/ActionStore/commitActions';
import { MODE } from '@/stores/ActionStore/constants';
import { createNestedCoords } from '@/stores/ActionStore/utils';
import { setStreamContainer, useUIStore } from '@/stores/UIStore';
import { ACTION_TYPE_ID } from '@contract';
import { CoordsTarget } from './CoordsTarget';
import { PassCoordsTarget } from './PassCoordsTarget';
import { MOUSE_BUTTON, logKeyDown, logMouseClick } from './interactions';

export const PITCH_PADDING = 3;

const HoverBox = styled(Box)({
  position: 'absolute',
  '&:hover': {
    backgroundColor: colors.deepPurple[500],
  },
});

const HorizontalHoverBox = styled(HoverBox)({
  width: `${50 - PITCH_PADDING}%`,
  height: `${PITCH_PADDING + PITCH_PADDING / 2}%`,
});

const VerticalHoverBox = styled(HoverBox)({
  width: `${PITCH_PADDING}%`,
  height: '50%',
});

const PitchSVG = styled(PitchHorizontalSVG)`
  flex: 1;
`;

export const PITCH_MAIN_ID = 'pitch-main';

export const Pitch: FC = () => {
  const $pitchContainer = useRef<HTMLDivElement | null>(null);
  const $pitchBorderContainer = useRef<HTMLDivElement | null>(null);
  const $streamPortalNode = useUIStore((state) => state.$streamPortalNode);
  const $streamContainer = useUIStore((state) => state.$streamContainer);
  const mode = useActionStore((state) => state.mode);
  const [pitchClientRect, setPitchClientRect] = useState<DOMRect>();
  const [pitchBorderClientRect, setPitchBorderClientRect] = useState<DOMRect>();
  const windowSize = useDebounce(useWindowSize(), 300);

  useEffect(() => {
    window.addEventListener('keydown', logKeyDown, false);

    return () => {
      window.removeEventListener('keydown', logKeyDown, false);
    };
  }, []);

  useEffect(() => {
    setStreamContainer($pitchContainer.current);
    return () => {
      setStreamContainer(null);
    };
  }, [$pitchContainer]);

  useEffect(() => {
    if (!$pitchContainer.current) return;

    setPitchClientRect($pitchContainer.current.getBoundingClientRect());
  }, [$pitchContainer, windowSize]);

  useEffect(() => {
    if (!$pitchBorderContainer.current) return;

    setPitchBorderClientRect(
      $pitchBorderContainer.current.getBoundingClientRect(),
    );
  }, [$pitchBorderContainer, windowSize]);

  function getOnMouseMove() {
    if (mode !== MODE.PASS && mode !== MODE.THROW_IN) return undefined;
    return function onMouseMove(e: ReactMouseEvent) {
      if (!pitchClientRect || !pitchBorderClientRect) return;

      const coords = createNestedCoords(
        e,
        pitchBorderClientRect,
        pitchClientRect,
      );

      setPassCoords(coords);
    };
  }

  function onClick(e: ReactMouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (
      !(
        e.target instanceof HTMLDivElement || e.target instanceof SVGPathElement
      ) ||
      !pitchClientRect ||
      !pitchBorderClientRect
    ) {
      return;
    }

    const clickedCoords = createNestedCoords(
      e,
      pitchBorderClientRect,
      pitchClientRect,
    );

    logMouseClick(MOUSE_BUTTON.LEFT, e, pitchClientRect);

    if (mode === MODE.PITCH) {
      setCoords(clickedCoords);
      return;
    }
    if (mode === MODE.PASS) {
      return commitAction(ACTION_TYPE_ID.Pass);
    }
    if (mode === MODE.CROSS) {
      return commitAction(ACTION_TYPE_ID.Cross);
    }
    if (mode === MODE.LAUNCH) {
      return commitAction(ACTION_TYPE_ID.Launch);
    }
    if (mode === MODE.GOALKEEPER_THROW) {
      return commitAction(ACTION_TYPE_ID.GoalkeeperThrow);
    }
    if (mode === MODE.THROW_IN) {
      return commitAction(ACTION_TYPE_ID.ThrowInTaken);
    }
  }

  function onMouseLeave() {
    if (mode === 'pass') {
      setPassCoords(null);
    }
  }

  function onRightClick(e: ReactMouseEvent) {
    e.preventDefault();

    logMouseClick(MOUSE_BUTTON.RIGHT, e, pitchClientRect);

    if (
      mode === MODE.PASS ||
      mode === MODE.CROSS ||
      mode === MODE.LAUNCH ||
      mode === MODE.GOALKEEPER_THROW
    ) {
      cancelPass();
      return;
    }
    if (mode === MODE.PITCH) {
      cancelPitch();
      return;
    }
    if (mode === MODE.THROW_IN) {
      resetToPitchMode();
      return;
    }
  }

  return (
    <Stack
      onClick={onClick}
      onMouseLeave={onMouseLeave}
      onContextMenu={onRightClick}
      onMouseMove={getOnMouseMove()}
      ref={$pitchBorderContainer}
      sx={{
        position: 'relative',
        flexDirection: 'row',
        pointerEvents: 'all',
        padding: `${PITCH_PADDING}%`,
        backgroundColor: 'var(--pitchColor)',
      }}
    >
      <VerticalHoverBox id='pitch-border-left-top' sx={{ top: 0, left: 0 }} />
      <VerticalHoverBox
        id='pitch-border-left-bottom'
        sx={{ top: '50%', left: 0 }}
      />
      <HorizontalHoverBox
        id='pitch-border-top-left'
        sx={{ top: 0, left: '3%' }}
      />
      <HorizontalHoverBox
        id='pitch-border-top-right'
        sx={{ top: 0, left: '50%' }}
      />
      <HorizontalHoverBox
        id='pitch-border-bottom-left'
        sx={{ bottom: 0, left: '3%' }}
      />
      <HorizontalHoverBox
        id='pitch-border-bottom-right'
        sx={{ bottom: 0, left: '50%' }}
      />
      <VerticalHoverBox id='pitch-border-right-top' sx={{ top: 0, right: 0 }} />
      <VerticalHoverBox
        id='pitch-border-right-bottom'
        sx={{ top: '50%', right: 0 }}
      />
      <Stack id={PITCH_MAIN_ID} ref={$pitchContainer} sx={{ width: '100%' }}>
        <PitchSVG />
        {$streamContainer === $pitchContainer.current &&
          !!$streamPortalNode && <OutPortal node={$streamPortalNode} />}
        <CoordsTarget />
        <PassCoordsTarget />
      </Stack>
    </Stack>
  );
};
