/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { Dialog } from '@/components/Dialog/Dialog';
import { PlayStoppedAction } from '@/types/action/action';
import { OPPOSITE_TEAM_LABEL, PLAYER_LABEL, REASON_LABEL } from '@/constants';
import { Controls } from '@/components/Dialogs/Common/Controls';
import { PLAY_STOPPED_DIALOG_TITLE } from '@/components/Dialogs/constants';
import {
  setAction,
  setActionPartialMetadata,
} from '@/stores/ActionStore/ActionStore';
import { postAction } from '@/service/helpers/postAction';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { PlayStoppedReason } from '@contract';
import { getActionMetadata } from '@/utils/actions';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { PlayerAutocomplete } from '@/components/Form/PlayerAutocomplete';
import { Player } from '@/service/lineups';
import { PLAY_STOPPED_REASON_OPTIONS } from './constants';

interface PlayStoppedDialogProps {
  onClose: VoidFunction;
  action: PlayStoppedAction;
}

const onReasonChange = (reason: PlayStoppedReason) => {
  setActionPartialMetadata<PlayStoppedAction>({ reason });
};

const onOppositeTeamChange = (
  _: ChangeEvent<HTMLInputElement>,
  checked: boolean,
) => {
  setActionPartialMetadata<PlayStoppedAction>({ isFromOppositeTeam: checked });
};

export const PlayStoppedDialog: FC<PlayStoppedDialogProps> = ({
  onClose,
  action,
}) => {
  const defaultAction = useRef(action);
  const metadata = getActionMetadata(action);

  const onPlayerChange = (player: Player | null) => {
    setAction({
      ...action,
      player,
    });
  };

  useEffect(() => {
    if (
      metadata.reason !== PlayStoppedReason.Injury ||
      metadata.isFromOppositeTeam
    ) {
      setAction({
        ...action,
        player: null,
      });
    }
    if (
      metadata.reason !== PlayStoppedReason.Injury &&
      metadata.isFromOppositeTeam
    ) {
      setActionPartialMetadata<PlayStoppedAction>({
        isFromOppositeTeam: false,
      });
    }
  }, [metadata.reason, metadata.isFromOppositeTeam]);

  const onSubmit = () => {
    postAction(action);
    onClose();
  };

  return (
    <Dialog isOpen title={PLAY_STOPPED_DIALOG_TITLE}>
      <Stack gap={2}>
        <Autocomplete
          options={PLAY_STOPPED_REASON_OPTIONS}
          value={metadata.reason}
          onChange={onReasonChange}
          label={REASON_LABEL}
        />
        <PlayerAutocomplete
          label={PLAYER_LABEL}
          player={action.player}
          disabled={
            metadata.reason !== PlayStoppedReason.Injury ||
            !!metadata.isFromOppositeTeam
          }
          onChange={onPlayerChange}
        />
        <PeriodClockField action={action} />
        <FormControlLabel
          control={
            <Checkbox
              onChange={onOppositeTeamChange}
              disabled={metadata.reason !== PlayStoppedReason.Injury}
              checked={!!metadata.isFromOppositeTeam}
            />
          }
          label={OPPOSITE_TEAM_LABEL}
        />
        <Controls
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={
            JSON.stringify(action) === JSON.stringify(defaultAction.current) ||
            (!metadata.isFromOppositeTeam &&
              (metadata.reason === null ||
                (metadata.reason === PlayStoppedReason.Injury &&
                  action.player === null)))
          }
        />
      </Stack>
    </Dialog>
  );
};
