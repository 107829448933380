import { isDevMode } from './environment';
import { SS_KEY, registerWindowUtil } from './window';

export function isDebugEnabled() {
  return window.sessionStorage.getItem(SS_KEY.DEBUG) === 'true';
}

if (window.sessionStorage.getItem(SS_KEY.DEBUG) === null && isDevMode) {
  window.sessionStorage.setItem(SS_KEY.DEBUG, 'true');
}

let logged = false;
if (isDevMode && !logged) {
  registerWindowUtil('setDebug', (enable) =>
    window.sessionStorage.setItem(SS_KEY.DEBUG, enable.toString()),
  );
  console.log('`window._ed.setDebug(boolean)` - enable/disable debug mode');
  logged = true;
}
export const isTraceStoreEnabled =
  window.sessionStorage.getItem(SS_KEY.TRACE_STORE) === 'true';
