import { FC } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DIRECTION } from './constants';

interface RadioButtonsProps {
  name: string;
  onChange: (arg: number) => void;
  value: number | null;
  controlLabel: string;
  fields: Record<string, string>;
  disabled?: boolean;
  onClear?: () => void;
  sx?: SxProps;
  direction?: DIRECTION;
}

export const RadioButtons: FC<RadioButtonsProps> = ({
  name,
  onChange,
  controlLabel,
  fields,
  disabled = false,
  value,
  onClear,
  sx,
  direction = DIRECTION.ROW,
}) => {
  return (
    <FormControl>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <FormLabel sx={{ textTransform: 'capitalize' }}>
          {controlLabel}
        </FormLabel>
        {onClear && (
          <IconButton onClick={onClear}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>

      <RadioGroup
        row={direction === DIRECTION.ROW}
        value={value}
        aria-labelledby={controlLabel}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        sx={sx}
      >
        {Object.keys(fields).map((value) => (
          <FormControlLabel
            key={value}
            value={value}
            disabled={disabled}
            control={<Radio />}
            label={fields[value]}
            name={name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
