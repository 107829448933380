import { Link as RouterLink, Outlet } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import {
  InPortal,
  OutPortal,
  createHtmlPortalNode,
} from 'react-reverse-portal';
import { BottomBar } from '@/components/BottomBar/BottomBar';
import {
  FixtureViewTabs,
  FixtureViewTabsProps,
} from '@/components/FixtureViewTabs/FixtureViewTabs';
import { NavBar } from '@/components/NavBar/NavBar';
import { useSocketStore } from '@/stores/SocketStore/SocketStore';
import { ROUTE_URL } from '@/router/constants';
import { LoadingOverlay } from '@/components/LoadingOverlay/LoadingOverlay';
import { HOME_BUTTON_LABEL } from '@/constants';
import { StreamVideo } from '@/components/StreamVideo/StreamVideo';
import { setStreamPortalNode, useUIStore } from '@/stores/UIStore';
import {
  useCombineCollectionInfo,
  useCurrentCollection,
} from '@/stores/CollectionStore';
import { useGetDataCollectionConfig } from '@/service/dataCollectionConfig';

export const FixtureView: FC<FixtureViewTabsProps> = ({ view }) => {
  useCombineCollectionInfo();
  useGetDataCollectionConfig();
  const collection = useCurrentCollection();
  const isSubscribedToActions = useSocketStore(
    (state) => state.actionsSubscribed,
  );
  const $streamContainer = useUIStore((state) => state.$streamContainer);
  const $streamPortalNode = useMemo(
    () =>
      createHtmlPortalNode({
        attributes: { id: 'stream-container' },
      }),
    [],
  );
  useEffect(() => {
    setStreamPortalNode($streamPortalNode);
    return () => {
      setStreamPortalNode(null);
    };
  }, [$streamPortalNode]);

  return (
    <Stack height='100vh' justifyContent='space-between'>
      <NavBar>
        <FixtureViewTabs view={view} />
        <Button
          component={RouterLink}
          to={ROUTE_URL.HOME}
          startIcon={<HomeIcon />}
        >
          {HOME_BUTTON_LABEL}
        </Button>
      </NavBar>

      <InPortal node={$streamPortalNode}>
        {collection && isSubscribedToActions && (
          <StreamVideo
            key={collection.id}
            // src='https://imgarena-fe-event-data-poc.s3.amazonaws.com/Colorado_Rapids_v_FC_Dallas.mp4'
          />
        )}
      </InPortal>

      {$streamContainer === null && <OutPortal node={$streamPortalNode} />}

      <Stack
        position='relative'
        flex='1'
        maxHeight={'100%'}
        overflow={'hidden'}
      >
        {isSubscribedToActions ? <Outlet /> : <LoadingOverlay />}
      </Stack>

      <BottomBar />
    </Stack>
  );
};
