import { postAction } from '@/service/helpers/postAction';
import {
  AerialAction,
  BadTouchAction,
  BallRecoveryAction,
  BallTouchAction,
  BigChanceAction,
  BlockAction,
  CardAction,
  ClearanceAction,
  CornerAwardedAction,
  CrossAction,
  DispossessedAction,
  GoalkeeperCatchAction,
  GoalkeeperCollectionAction,
  GoalkeeperDropAction,
  GoalkeeperPenaltyFacedAction,
  GoalkeeperPunchAction,
  GoalkeeperSaveAction,
  GoalkeeperThrowAction,
  GoalKickAwardedAction,
  InterceptionAction,
  LaunchAction,
  OffsideAction,
  OffsideForAction,
  PassAction,
  PlayerOffAction,
  PlayerOnAction,
  PlayResumedAction,
  TackleAction,
  ThrowInAwardedAction,
  ThrowInTakenAction,
} from '@/types/action/action';
import {
  ACTION_TYPE_ID,
  CardReason,
  CardType,
  GoalkeeperSaveAttemptType,
  METADATA_KEY,
  Position,
  GoalkeeperPenaltyFacedOutcome,
  Stance,
} from '@contract';
import { getActionWarnings } from '@/components/ActionWarnings/helpers';
import { isActionOutcomeEditable } from '@/utils/actions';
import { findPlayerById } from '@/stores/LineupStore/utils';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { setRecentlyAwardedAction, useActionStore } from './ActionStore';
import {
  createDefaultAction,
  getCornerCoords,
  getGoalKickCoords,
  modifyActionMetadata,
} from './utils';
import { MODE } from './constants';

const AT = ACTION_TYPE_ID;
const ACTION_COMMIT_FN: Partial<Record<ACTION_TYPE_ID, VoidFunction>> =
  Object.freeze({
    [AT.Aerial]: commitAerial,
    [AT.BadTouch]: commitBadTouch,
    [AT.BallRecovery]: commitBallRecovery,
    [AT.BallTouch]: commitBallTouch,
    [AT.BigChance]: commitBigChance,
    [AT.Block]: commitBlock,
    [AT.Card]: commitCard,
    [AT.Clearance]: commitClearance,
    [AT.CornerAwarded]: commitCornerAwarded,
    [AT.Cross]: commitPass,
    [AT.Dispossessed]: commitDispossessed,
    [AT.GoalkeeperCatch]: commitGoalkeeperCatch,
    [AT.GoalkeeperCollection]: commitGoalkeeperCollection,
    [AT.GoalkeeperDrop]: commitGoalkeeperDrop,
    [AT.GoalkeeperPenaltyFaced]: commitGoalkeeperPenaltyFaced,
    [AT.GoalkeeperPunch]: commitGoalkeeperPunch,
    [AT.GoalkeeperSave]: commitGoalkeeperSave,
    [AT.GoalkeeperThrow]: commitPass,
    [AT.GoalKickAwarded]: commitGoalKickAwarded,
    [AT.Interception]: commitInterception,
    [AT.Launch]: commitPass,
    [AT.Offside]: commitOffside,
    [AT.OffsideFor]: commitOffsideFor,
    [AT.Pass]: commitPass,
    [AT.PlayerOff]: commitPlayerOff,
    [AT.PlayerOn]: commitPlayerOn,
    [AT.PlayResumed]: commitPlayResumed,
    [AT.Tackle]: commitTackle,
    [AT.ThrowInAwarded]: commitThrowInAwarded,
    [AT.ThrowInTaken]: commitThrowInTaken,
  });

export function commitAction(actionTypeId: ACTION_TYPE_ID) {
  const commit = ACTION_COMMIT_FN[actionTypeId];
  if (commit) {
    commit();
  }
}

function commitCard() {
  return useActionStore.setState((state) => {
    if (!state.player) return state;

    const defaultAction = createDefaultAction<CardAction>(
      ACTION_TYPE_ID.Card,
      METADATA_KEY.card,
    );

    if (!defaultAction) return state;

    const action: CardAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.card]: {
          outcome: CardType.Yellow,
          reason: CardReason.Foul,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitPlayerOff() {
  return useActionStore.setState((state) => {
    if (!state.player) return state;

    const defaultAction = createDefaultAction<PlayerOffAction>(
      ACTION_TYPE_ID.PlayerOff,
      METADATA_KEY.playerOff,
    );

    if (!defaultAction) return state;

    const action: PlayerOffAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.playerOff]: {},
      },
    };

    postAction(action);

    return { action };
  });
}

function commitPlayerOn() {
  return useActionStore.setState((state) => {
    if (!state.player) return state;

    const defaultAction = createDefaultAction<PlayerOnAction>(
      ACTION_TYPE_ID.PlayerOn,
      METADATA_KEY.playerOn,
    );

    if (!defaultAction) return state;

    const action: PlayerOnAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.playerOn]: {},
      },
    };

    postAction(action);

    return { action };
  });
}

function commitBigChance() {
  return useActionStore.setState((state) => {
    if (!state.player) return state;

    const defaultAction = createDefaultAction<BigChanceAction>(
      ACTION_TYPE_ID.BigChance,
      METADATA_KEY.bigChance,
    );

    if (!defaultAction) return state;

    const action: BigChanceAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.bigChance]: {},
      },
    };

    postAction(action);

    return { action };
  });
}

function commitClearance() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<ClearanceAction>(
      ACTION_TYPE_ID.Clearance,
      METADATA_KEY.clearance,
    );

    if (!defaultAction) return state;

    const action: ClearanceAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.clearance]: {
          position: state.coords,
          bodyPart: null,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitGoalkeeperCatch() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<GoalkeeperCatchAction>(
      ACTION_TYPE_ID.GoalkeeperCatch,
      METADATA_KEY.goalkeeperCatch,
    );

    if (!defaultAction) return state;

    const action: GoalkeeperCatchAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.goalkeeperCatch]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitGoalkeeperCollection() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<GoalkeeperCollectionAction>(
      ACTION_TYPE_ID.GoalkeeperCollection,
      METADATA_KEY.goalkeeperCollection,
    );

    if (!defaultAction) return state;

    const action: GoalkeeperCollectionAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.goalkeeperCollection]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitGoalkeeperDrop() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<GoalkeeperDropAction>(
      ACTION_TYPE_ID.GoalkeeperDrop,
      METADATA_KEY.goalkeeperDrop,
    );

    if (!defaultAction) return state;

    const action: GoalkeeperDropAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.goalkeeperDrop]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitGoalkeeperPunch() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<GoalkeeperPunchAction>(
      ACTION_TYPE_ID.GoalkeeperPunch,
      METADATA_KEY.goalkeeperPunch,
    );

    if (!defaultAction) return state;

    const action: GoalkeeperPunchAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.goalkeeperPunch]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitInterception() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<InterceptionAction>(
      ACTION_TYPE_ID.Interception,
      METADATA_KEY.interception,
    );

    if (!defaultAction) return state;

    const action: InterceptionAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.interception]: {
          position: state.coords,
          bodyPart: null,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitOffside() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<OffsideAction>(
      ACTION_TYPE_ID.Offside,
      METADATA_KEY.offside,
    );

    if (!defaultAction) return state;

    const action: OffsideAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.offside]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitAerial() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<AerialAction>(
      ACTION_TYPE_ID.Aerial,
      METADATA_KEY.aerial,
    );

    if (!defaultAction) return state;

    const action: AerialAction = {
      ...defaultAction,
      isSuccessful: true,
      metadata: {
        [METADATA_KEY.aerial]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitDispossessed() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<DispossessedAction>(
      ACTION_TYPE_ID.Dispossessed,
      METADATA_KEY.dispossessed,
    );

    if (!defaultAction) return state;

    const action: DispossessedAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.dispossessed]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitBallTouch() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<BallTouchAction>(
      ACTION_TYPE_ID.BallTouch,
      METADATA_KEY.ballTouch,
    );

    if (!defaultAction) return state;

    const action: BallTouchAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.ballTouch]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitGoalkeeperSave() {
  const { pitchPlayers } = useLineupStore.getState();

  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<GoalkeeperSaveAction>(
      ACTION_TYPE_ID.GoalkeeperSave,
      METADATA_KEY.goalkeeperSave,
    );

    const playerPosition = pitchPlayers.find(
      ({ position }) => position === Position.Gk,
    );
    const goalkeeperPlayer = findPlayerById(
      playerPosition ? playerPosition.player.id : '',
    );

    if (!defaultAction) return state;

    const action: GoalkeeperSaveAction = {
      ...defaultAction,
      player: goalkeeperPlayer,
      metadata: {
        [METADATA_KEY.goalkeeperSave]: {
          attemptType: GoalkeeperSaveAttemptType.TemptSave,
          bodyPart: null,
          type: null,
          extras: null,
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitBallRecovery() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<BallRecoveryAction>(
      ACTION_TYPE_ID.BallRecovery,
      METADATA_KEY.ballRecovery,
    );

    if (!defaultAction) return state;

    const action: BallRecoveryAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.ballRecovery]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitGoalkeeperPenaltyFaced() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<GoalkeeperPenaltyFacedAction>(
      ACTION_TYPE_ID.GoalkeeperPenaltyFaced,
      METADATA_KEY.goalkeeperPenaltyFaced,
    );

    if (!defaultAction) return state;

    const action: GoalkeeperPenaltyFacedAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.goalkeeperPenaltyFaced]: {
          position: state.coords,
          outcome: GoalkeeperPenaltyFacedOutcome.Scored,
          stance: Stance.DivedLeft,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitBlock() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<BlockAction>(
      ACTION_TYPE_ID.Block,
      METADATA_KEY.block,
    );

    if (!defaultAction) return state;

    const action: BlockAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.block]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitOffsideFor() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<OffsideForAction>(
      ACTION_TYPE_ID.OffsideFor,
      METADATA_KEY.offsideFor,
    );

    if (!defaultAction) return state;

    const action: OffsideForAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.offsideFor]: {
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

export function toggleActionOutcome() {
  return useActionStore.setState((state) => {
    const { action } = state;
    if (!action) {
      return state;
    }
    if (!isActionOutcomeEditable(action.actionTypeId)) {
      return state;
    }

    const updatedAction = {
      ...action,
      isSuccessful: !action.isSuccessful,
    };
    updatedAction.warnings = getActionWarnings(updatedAction);

    postAction(updatedAction);
    setRecentlyAwardedAction(null);

    return { action: updatedAction };
  });
}

function commitPass() {
  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.passCoords) {
      return state;
    }
    if (!state.player) {
      return state;
    }

    const action = state.action;
    const actionId = action && action.actionId;
    if (
      !action ||
      !actionId ||
      (action.actionTypeMetadata !== METADATA_KEY.pass &&
        action.actionTypeMetadata !== METADATA_KEY.cross &&
        action.actionTypeMetadata !== METADATA_KEY.launch &&
        action.actionTypeMetadata !== METADATA_KEY.goalkeeperThrow)
    ) {
      return state;
    }
    const updatedAction:
      | PassAction
      | LaunchAction
      | CrossAction
      | GoalkeeperThrowAction = modifyActionMetadata(action, {
      targetPosition: {
        x: state.passCoords.x,
        y: state.passCoords.y,
      },
      bodyPart: null,
    });

    updatedAction.warnings = getActionWarnings(updatedAction);

    postAction(updatedAction);
    setRecentlyAwardedAction(null);

    return {
      coords: state.passCoords,
      passCoords: null,
      playerNumber: null,
      player: null,
      mode: MODE.PITCH,
      action: updatedAction,
    };
  });
}

function commitThrowInAwarded() {
  return useActionStore.setState((state) => {
    if (!state.coords) return state;

    const defaultAction = createDefaultAction<ThrowInAwardedAction>(
      ACTION_TYPE_ID.ThrowInAwarded,
      METADATA_KEY.throwInAwarded,
    );

    if (!defaultAction) return state;

    const action: ThrowInAwardedAction = {
      ...defaultAction,
      isSuccessful: true,
      metadata: {
        [METADATA_KEY.throwInAwarded]: {
          position: state.coords,
        },
      },
    };

    postAction(action);
    setRecentlyAwardedAction(action);

    return {
      action,
    };
  });
}

function commitThrowInTaken() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords || !state.passCoords) return state;

    const defaultAction = createDefaultAction<ThrowInTakenAction>(
      ACTION_TYPE_ID.ThrowInTaken,
      METADATA_KEY.throwInTaken,
    );

    if (!defaultAction) return state;

    const action: ThrowInTakenAction = {
      ...defaultAction,
      isSuccessful: true,
      metadata: {
        [METADATA_KEY.throwInTaken]: {
          position: state.coords,
          targetPosition: state.passCoords,
          targetPlayer: null,
          direction: null,
        },
      },
    };

    postAction(action);
    setRecentlyAwardedAction(null);

    return {
      coords: state.passCoords,
      passCoords: null,
      playerNumber: null,
      player: null,
      mode: MODE.PITCH,
      action,
    };
  });
}

function commitCornerAwarded() {
  return useActionStore.setState((state) => {
    if (!state.coords) return state;

    const defaultAction = createDefaultAction<CornerAwardedAction>(
      ACTION_TYPE_ID.CornerAwarded,
      METADATA_KEY.cornerAwarded,
    );

    if (!defaultAction) return state;

    const action: CornerAwardedAction = {
      ...defaultAction,
      isSuccessful: true,
      metadata: {
        [METADATA_KEY.cornerAwarded]: {
          position: state.coords,
        },
      },
    };

    postAction(action);
    setRecentlyAwardedAction(action);

    return {
      action,
      coords: getCornerCoords(state.coords),
    };
  });
}

function commitGoalKickAwarded() {
  return useActionStore.setState((state) => {
    if (!state.coords) return state;

    const defaultAction = createDefaultAction<GoalKickAwardedAction>(
      ACTION_TYPE_ID.GoalKickAwarded,
      METADATA_KEY.goalKickAwarded,
    );

    if (!defaultAction) return state;

    const action: GoalKickAwardedAction = {
      ...defaultAction,
      isSuccessful: true,
      metadata: {
        [METADATA_KEY.goalKickAwarded]: {
          position: state.coords,
        },
      },
    };

    postAction(action);
    setRecentlyAwardedAction(action);

    return {
      action,
      coords: getGoalKickCoords(state.coords),
    };
  });
}

function commitPlayResumed() {
  return useActionStore.setState((state) => {
    const defaultAction = createDefaultAction<PlayResumedAction>(
      ACTION_TYPE_ID.PlayResumed,
      METADATA_KEY.playResumed,
    );

    if (!defaultAction) return state;

    const action: PlayResumedAction = {
      ...defaultAction,
      metadata: {
        [METADATA_KEY.playResumed]: {},
      },
    };

    postAction(action);

    return { action };
  });
}

function commitTackle() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<TackleAction>(
      ACTION_TYPE_ID.Tackle,
      METADATA_KEY.tackle,
    );

    if (!defaultAction) return state;

    const action: TackleAction = {
      ...defaultAction,
      isSuccessful: true,
      metadata: {
        [METADATA_KEY.tackle]: {
          isAssist: false,
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}

function commitBadTouch() {
  return useActionStore.setState((state) => {
    if (!state.player || !state.coords) return state;

    const defaultAction = createDefaultAction<BadTouchAction>(
      ACTION_TYPE_ID.BadTouch,
      METADATA_KEY.badTouch,
    );

    if (!defaultAction) return state;

    const action: BadTouchAction = {
      ...defaultAction,
      isSuccessful: true,
      metadata: {
        [METADATA_KEY.badTouch]: {
          isAssist: false,
          position: state.coords,
        },
      },
    };

    postAction(action);

    return { action };
  });
}
