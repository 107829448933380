import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { HubConnectionState } from '@microsoft/signalr';
import ScoringWorkerUrl from '@/workers/scoring/worker?worker&url';
import {
  FixtureIdMsg,
  SCORING_WORKER_HOST_ACTION,
  ScoringAPIWorker,
  type SaveLineupMsg,
  type LineupsSubscriptionMsg,
} from '@/workers/scoring/types';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import type { SaveLineupDto } from '@contract';
import { registerWindowUtil } from '@/utils/window';
import { isDevMode } from '@/utils/environment';
import { onWorkerMessage } from './handlers';

export type SocketStore = {
  isOnline: boolean;
  isWorkerReady: boolean;
  wsConnection: HubConnectionState;
  worker: ScoringAPIWorker;
  actionsSubscribed: boolean;
  lineupsSubscribed: boolean;
  stateSubscribed: boolean;
  isLineupsInSync: boolean;
};

export const DEFAULT_STATE: SocketStore = {
  isOnline: true,
  wsConnection: HubConnectionState.Disconnected,
  isWorkerReady: false,
  actionsSubscribed: false,
  lineupsSubscribed: false,
  stateSubscribed: false,
  isLineupsInSync: false,
  worker: new Worker(getWorkerUrl(), { type: 'module' }),
};

export const useSocketStore = create<SocketStore>()(
  devtools(() => ({ ...DEFAULT_STATE }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'SocketStore',
  }),
);

export function setOnline() {
  return useSocketStore.setState({ isOnline: true });
}

export function setOffline() {
  return useSocketStore.setState({ isOnline: false });
}

export function setWSConnection(wsConnection: HubConnectionState) {
  if (useSocketStore.getState().wsConnection === wsConnection) return;
  return useSocketStore.setState({ wsConnection }, false, 'setWSConnection');
}

export function setIsWorkerReady(isWorkerReady: boolean) {
  if (useSocketStore.getState().isWorkerReady === isWorkerReady) return;
  return useSocketStore.setState({ isWorkerReady }, false, 'isWorkerReady');
}
export function setActionsSubscribed(actionsSubscribed: boolean) {
  return useSocketStore.setState({ actionsSubscribed });
}
export function setLineupsSubscribed(lineupsSubscribed: boolean) {
  return useSocketStore.setState({ lineupsSubscribed });
}
export function setStateSubscribed(stateSubscribed: boolean) {
  return useSocketStore.setState({ stateSubscribed });
}
export function setIsLineupsInSync(isLineupsInSync: boolean) {
  return useSocketStore.setState({ isLineupsInSync });
}

function getWorkerUrl() {
  const [workerFilePath, workerQueryString] = ScoringWorkerUrl.split('?');
  const workerSearchParams = new URLSearchParams(workerQueryString || '');

  return workerFilePath + '?' + workerSearchParams.toString();
}

export function addWorkerEventListener() {
  const worker = useSocketStore.getState().worker;

  worker.addEventListener('message', onWorkerMessage);
}

export function sendToken(token?: string) {
  if (!token) return;

  const worker = useSocketStore.getState().worker;

  worker.postMessage({
    action: SCORING_WORKER_HOST_ACTION.TOKEN,
    payload: token,
  });
}

export function sendFixtureId(fixtureId = '', oldFixtureId?: string) {
  const { wsConnection, worker } = useSocketStore.getState();

  if (wsConnection !== HubConnectionState.Connected) return;

  const message: FixtureIdMsg = {
    action: SCORING_WORKER_HOST_ACTION.FIXTURE_ID,
    payload: { fixtureId },
  };

  if (oldFixtureId) {
    message.payload.oldFixtureId = oldFixtureId;
  }

  worker.postMessage(message);
}

export function subscribeLineups(payload: LineupsSubscriptionMsg['payload']) {
  const { wsConnection, worker, lineupsSubscribed } = useSocketStore.getState();
  if (wsConnection !== HubConnectionState.Connected || lineupsSubscribed) {
    return;
  }

  const message: LineupsSubscriptionMsg = {
    action: SCORING_WORKER_HOST_ACTION.LINEUPS_SUBSCRIBE,
    payload,
  };
  worker.postMessage(message);
}

export function unsubscribeLineups(payload: LineupsSubscriptionMsg['payload']) {
  const { wsConnection, worker, lineupsSubscribed } = useSocketStore.getState();

  if (wsConnection !== HubConnectionState.Connected || !lineupsSubscribed) {
    return;
  }

  const message: LineupsSubscriptionMsg = {
    action: SCORING_WORKER_HOST_ACTION.LINEUPS_UNSUBSCRIBE,
    payload: payload,
  };
  worker.postMessage(message);
}

export function saveLineup(data: SaveLineupDto) {
  const { wsConnection, worker } = useSocketStore.getState();
  setIsLineupsInSync(false);

  if (wsConnection !== HubConnectionState.Connected) return;
  const message: SaveLineupMsg = {
    action: SCORING_WORKER_HOST_ACTION.SAVE_LINEUP,
    payload: data,
  };
  worker.postMessage(message);
}

isDevMode && registerWindowUtil('saveLineup', saveLineup);

window.addEventListener('offline', setOffline);
window.addEventListener('online', setOnline);
