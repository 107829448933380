import { CardType, CardReason } from '@contract';

export const CARD_OPTIONS = {
  [CardType.Yellow]: 'Yellow Card',
  [CardType.SecondYellow]: '2nd Yellow Card',
  [CardType.Red]: 'Red Card',
};

export const CardReasonsLabels: Record<CardReason, string> = {
  [CardReason.Foul]: 'Foul',
  [CardReason.Dive]: 'Dive',
  [CardReason.Handball]: 'Handball',
  [CardReason.Dissent]: 'Dissent',
  [CardReason.DangerousPlay]: 'Dangerous Play',
  [CardReason.Argument]: 'Argument',
  [CardReason.TimeWasting]: 'Time Wasting',
  [CardReason.ExcessiveCelebration]: 'Excessive Celebration',
  [CardReason.CrowdInteraction]: 'Crowd Interaction',
  [CardReason.PersistentInfringement]: 'Persistent Infringement',
  [CardReason.FoulAndAbusiveLanguage]: 'Foul and Abusive Language',
  [CardReason.Encroachment]: 'Encroachment',
  [CardReason.LeavingField]: 'Leaving Field',
  [CardReason.Spitting]: 'Spitting',
  [CardReason.OffTheBallFoul]: 'Off the Ball Foul',
  [CardReason.UnsportingBehaviour]: 'Unsporting Behaviour',
  [CardReason.NotRetreating]: 'Not Retreating',
  [CardReason.RecklessOffence]: 'Reckless Offence',
  [CardReason.TacticalFoul]: 'Tactical Foul',
  [CardReason.OtherReason]: 'Other Reason',
  [CardReason.ViolentConduct]: 'Violent Conduct',
  [CardReason.SeriousFoul]: 'Serious Foul',
  [CardReason.ProfessionalFoulLastMan]: 'Professional Foul Last Man',
  [CardReason.ProfessionalFoulHandball]: 'Professional Foul Handball',
  [CardReason.EnteringField]: 'Entering Field',
};

export const YELLOW_CARD_OPTIONS = [
  {
    label: CardReasonsLabels[CardReason.Foul],
    value: CardReason.Foul,
  },
  {
    label: CardReasonsLabels[CardReason.Dive],
    value: CardReason.Dive,
  },
  {
    label: CardReasonsLabels[CardReason.Handball],
    value: CardReason.Handball,
  },
  {
    label: CardReasonsLabels[CardReason.Dissent],
    value: CardReason.Dissent,
  },
  {
    label: CardReasonsLabels[CardReason.DangerousPlay],
    value: CardReason.DangerousPlay,
  },
  {
    label: CardReasonsLabels[CardReason.Argument],
    value: CardReason.Argument,
  },
  {
    label: CardReasonsLabels[CardReason.TimeWasting],
    value: CardReason.TimeWasting,
  },
  {
    label: CardReasonsLabels[CardReason.ExcessiveCelebration],
    value: CardReason.ExcessiveCelebration,
  },
  {
    label: CardReasonsLabels[CardReason.CrowdInteraction],
    value: CardReason.CrowdInteraction,
  },
  {
    label: CardReasonsLabels[CardReason.PersistentInfringement],
    value: CardReason.PersistentInfringement,
  },
  {
    label: CardReasonsLabels[CardReason.FoulAndAbusiveLanguage],
    value: CardReason.FoulAndAbusiveLanguage,
  },
  {
    label: CardReasonsLabels[CardReason.Encroachment],
    value: CardReason.Encroachment,
  },
  {
    label: CardReasonsLabels[CardReason.LeavingField],
    value: CardReason.LeavingField,
  },
  {
    label: CardReasonsLabels[CardReason.Spitting],
    value: CardReason.Spitting,
  },
  {
    label: CardReasonsLabels[CardReason.OffTheBallFoul],
    value: CardReason.OffTheBallFoul,
  },
  {
    label: CardReasonsLabels[CardReason.UnsportingBehaviour],
    value: CardReason.UnsportingBehaviour,
  },
  {
    label: CardReasonsLabels[CardReason.NotRetreating],
    value: CardReason.NotRetreating,
  },
  {
    label: CardReasonsLabels[CardReason.RecklessOffence],
    value: CardReason.RecklessOffence,
  },
  {
    label: CardReasonsLabels[CardReason.TacticalFoul],
    value: CardReason.TacticalFoul,
  },
  {
    label: CardReasonsLabels[CardReason.OtherReason],
    value: CardReason.OtherReason,
  },
];

export const RED_CARD_OPTIONS = [
  {
    label: CardReasonsLabels[CardReason.OtherReason],
    value: CardReason.OtherReason,
  },
  {
    label: CardReasonsLabels[CardReason.ViolentConduct],
    value: CardReason.ViolentConduct,
  },
  {
    label: CardReasonsLabels[CardReason.SeriousFoul],
    value: CardReason.SeriousFoul,
  },
  {
    label: CardReasonsLabels[CardReason.ProfessionalFoulLastMan],
    value: CardReason.ProfessionalFoulLastMan,
  },
  {
    label: CardReasonsLabels[CardReason.ProfessionalFoulHandball],
    value: CardReason.ProfessionalFoulHandball,
  },
];

export const CARD_REASON_OPTION = {
  [CardType.Yellow]: YELLOW_CARD_OPTIONS,
  [CardType.SecondYellow]: YELLOW_CARD_OPTIONS,
  [CardType.Red]: RED_CARD_OPTIONS,
} as const;
