import { v1 as uuid } from 'uuid';
import { MouseEvent } from 'react';
import { Player } from '@/service/lineups';
import {
  Action,
  CrossAction,
  GoalkeeperThrowAction,
  LaunchAction,
  PassAction,
} from '@/types/action/action';
import { ACTION_TYPE_ID, CoordinatesDto, METADATA_KEY } from '@contract';
import { getPitchOffset } from '@/components/Pitch/utils';
import { clamp } from '@/utils/clamp';
import { mirrorValueInRange } from '@/utils/mirrorValueInRange';
import { PITCH_PADDING } from '@/components/Pitch/Pitch';
import { getActionMetadata } from '@/utils/actions';
import { ACTION_STATE } from '../constants';
import { getCurrentClock } from '../ClockStore';
import { useFixtureStore } from '../FixtureStore';
import { useUserStore } from '../UserStore';
import { useLineupStore } from '../LineupStore/LineupStore';
import { PitchCoords, setCoords, useActionStore } from './ActionStore';
import { ACTION_OUTCOME_DEFAULT_SUCCESS, MODE, PassMode } from './constants';

export function getDefaultIsSuccessful(actionTypeId: Action['actionTypeId']) {
  if (ACTION_OUTCOME_DEFAULT_SUCCESS.includes(actionTypeId)) {
    return true;
  }
  return null;
}

export function createDefaultAction<T extends Action>(
  actionTypeId: T['actionTypeId'],
  actionTypeMetadata: T['actionTypeMetadata'],
) {
  const { collectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();
  const { player } = useActionStore.getState();

  if (!collectionId || !teamId || !userId) return null;
  const now = Date.now();
  return {
    actionId: uuid(),
    actionTypeId,
    actionTypeMetadata,
    createdAt: now,
    updatedAt: now,
    fixtureId: collectionId,
    isRemoved: false,
    messageId: null,
    team: {
      id: teamId,
      name: '',
    },
    parentActionId: null,
    period: null,
    teamPossessionPhaseId: null,
    userId,
    warnings: null,
    player,
    isSuccessful: getDefaultIsSuccessful(actionTypeId),
    state: ACTION_STATE.NONE,
    ...getCurrentClock(),
  };
}

export function makePendingPass(data: {
  player: Player;
  coords: PitchCoords;
  fixtureId: string;
  teamId: string;
  userId: string;
  mode?: PassMode;
}): PassAction | CrossAction | LaunchAction | GoalkeeperThrowAction {
  const now = Date.now();
  const action = {
    messageId: null,
    parentActionId: null,
    period: null,
    teamPossessionPhaseId: null,
    actionId: uuid(),
    ...getCurrentClock(),
    createdAt: now,
    updatedAt: now,
    fixtureId: data.fixtureId,
    team: {
      id: data.teamId,
      name: '',
    },
    userId: data.userId,
    isRemoved: false,
    warnings: null,
    state: ACTION_STATE.NONE,
    player: data.player,
  };
  const actionMetadata = {
    period: {
      startTime: new Date(now).toISOString(),
      endTime: new Date(now).toISOString(),
    },
    position: { x: data.coords.x, y: data.coords.y },
    targetPosition: { x: data.coords.x, y: data.coords.y },
    targetPlayer: null,
    direction: null,
    distance: null,
    isAssist: false,
    actionContext: null,
    bodyPart: null,
  };

  if (data.mode === MODE.CROSS) {
    return {
      ...action,
      actionTypeId: ACTION_TYPE_ID.Cross,
      actionTypeMetadata: METADATA_KEY.cross,
      isSuccessful: getDefaultIsSuccessful(ACTION_TYPE_ID.Cross),
      metadata: {
        [METADATA_KEY.cross]: actionMetadata,
      },
    };
  }

  if (data.mode === MODE.LAUNCH) {
    return {
      ...action,
      actionTypeId: ACTION_TYPE_ID.Launch,
      actionTypeMetadata: METADATA_KEY.launch,
      isSuccessful: getDefaultIsSuccessful(ACTION_TYPE_ID.Launch),
      metadata: {
        [METADATA_KEY.launch]: actionMetadata,
      },
    };
  }

  if (data.mode === MODE.GOALKEEPER_THROW) {
    return {
      ...action,
      actionTypeId: ACTION_TYPE_ID.GoalkeeperThrow,
      actionTypeMetadata: METADATA_KEY.goalkeeperThrow,
      isSuccessful: getDefaultIsSuccessful(ACTION_TYPE_ID.GoalkeeperThrow),
      metadata: {
        [METADATA_KEY.goalkeeperThrow]: actionMetadata,
      },
    };
  }

  return {
    ...action,
    actionTypeId: ACTION_TYPE_ID.Pass,
    actionTypeMetadata: METADATA_KEY.pass,
    isSuccessful: getDefaultIsSuccessful(ACTION_TYPE_ID.Pass),
    metadata: {
      [METADATA_KEY.pass]: actionMetadata,
    },
  };
}

export type GetRelativeCoordsProps = {
  clientWidth: number;
  clientHeight: number;
  offsetX: number;
  offsetY: number;
  pitchArea: string | null;
};

export function createCoords({
  clientWidth,
  clientHeight,
  offsetX,
  offsetY,
  pitchArea,
}: GetRelativeCoordsProps): PitchCoords {
  const xPx = clamp(offsetX, 0, clientWidth);
  const yPx = clamp(offsetY, 0, clientHeight);
  const xDraw = clamp((xPx / clientWidth) * 100, 0, 100);
  const yDraw = clamp((yPx / clientHeight) * 100, 0, 100);
  const x = xDraw;
  const y = mirrorValueInRange(yDraw);

  return {
    xDraw: Number(xDraw.toFixed(2)),
    yDraw: Number(yDraw.toFixed(2)),
    x: Number(x.toFixed(2)),
    y: Number(y.toFixed(2)),
    pitchArea,
  };
}

export function createNestedCoords(
  e: MouseEvent,
  outerClientRect: DOMRect,
  innerClientRect: DOMRect,
) {
  const outerOffset = getPitchOffset(e, outerClientRect);
  const innerOffset = getPitchOffset(e, innerClientRect);
  const pitchArea =
    'id' in e.target && typeof e.target.id === 'string' ? e.target.id : '';

  const outerCoords = createCoords({
    clientHeight: outerOffset.pitchHeight,
    clientWidth: outerOffset.pitchWidth,
    offsetX: outerOffset.offsetX,
    offsetY: outerOffset.offsetY,
    pitchArea,
  });

  const innerCoords = createCoords({
    clientHeight: innerOffset.pitchHeight,
    clientWidth: innerOffset.pitchWidth,
    offsetX: innerOffset.offsetX,
    offsetY: innerOffset.offsetY,
    pitchArea,
  });

  return {
    ...outerCoords,
    x: innerCoords.x,
    y: innerCoords.y,
  };
}

export function getCornerCoords(coords: PitchCoords): PitchCoords {
  const cornerCoords = {
    ...coords,
  };

  if (coords.y >= 50) {
    cornerCoords.y = 100;
    cornerCoords.yDraw = PITCH_PADDING + PITCH_PADDING / 2;
  } else {
    cornerCoords.y = 0;
    cornerCoords.yDraw = 100 - PITCH_PADDING - PITCH_PADDING / 2;
  }

  if (coords.x === 0) {
    cornerCoords.xDraw = PITCH_PADDING;
  } else {
    cornerCoords.xDraw = 100 - PITCH_PADDING;
  }

  return cornerCoords;
}

export function getGoalKickCoords(coords: PitchCoords): PitchCoords {
  const goalKickCoords = {
    y: 50,
    yDraw: 50,
  };

  if (coords.x >= 50) {
    return {
      ...goalKickCoords,
      pitchArea: 'penalty-mark-right',
      x: 95,
      xDraw: 95 - PITCH_PADDING,
    };
  }

  return {
    ...goalKickCoords,
    pitchArea: 'penalty-mark-left',
    x: 5,
    xDraw: 5 + PITCH_PADDING,
  };
}

export function getPitchDrawCoords(coords: CoordinatesDto): PitchCoords {
  const HALF_OF_THE_PITCH = 50;
  const HORIZONTAL_RATIO = (100 - PITCH_PADDING * 2) / 100;
  const VERTICAL_RATIO = (100 - PITCH_PADDING * 3) / 100;

  const xDraw =
    (coords.x - HALF_OF_THE_PITCH) * HORIZONTAL_RATIO + HALF_OF_THE_PITCH;
  const yDraw =
    100 - ((coords.y - HALF_OF_THE_PITCH) * VERTICAL_RATIO + HALF_OF_THE_PITCH);

  return {
    ...coords,
    pitchArea: '',
    xDraw,
    yDraw,
  };
}

export function modifyActionMetadata<
  T extends Action,
  Metadata extends ActionMeta<T>,
>(action: T, metadata: Partial<Metadata>) {
  const key = action.actionTypeMetadata;
  if (action.actionTypeMetadata !== key) return action;
  if (!(key in action.metadata)) return action;

  const currentMetadata = getActionMetadata(action);
  const newAction: T = {
    ...action,
    metadata: {
      [key]: {
        ...currentMetadata,
        ...metadata,
      },
    },
  };
  return newAction;
}

export const isActionCommitted = (action: Action) => {
  return !!action.state && action.state !== ACTION_STATE.NONE;
};

export const setFreeKickCoords = (position: CoordinatesDto) => {
  const freeKickCoords = getPitchDrawCoords(position);

  setCoords(freeKickCoords);
};

export const setThrowInCoords = (position: CoordinatesDto) => {
  const coords = {
    ...position,
    pitchArea: '',
    xDraw: 0,
    yDraw: 0,
  };
  const throwInCoords = getPitchDrawCoords(coords);

  setCoords(throwInCoords);
};

export const setCornerCoords = (position: CoordinatesDto) => {
  const coords = {
    ...position,
    pitchArea: '',
    xDraw: 0,
    yDraw: 0,
  };
  const cornerCoords = getCornerCoords(coords);

  setCoords(cornerCoords);
};

export const setGoalKickCoords = (position: CoordinatesDto) => {
  const coords = {
    ...position,
    pitchArea: '',
    xDraw: 0,
    yDraw: 0,
  };
  const goalKickCoords = getGoalKickCoords(coords);

  setCoords(goalKickCoords);
};
