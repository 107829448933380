import { FixturePhase } from '@contract';

export enum DIRECTION_OF_PLAY {
  RIGHT_TO_LEFT = 0,
  LEFT_TO_RIGHT = 1,
}

export const DIRECTION_OF_PLAY_OPTIONS = {
  [DIRECTION_OF_PLAY.LEFT_TO_RIGHT]: 'Left to Right',
  [DIRECTION_OF_PLAY.RIGHT_TO_LEFT]: 'Right to Left',
};

export const KICK_OFF_LABEL = 'KICK OFF';
export const END_PERIOD_LABEL = 'END PERIOD';
export const MATCH_DETAILS_LABEL = 'MATCH DETAILS';
export const END_MATCH_LABEL = 'END MATCH';
export const SELECT_PERIOD_LABEL = 'Select Period';
export const DIRECTION_OF_PLAY_LABEL = 'Direction of Play';
export const TOGGLE_GAME_DETAILS_LABEL = 'Game details';

export const GAME_STATE: Record<FixturePhase, string> = {
  [FixturePhase.FirstHalf]: 'FIRST HALF',
  [FixturePhase.FirstHalfEt]: 'FIRST HALF ET',
  [FixturePhase.FullTime]: 'FULL TIME',
  [FixturePhase.HalfTime]: 'HALF TIME',
  [FixturePhase.KickOff]: 'KICK OFF',
  [FixturePhase.PenaltySht]: 'PENALTY SHT',
  [FixturePhase.PreGame]: 'PRE GAME',
  [FixturePhase.SecondHalf]: 'SECOND HALF',
  [FixturePhase.SecondHalfEt]: 'SECOND HALF ET',
  [FixturePhase.Unknown]: 'UNKNOWN',
  [FixturePhase.PostGame]: 'KICK OFF',
};
