import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { CoordinatesDto } from '@contract';
import { ClickableDots, DotProps } from '@/components/Pitch/ClickableDots';
import { GoalAction, OwnGoalAction, ShotAction } from '@/types/action/action';
import { GOAL_AREA, GOAL_RATIO } from '../constants';

interface GoalProps {
  ballPosition: CoordinatesDto | null;
  onTargetDisabled?: boolean;
  offTargetDisabled?: boolean;
  setPartialMetadata: (
    metadata:
      | Partial<ActionMeta<ShotAction>>
      | Partial<ActionMeta<GoalAction>>
      | Partial<ActionMeta<OwnGoalAction>>,
  ) => void;
}

export const Goal: FC<GoalProps> = ({
  ballPosition,
  onTargetDisabled = false,
  offTargetDisabled = false,
  setPartialMetadata,
}) => {
  const position = ballPosition || {
    x: 50,
    y: 50,
  };

  const setCoords = (dot: DotProps) => {
    if (!dot.positionId) return;
    setPartialMetadata({ ballPosition: dot.coords });
  };

  return (
    <Stack
      sx={{
        position: 'relative',
      }}
    >
      <Box
        id='off-target-area'
        sx={{
          height: '100px',
          backgroundColor: offTargetDisabled
            ? 'var(--disabledPitchColor)'
            : 'var(--pitchColor)',
        }}
      >
        {!offTargetDisabled && (
          <ClickableDots
            dots={[
              {
                positionId: GOAL_AREA.OFF_TARGET,
                coords: {
                  ...position,
                  xDraw: position.x,
                  yDraw: 100 - position.y,
                },
              },
            ]}
            setCoords={setCoords}
          />
        )}
      </Box>
      <Box
        id='on-target-area'
        sx={{
          backgroundColor: onTargetDisabled ? '#9f9f9f' : '#e5e5e5',
          position: 'absolute',
          isolation: 'isolate',
          height: GOAL_RATIO.VERTICAL,
          width: GOAL_RATIO.HORIZONTAL,
          bottom: 0,
          left: '50%',
          translate: '-50%',
        }}
      >
        {!onTargetDisabled && (
          <ClickableDots
            dots={[
              {
                positionId: GOAL_AREA.ON_TARGET,
                coords: {
                  ...position,
                  xDraw: position.x,
                  yDraw: 100 - position.y,
                },
              },
            ]}
            setCoords={setCoords}
          />
        )}
      </Box>
    </Stack>
  );
};
