import { Checkbox, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import { toggleWarning } from '@/components/ActionWarnings/helpers';
import { setAction } from '@/stores/ActionStore/ActionStore';
import { isFlagToQAWarning } from '@/utils/actions';
import type { Action } from '@/types/action/action';
import { FLAG_LABEL } from '@/constants';

type FlagFieldProps = {
  action: Action;
};

export const FlagField: FC<FlagFieldProps> = ({ action }) => {
  const toggleFlag = () => {
    if (!action) return;
    setAction({
      ...action,
      warnings: toggleWarning(action.warnings, ACTION_WARNING.FLAG_TO_QA),
    });
  };
  const isChecked = (action.warnings || []).some(isFlagToQAWarning);

  return (
    <FormControlLabel
      control={
        <Checkbox
          aria-labelledby={FLAG_LABEL}
          onChange={toggleFlag}
          checked={isChecked}
        />
      }
      label={FLAG_LABEL}
    />
  );
};
