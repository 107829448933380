import { ActionWarning } from '@contract';

const FLAG_TO_QA: ActionWarning = {
  type: 'flag',
  message: 'Flagged',
};
const PASS_SAME_PLAYER: ActionWarning = {
  type: 'passSamePlayer',
  message: 'Player cannot pass to himself',
};

export const ACTION_WARNING = {
  FLAG_TO_QA,
  PASS_SAME_PLAYER,
} as const;
