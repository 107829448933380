import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { useEffect } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { UserRole, type CollectionDto } from '@contract';
import { useGetCollection } from '@/service/fixturesCollection';
import { makeFakeCollectionInfo } from '@root/db/fakeCollectionInfo';
import { currentEnvironment } from '@/utils/environment';
import { ENVIRONMENT } from '@root/env/environment';
import { isDebugEnabled } from '@/utils/debug';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { reset as fixtureStoreReset, useFixtureStore } from './FixtureStore';
import {
  reset as lineupStoreReset,
  useLineupStore,
} from './LineupStore/LineupStore';
import { deriveDirectionOfPlay } from './utils';

export type CollectionStore = {
  collection: CollectionDto | undefined;
  directionOfPlay: DIRECTION_OF_PLAY;
};

export const useCollectionStore = create<CollectionStore>()(
  persist(
    (): CollectionStore => ({
      collection: undefined,
      directionOfPlay: DIRECTION_OF_PLAY.RIGHT_TO_LEFT,
    }),
    {
      name: 'evd-current-collection',
      storage: createJSONStorage(() => window.sessionStorage),
    },
  ),
);

export function setCurrentCollection(collection: CollectionDto | undefined) {
  useCollectionStore.setState({ collection });
  broadcastCollectionInfo(collection);
}

export function useCurrentCollection() {
  return useCollectionStore((state) => state.collection);
}

export function getCurrentCollection() {
  return useCollectionStore.getState().collection;
}

export function setDirectionOfPlay(directionOfPlay: DIRECTION_OF_PLAY) {
  useCollectionStore.setState({ directionOfPlay });
}

export const onIsHomeTeamAssignedChange = useCollectionStore.subscribe(
  (state, prevState) => {
    if (
      !state.collection ||
      state.collection.isHomeTeamAssigned ===
        prevState.collection?.isHomeTeamAssigned
    )
      return;

    const { currentPeriodState } = useFixtureStore.getState();

    if (!currentPeriodState) return;

    const { isHomeTeamAssigned } = state.collection;
    const { isHomeTeamLeft } = currentPeriodState;

    setDirectionOfPlay(
      deriveDirectionOfPlay(isHomeTeamLeft, isHomeTeamAssigned),
    );
  },
);

function broadcastCollectionInfo(collectionInfo: CollectionDto | undefined) {
  const collection = collectionInfo || getCurrentCollection();

  if (!collection) {
    fixtureStoreReset();
    lineupStoreReset();
    return;
  }

  const isHomeTeamCollector =
    collection.role === UserRole.Qa ? null : collection.isHomeTeamAssigned;

  useFixtureStore.setState({
    fixtureId: collection.fixture.id,
    collectionId: collection.id,
    role: collection.role,
    isHomeTeamCollector,
  });

  if (isHomeTeamCollector === null) {
    return;
  }

  const teamId = isHomeTeamCollector
    ? collection.fixture.homeTeamId
    : collection.fixture.awayTeamId;
  const teamName = isHomeTeamCollector
    ? collection.fixture.homeTeam
    : collection.fixture.awayTeam;

  useLineupStore.setState({
    teamId,
    teamName,
  });
}

export function useCombineCollectionInfo() {
  const collectionId = useFixtureStore((state) => state.collectionId);
  const collectionResult = useGetCollection(collectionId);
  const currentCollection = getCurrentCollection();

  useEffect(() => {
    if (collectionResult.data) {
      setCurrentCollection(collectionResult.data);
    }
  }, [collectionResult.data]);

  useEffect(() => {
    if (
      collectionId &&
      collectionResult.error &&
      !collectionResult.data &&
      (!currentCollection ||
        (currentCollection && currentCollection.id !== collectionId)) &&
      currentEnvironment !== ENVIRONMENT.PROD
    ) {
      const fakeCollectionInfo = makeFakeCollectionInfo(collectionId);
      isDebugEnabled() &&
        console.info(
          'Collection->Generating fake collection info',
          fakeCollectionInfo,
        );
      setCurrentCollection(fakeCollectionInfo);
    }
  }, [
    collectionId,
    collectionResult.data,
    collectionResult.error,
    currentCollection,
  ]);

  useEffectOnce(() => {
    broadcastCollectionInfo(currentCollection);
  });
}
