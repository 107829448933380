import { create } from 'zustand';
import type { createHtmlPortalNode } from 'react-reverse-portal';
import { ACTION_TYPE_ID } from '@contract';

export type UIStore = {
  currentTab: string;
  $streamPortalNode: ReturnType<typeof createHtmlPortalNode> | null;
  $streamContainer: HTMLDivElement | null;
  blockedPitchAction: ACTION_TYPE_ID | null;
  contextNestedMenu: string | null;
  pitchBlockedActionType: ACTION_TYPE_ID | null;
};

export const DEFAULT_STATE: UIStore = {
  currentTab: 'pitch',
  $streamPortalNode: null,
  $streamContainer: null,
  blockedPitchAction: null,
  contextNestedMenu: null,
  pitchBlockedActionType: null,
};

export const useUIStore = create<UIStore>()(() => ({ ...DEFAULT_STATE }));

export function setPitchBlockedActionType(
  pitchBlockedActionType: ACTION_TYPE_ID | null,
) {
  return useUIStore.setState({ pitchBlockedActionType });
}

export function setStreamContainer(
  $streamContainer: UIStore['$streamContainer'],
) {
  return useUIStore.setState({ $streamContainer });
}

export function setStreamPortalNode(
  $streamPortalNode: UIStore['$streamPortalNode'],
) {
  return useUIStore.setState({ $streamPortalNode });
}

export function setContextNestedMenu(contextNestedMenu: string | null) {
  return useUIStore.setState({ contextNestedMenu });
}
