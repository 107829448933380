import { addLeadingZero } from '@/utils/formatDate';
import { CollectionDto } from '@contract';

export const getFixturesCollectionsDateRange = (
  collections: CollectionDto[],
) => {
  const sortedCollectionsDates = collections
    .map(({ fixture }) => fixture.startDateUtc)
    .filter((date) => !isNaN(new Date(date).getTime()))
    .sort();

  const from = sortedCollectionsDates.at(0);
  const to = sortedCollectionsDates.at(-1);

  if (!from || !to) return;

  const fromDate = new Date(from);
  const toDate = new Date(to);
  const fromDay = addLeadingZero(fromDate.getDate());
  const toDay = addLeadingZero(toDate.getDate());
  const month = addLeadingZero(toDate.getMonth() + 1);
  const year = toDate.getFullYear();

  return `${fromDay}-${toDay}/${month}/${year}`;
};
