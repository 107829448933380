import { Stack } from '@mui/material';
import { FC, useRef } from 'react';
import { v1 as uuid } from 'uuid';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { Dialog } from '@/components/Dialog/Dialog';
import { CardType, CardReason } from '@contract';
import { CardAction } from '@/types/action/action';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { setPartialCardMetadata } from '@/stores/ActionStore/ActionStore';
import { CARD_OPTIONS, CARD_REASON_OPTION } from '@/service/cards';
import { postAction } from '@/service/helpers/postAction';
import { CARD_LABEL, PLAYER_CARDED_LABEL, REASON_LABEL } from '@/constants';
import { isActionCommitted } from '@/stores/ActionStore/utils';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { CARD_DIALOG_TITLE } from '../constants';
import { Controls } from '../Common/Controls';

interface CardDialogProps {
  action: CardAction;
  onClose: VoidFunction;
}

export const CardDialog: FC<CardDialogProps> = ({ action, onClose }) => {
  const defaultAction = useRef(action);
  const isEditMode = isActionCommitted(action);

  const onCardChange = (outcome: CardType) => {
    setPartialCardMetadata({
      reason: null,
      outcome,
    });
  };

  const onSubmit = () => {
    postAction(action);

    if (isEditMode) {
      onClose();
      return;
    }

    if (action.metadata.card.outcome === CardType.SecondYellow) {
      postAction({
        ...action,
        actionId: uuid(),
        metadata: {
          card: { outcome: CardType.Red, reason: CardReason.OtherReason },
        },
      });
    }

    onClose();
  };

  const onReasonChange = (reason: CardReason) => {
    setPartialCardMetadata({
      reason,
    });
  };

  return (
    <Dialog isOpen title={CARD_DIALOG_TITLE}>
      <Stack width='100%' alignItems='flex-end' gap={2}>
        <Stack gap={6} direction='row'>
          <Stack gap={2}>
            <RadioButtons
              name={CARD_LABEL}
              onChange={onCardChange}
              value={action.metadata.card.outcome}
              controlLabel={CARD_LABEL}
              fields={CARD_OPTIONS}
            />
            <ActionPlayerField
              action={action}
              playerAutoCompleteProps={{ label: PLAYER_CARDED_LABEL }}
            />
            <PeriodClockField action={action} />
            <Autocomplete
              options={CARD_REASON_OPTION[action.metadata.card.outcome]}
              value={action.metadata.card.reason}
              onChange={onReasonChange}
              label={REASON_LABEL}
            />
          </Stack>
        </Stack>
        <Controls
          onSubmit={onSubmit}
          onClose={onClose}
          disabled={
            action.metadata.card.reason === null ||
            JSON.stringify(action) === JSON.stringify(defaultAction.current)
          }
        />
      </Stack>
    </Dialog>
  );
};
