import { PlayerPositionDto, Position } from '@contract';
import type { Player } from '@/service/lineups';
import { sortPlayersByShirtNumber } from '@/utils/sortPlayersByShirtNumber';
import { useLineupStore } from './LineupStore';
import {
  FORMATION_MAP,
  type LineupPosition,
  type LineupStore,
  type PitchPositionMap,
  type PlayerPosition,
} from './constants';

export function getDefaultPitchPlayersMap() {
  return Object.values(Position).reduce<PitchPositionMap>((acc, position) => {
    if (typeof position === 'string') return acc;
    acc.set(position, null);
    return acc;
  }, new Map());
}

export function calculateFormation(pitchPlayers: PlayerPositionDto[]) {
  const formation = FORMATION_MAP.reduce<number[]>((acc, line) => {
    const lineValue = line.reduce<number>((lineAcc, linePosition) => {
      const exists = pitchPlayers.some(
        ({ position }) => position === linePosition,
      );
      if (exists) {
        lineAcc++;
      }
      return lineAcc;
    }, 0);

    if (lineValue) {
      acc.push(lineValue);
    }
    return acc;
  }, []);
  return formation.join('-');
}

export function findPlayerById(id: string) {
  if (!id) {
    return null;
  }
  const lineup = useLineupStore.getState().lineup;
  const playerPosition = lineup.find(({ player }) => player.id === id);
  if (!playerPosition) return null;

  return playerPosition.player;
}

export const PLAYERS_LIST_VARIANT = {
  ALL: 'all',
  IN_GAME: 'in-game',
  PITCH: 'pitch-players',
  BENCH: 'bench-players',
  OFF: 'off-players',
} as const;
export type PlayersListVariant = ConstType<typeof PLAYERS_LIST_VARIANT>;

export function getPlayersList(
  store?: LineupStore,
  listVariant?: PlayersListVariant,
): Player[] {
  const lineups = store || useLineupStore.getState();
  let result: Player[];
  switch (listVariant) {
    case PLAYERS_LIST_VARIANT.ALL:
      result = lineups.lineup.map(({ player }) => player);
      break;
    case PLAYERS_LIST_VARIANT.IN_GAME:
      result = [
        ...lineups.pitchPlayers.map(({ player }) => player),
        ...lineups.benchPlayers,
        ...lineups.offPlayers,
      ];
      break;
    case PLAYERS_LIST_VARIANT.BENCH:
      result = lineups.benchPlayers;
      break;
    case PLAYERS_LIST_VARIANT.OFF:
      result = lineups.offPlayers;
      break;
    case PLAYERS_LIST_VARIANT.PITCH:
    default:
      result = lineups.pitchPlayers.map(({ player }) => player);
      break;
  }
  return result.sort(sortPlayersByShirtNumber);
}

export function makePlayerPositions(
  pitchPositiomMap: PitchPositionMap,
): PlayerPositionDto[] {
  const entries = [...pitchPositiomMap.entries()];
  return entries.reduce<PlayerPositionDto[]>((acc, [position, player]) => {
    if (player === null) return acc;
    acc.push({ player, position });
    return acc;
  }, []);
}

export function playerPositionsToMap(
  playerPositions: PlayerPositionDto[],
): PitchPositionMap {
  const map = getDefaultPitchPlayersMap();
  return playerPositions.reduce<PitchPositionMap>((acc, entry) => {
    acc.set(entry.position, entry.player);
    return acc;
  }, map);
}

export function isPlayerPositionDto(
  playerPosition: PlayerPosition,
): playerPosition is PlayerPositionDto {
  return isPitchPosition(playerPosition.position);
}

export function isPitchPosition(
  position: LineupPosition,
): position is Position {
  return typeof position === 'number' && position in Position;
}
