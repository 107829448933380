import { MenuItem, ListItemText, ListItemIcon, Chip } from '@mui/material';
import type { FC } from 'react';
import DoNotTouch from '@mui/icons-material/DoNotTouch';
import PanTool from '@mui/icons-material/PanTool';
import { setContextAction } from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import { togglePassAssist } from '../actions';

export const TOGGLE_ASSIST_LABEL = 'Toggle assist';

export type ToggleAssistProps = {
  action: Parameters<typeof togglePassAssist>[0];
};

export const ToggleAssist: FC<ToggleAssistProps> = ({ action }) => {
  const isAssisted = getActionMetadata(action).isAssist;
  return (
    <MenuItem
      aria-label={TOGGLE_ASSIST_LABEL}
      onClick={() => {
        togglePassAssist(action);
        setContextAction(null);
      }}
    >
      <ListItemIcon>{isAssisted ? <DoNotTouch /> : <PanTool />}</ListItemIcon>
      <ListItemText disableTypography>{TOGGLE_ASSIST_LABEL}</ListItemText>
      <Chip
        size='small'
        sx={{ marginLeft: 1 }}
        label={isAssisted ? 'OFF' : 'ON'}
      />
    </MenuItem>
  );
};
