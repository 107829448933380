import useSWR from 'swr';
import { useAuthToken } from './hooks/authToken';
import { SERVICE_ENDPOINT } from './constants/endpoint';
import { fetchData } from './fetchData';

export type Player = {
  id: string;
  firstName: string;
  lastName: string;
  shirtNumber: string;
  fullName: string;
};

export type Formation = {
  positionId: number;
  id: string;
  positionAbbreviation: string;
  positionName: string;
};

export type Lineups = {
  fixtureId: string;
  teamId: string;
  startingLineup: Player[];
  substitutes: Player[];
  formation: Formation[];
};

export type LineupsResponse = {
  lineups: Lineups;
};
export function useGetLineups() {
  const token = useAuthToken();
  const output = useSWR<LineupsResponse>(
    SERVICE_ENDPOINT.Lineups.get,
    (url: string) => fetchData({ url, token }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );
  return output;
}
