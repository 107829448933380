import { Player } from '@/service/lineups';
import { ACTION_TYPE_ID, Extras } from '@contract';

export const FILTER_NAME = {
  PLAYERS: 'players',
  EVENTS: 'events',
  EXTRAS: 'extras',
  OUTCOME: 'outcome',
  FLAGS: 'flags',
  WARNINGS: 'warnings',
} as const;

export type FilterName = ConstType<typeof FILTER_NAME>;

export const DEFAULT_FILTER_VALUE: FilterValue = {
  [FILTER_NAME.PLAYERS]: [],
  [FILTER_NAME.EVENTS]: [],
  [FILTER_NAME.EXTRAS]: [],
  [FILTER_NAME.OUTCOME]: null,
  [FILTER_NAME.FLAGS]: null,
  [FILTER_NAME.WARNINGS]: null,
};

export type FilterValue = {
  [FILTER_NAME.PLAYERS]: Player[];
  [FILTER_NAME.EVENTS]: ACTION_TYPE_ID[];
  [FILTER_NAME.EXTRAS]: Extras[];
  [FILTER_NAME.OUTCOME]: boolean | null;
  [FILTER_NAME.FLAGS]: boolean | null;
  [FILTER_NAME.WARNINGS]: boolean | null;
};
