import { FC } from 'react';
import { setAction } from '@/stores/ActionStore/ActionStore';
import { OutcomeToggleButtonGroup } from '@/components/ActionOutcome/OutcomeToggleButtonGroup';
import type { Action } from '@/types/action/action';
import {
  REQUIRED_OUTCOME_OPTIONS,
  DEFAULT_OUTCOME_OPTIONS,
} from '@/components/ActionOutcome/constants';
import { ACTION_TYPE_ID } from '@contract';
import { useIsActionOutcomeEditable } from '@/utils/actions';

type OutcomeFieldProps = {
  action: Action;
};

function getActionOutcomeOptions(action: Action) {
  switch (action.actionTypeId) {
    case ACTION_TYPE_ID.FreeKickAwarded:
      return REQUIRED_OUTCOME_OPTIONS;
    default:
      return DEFAULT_OUTCOME_OPTIONS;
  }
}

export const OutcomeField: FC<OutcomeFieldProps> = ({ action }) => {
  const isEditable = useIsActionOutcomeEditable(action.actionTypeId);
  if (!isEditable) return;

  const onOutcomeChange = (e: unknown, isSuccessful: boolean | null) => {
    setAction({
      ...action,
      isSuccessful,
    });
  };
  return (
    <OutcomeToggleButtonGroup
      value={action.isSuccessful}
      options={getActionOutcomeOptions(action)}
      onChange={onOutcomeChange}
    />
  );
};
