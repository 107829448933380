import { Stack } from '@mui/material';
import { FC } from 'react';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { Dialog } from '@/components/Dialog/Dialog';
import { VARReason } from '@contract';
import { VARPendingAction } from '@/types/action/action';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import { postAction } from '@/service/helpers/postAction';
import { REASON_LABEL } from '@/constants';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { VAR_PENDING_DIALOG_TITLE } from '../constants';
import { Controls } from '../Common/Controls';
import { VAR_PENDING_REASON_OPTIONS } from './constants';

interface VARPendingDialogProps {
  action: VARPendingAction;
  onClose: VoidFunction;
}

const onReasonChange = (reason: VARReason) => {
  setActionPartialMetadata<VARPendingAction>({ reason });
};

export const VARPendingDialog: FC<VARPendingDialogProps> = ({
  action,
  onClose,
}) => {
  const onSubmit = () => {
    if (action.metadata.varPending.reason === null || !action.clockTime) return;

    postAction(action);
    onClose();
  };

  return (
    <Dialog isOpen title={VAR_PENDING_DIALOG_TITLE}>
      <Stack width='100%' alignItems='flex-end' gap={2}>
        <Stack gap={6} direction='row'>
          <Stack gap={2}>
            <Autocomplete
              options={VAR_PENDING_REASON_OPTIONS}
              value={action.metadata.varPending.reason || null}
              onChange={onReasonChange}
              label={REASON_LABEL}
            />
            <PeriodClockField action={action} />
          </Stack>
        </Stack>
        <Controls
          onSubmit={onSubmit}
          onClose={onClose}
          disabled={
            action.metadata.varPending.reason === null ||
            action.metadata.varPending.reason === undefined
          }
        />
      </Stack>
    </Dialog>
  );
};
