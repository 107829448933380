import { Box, Button, Stack, css, styled } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { FC } from 'react';
import PitchHorizontalSVG from '@/assets/football-pitch.svg?react';
import { useCollectionStore } from '@/stores/CollectionStore';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { DIRECTION_OF_PLAY } from '../Periods/constants';
import { LineupPlayer } from './Player';

export const PITCH_LINEUP_ID = 'pitch-lineup';

const PitchSVG = styled(PitchHorizontalSVG)(
  () => css`
    flex: 1;
  `,
);

const rightToLeftGrid = `
  '. . RW RM RWB RB .'
  'RCF . RAM RCM RDM RCB .'
  'ST SS CAM CM CDM CB GK'
  'LCF . LAM LCM LDM LCB .'
  '. . LW LM LWB LB .'`;

const leftToRightGrid = `
  '. LB LWB LM LW . .'
  '. LCB LDM LCM LAM . LCF'
  'GK CB CDM CM CAM SS ST'
  '. RCB RDM RCM RAM . RCF'
  '. RB RWB RM RW . .'
`;

const gridAreas = {
  [DIRECTION_OF_PLAY.RIGHT_TO_LEFT]: rightToLeftGrid,
  [DIRECTION_OF_PLAY.LEFT_TO_RIGHT]: leftToRightGrid,
};

const PlayersGrid = styled(Box)(
  ({ theme }) => css`
    position: absolute;
    inset: 0;
    display: grid;
    padding: ${theme.spacing(0.5)};
    gap: ${theme.spacing(0.5)};
  `,
);

export const Lineup: FC = () => {
  const directionOfPlay = useCollectionStore((state) => state.directionOfPlay);
  const pitchPlayers = useLineupStore((state) => state.pitchPlayers);

  return (
    <Stack flex='1' p={1} justifyContent='center'>
      <Button
        variant='text'
        startIcon={
          directionOfPlay === DIRECTION_OF_PLAY.LEFT_TO_RIGHT ? (
            <ArrowForward />
          ) : (
            <ArrowBack />
          )
        }
      >
        Direction of play
      </Button>
      <Stack position='relative' id={PITCH_LINEUP_ID}>
        <PitchSVG />
        <PlayersGrid
          sx={{
            gridTemplateAreas: gridAreas[directionOfPlay],
          }}
        >
          {pitchPlayers.map(({ position, player }) => (
            <LineupPlayer key={player.id} player={player} position={position} />
          ))}
        </PlayersGrid>
      </Stack>
    </Stack>
  );
};
