import { FC } from 'react';

import { type PropsWithAction } from './ActionContextMenuContent';
import { ChangePlayer } from './MenuItems/ChangePlayer';
import { ToggleFlag } from './MenuItems/ToggleFlag';
import { Delete } from './MenuItems/Delete';
import { Resend } from './MenuItems/Resend';
import { EditTime } from './MenuItems/EditTime';
import { ChangeOutcome } from './MenuItems/ChangeOutcome';
import { DisplayDetails } from './MenuItems/DisplayDetails';

export const CommonContextMenuItems: FC<PropsWithAction> = ({ action }) => {
  return (
    <>
      <Resend action={action} />
      <ChangePlayer action={action} />
      <ChangeOutcome action={action} />
      <ToggleFlag action={action} />
      <EditTime action={action} />
      <DisplayDetails action={action} />
      <Delete action={action} />
    </>
  );
};
