import {
  ActionContext,
  AttemptType,
  BodyPart,
  Extras,
  PatternOfPlay,
} from '@contract';

export const SHOT_DIALOG_TITLE = 'Attempt details';
export const GOAL_DIALOG_TITLE = 'Goal';
export const OWN_GOAL_DIALOG_TITLE = 'Own goal';
export const CARD_DIALOG_TITLE = 'Select Card';
export const VAR_PENDING_DIALOG_TITLE = 'VAR pending';
export const VAR_DECISION_DIALOG_TITLE = 'VAR outcome';
export const SUBSTITUTION_DIALOG_TITLE = 'Select Substitutions';
export const OFFSIDE_DIALOG_TITLE = 'Offside Against';
export const OFFSIDE_FOR_DIALOG_TITLE = 'Offside For';
export const FREE_KICK_DIALOG_TITLE = 'Free Kick Awarded';
export const TACKLE_DIALOG_TITLE = 'Tackle';
export const BAD_TOUCH_DIALOG_TITLE = 'Bad Touch';
export const PLAY_STOPPED_DIALOG_TITLE = 'Play stopped';
export const AERIAL_DIALOG_TITLE = 'Aerial';
export const GOALKEEPER_SAVE_DIALOG_TITLE = 'Goalkeeper Save';
export const GOALKEEPER_PENALTY_FACED_DIALOG_TITLE = 'Goalkeeper Penalty Faced';

export const GOAL_RATIO = {
  HORIZONTAL: '10.76%',
  VERTICAL: '50%',
};

export enum PITCH_POSITION {
  SHOT = 'shot',
  GOALKEEPER = 'goalkeeper',
  DEFENDER = 'defender',
  PASS_FROM = 'passForm',
  PASS_TO = 'passTo',
  ACTION_POSITION = 'actionPosition',
}

export enum GOAL_AREA {
  ON_TARGET = 'onTarget',
  OFF_TARGET = 'offTarget',
}

export const ATTEMPT_TYPE: Record<AttemptType, string> = {
  [AttemptType.TemptAttempt]: 'Temp. attempt',
  [AttemptType.OffTarget]: 'Off target',
  [AttemptType.OnTarget]: 'On target',
  [AttemptType.Blocked]: 'Blocked',
};

export const PATTERN_OF_PLAY: Record<PatternOfPlay, string> = {
  [PatternOfPlay.RegularPlay]: 'Regular play',
  [PatternOfPlay.Corner]: 'Corner',
  [PatternOfPlay.FreeKick]: 'Free kick',
  [PatternOfPlay.ThrowIn]: 'Throw in',
};

export const BODY_PART: Record<BodyPart, string> = {
  [BodyPart.LeftFoot]: 'Left foot',
  [BodyPart.RightFoot]: 'Right foot',
  [BodyPart.Head]: 'Head',
  [BodyPart.Other]: 'Other',
};

type ExtrasOptions = Exclude<Extras, Extras.Blocked>;

export const EXTRAS: Record<Exclude<Extras, Extras.Blocked>, string> = {
  [Extras.Assist]: 'Assist',
  [Extras.Woodwork]: 'Woodwork',
  [Extras.Deflection]: 'Deflection',
  [Extras.BigChance]: 'Big chance',
};

export const TAKEN_EXTRAS: Record<ActionContext, string> = {
  [ActionContext.CornerTaken]: 'Corner taken',
  [ActionContext.FreeKickTaken]: 'Free kick taken',
  [ActionContext.GoalKickTaken]: 'Goal kick taken',
};

export const attemptExtrasOptions = Object.keys(EXTRAS).map((k) => {
  const key = Number(k) as ExtrasOptions;
  return {
    value: key,
    label: EXTRAS[key],
  };
});

export const PITCH_PLAYERS_VALUES = {
  SHOT: { COLOR: 'red', LABEL: 'Position of shot' },
  GOALKEEPER: { COLOR: 'yellow', LABEL: 'Goalkeeper position' },
  DEFENDER: { COLOR: 'purple', LABEL: 'Defender position' },
};

const RTL_POSITION = {
  Y: 36.5,
  X: 8,
};

const LTR_POSITION = {
  Y: 36.5,
  X: 92,
};

export const DEFENDERS_VALUES = {
  MAX_COUNT: 10,
  MIN_COUNT: 0,
  GAP: 3,
  RTL_POSITION,
  LTR_POSITION,
};
