import { Player } from '@/service/lineups';
import { PlayerQuickInputMap, PLAYER_REF_SYMBOL } from './constants';

export function _buildTreeFromPlayer(player: Player, map: PlayerQuickInputMap) {
  const shirtDigits = player.shirtNumber.split('');
  shirtDigits.reduce((acc, digit, index, digits) => {
    acc[digit] = acc[digit] || {};
    if (index === digits.length - 1) {
      acc[digit][PLAYER_REF_SYMBOL] = player;
      return acc;
    }
    return acc[digit];
  }, map);
}

export function buildPlayerFromMap(
  getInputMap: () => PlayerQuickInputMap | null,
  callback: (number: string, player: Player | null) => void,
  wait = 500,
): (substring: string) => void {
  let timer: ReturnType<typeof setTimeout>;
  let value = '';
  let player: Player | null = null;
  let inputMap: PlayerQuickInputMap | null = getInputMap();

  return function (substring: string) {
    if (inputMap === null) {
      inputMap = getInputMap();
    }
    if (inputMap === null) return;

    if (!inputMap[substring]) {
      const mapTopLevel = getInputMap();
      if (!mapTopLevel || !mapTopLevel[substring]) {
        return;
      }
      value = '';
      player = null;
      inputMap = mapTopLevel;
    }

    clearTimeout(timer);

    value += substring;
    inputMap = inputMap[substring];

    player = inputMap[PLAYER_REF_SYMBOL] || null;
    callback(value, player);

    timer = setTimeout(() => {
      value = '';
      if (player === null) {
        callback(value, player);
      }
      player = null;
      inputMap = getInputMap();
    }, wait);
  };
}
export function makePlayerQuickInputMap(playersList: Player[]) {
  const inputMap: PlayerQuickInputMap = {};
  for (const player of playersList) {
    _buildTreeFromPlayer(player, inputMap);
  }
  return inputMap;
}
