import { FC, useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { FreeKickAwardedAction } from '@/types/action/action';
import { Dialog } from '@/components/Dialog/Dialog';
import { Controls } from '@/components/Dialogs/Common/Controls';
import { REASON_LABEL } from '@/constants';
import {
  setActionPartialMetadata,
  setCoords,
  setRecentlyAwardedAction,
} from '@/stores/ActionStore/ActionStore';
import { getActionMetadata } from '@/utils/actions';
import { postAction } from '@/service/helpers/postAction';
import { FREE_KICK_DIALOG_TITLE } from '@/components/Dialogs/constants';
import { RadioButtons } from '@/components/Form/RadioButtons';
import { Autocomplete } from '@/components/Form/Autocomplete';
import { ActionPlayerField } from '@/components/Dialogs/Common/ActionPlayerField';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { FlagField } from '@/components/Dialogs/Common/FlagField';
import { OutcomeField } from '@/components/Dialogs/Common/OutcomeField';
import { useCollectionStore } from '@/stores/CollectionStore';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { SimplePitch } from '../Common/SimplePitch';
import {
  FREE_KICK_REASON_OPTIONS,
  FREE_KICK_TYPE,
  FREE_KICK_TYPE_LABEL,
  FreeKickType,
  PENALTY_COORDS,
} from './constants';

interface FreeKickDialogProps {
  action: FreeKickAwardedAction;
  onClose: VoidFunction;
}

const setPartialFreeKickMetadata = (
  metadata: Partial<ActionMeta<FreeKickAwardedAction>>,
) => {
  setActionPartialMetadata<FreeKickAwardedAction>(metadata);
};

export const FreeKickDialog: FC<FreeKickDialogProps> = ({
  action,
  onClose,
}) => {
  const defaultAction = useRef(action);
  const metadata = getActionMetadata(action);
  const directionOfPlay = useCollectionStore((state) => state.directionOfPlay);

  const setIsPenalty = (value: number) => {
    setActionPartialMetadata<FreeKickAwardedAction>({
      isPenalty: value === FreeKickType.Penalty,
    });
  };

  const onSubmit = () => {
    if (action.isSuccessful) {
      if (metadata.isPenalty) {
        setCoords(
          directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
            ? PENALTY_COORDS.LEFT
            : PENALTY_COORDS.RIGHT,
        );
      }

      setRecentlyAwardedAction(action);
    }

    postAction(action);
    onClose();
  };

  return (
    <Dialog isOpen title={FREE_KICK_DIALOG_TITLE} maxWidth='xl' fullWidth>
      <Box width='100%'>
        <Stack direction='row' gap={2}>
          <SimplePitch action={action} />
          <Stack flex={1} gap={2}>
            <RadioButtons
              name='freeKickType'
              onChange={setIsPenalty}
              controlLabel={FREE_KICK_TYPE_LABEL}
              fields={FREE_KICK_TYPE}
              value={
                metadata.isPenalty
                  ? FreeKickType.Penalty
                  : FreeKickType.FreeKick
              }
            />
            <OutcomeField action={action} />
            <Autocomplete
              options={FREE_KICK_REASON_OPTIONS}
              value={metadata.reason}
              onChange={(reason) => setPartialFreeKickMetadata({ reason })}
              label={REASON_LABEL}
            />
            <ActionPlayerField action={action} />
            <PeriodClockField action={action} />
            <FlagField action={action} />
          </Stack>
        </Stack>
        <Controls
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={
            action &&
            !!action.messageId &&
            JSON.stringify(action) === JSON.stringify(defaultAction.current)
          }
        />
      </Box>
    </Dialog>
  );
};
