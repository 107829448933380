import { Divider, Paper, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import DirectionsRun from '@mui/icons-material/DirectionsRun';
import Bolt from '@mui/icons-material/Bolt';
import TimerOutlined from '@mui/icons-material/TimerOutlined';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { Action } from '@/types/action/action';
import { ACTION_TYPE } from '@/types/action/actionType';
import { PlayerName } from '@/components/PlayerName/PlayerName';
import { isPositiveOutcome } from '@/utils/actions';

export type ActionChipProps = {
  action: Action;
};
const ChipPart: FC<{ icon?: ReactNode; content?: ReactNode }> = ({
  icon,
  content,
}) => {
  return (
    <Stack direction='row' alignItems={'center'} gap={0.5} mr={0.5}>
      {icon}
      {content && (
        <Typography lineHeight={1} variant='body2'>
          {content}
        </Typography>
      )}
    </Stack>
  );
};
export const ActionChip: FC<ActionChipProps> = ({ action }) => {
  const outcome = isPositiveOutcome(action);

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        p: 1,
        width: 'fit-content',
        maxWidth: '100%',
        borderRadius: '999em',
      }}
    >
      <ChipPart icon={outcome ? <CheckCircleOutline /> : <CancelOutlined />} />
      <Divider orientation='vertical' flexItem />
      <ChipPart icon={<TimerOutlined />} content={action.clockTime} />
      <Divider orientation='vertical' flexItem />
      <ChipPart icon={<Bolt />} content={ACTION_TYPE[action.actionTypeId]} />
      {action.player && (
        <>
          <Divider orientation='vertical' flexItem />
          <ChipPart
            icon={<DirectionsRun />}
            content={<PlayerName player={action.player} />}
          />
        </>
      )}
    </Paper>
  );
};
