import { useMatches } from 'react-router-dom';
import { ROUTE_ID } from '@/router/constants';

export const VIEW_NAME = {
  [ROUTE_ID.QA]: 'QA',
  [ROUTE_ID.HOME_COLLECTOR]: 'Home collector',
  [ROUTE_ID.AWAY_COLLECTOR]: 'Away collector',
} as const;
export type ViewName = ConstType<typeof VIEW_NAME>;

/**
 * @returns current view limited to qa and collector with variant
 */
export const useCurrentView = (): string | null => {
  const matches = useMatches();
  if (!matches) return null;

  const match = matches.findLast((match) => {
    return match.id && match.id in VIEW_NAME;
  });

  if (!match) return null;

  const routeId = match.id as keyof typeof VIEW_NAME;
  return VIEW_NAME[routeId];
};
