import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  FilterValue,
  DEFAULT_FILTER_VALUE,
} from '@/components/EventHistory/FiltersDrawer/constants';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { sortByActionTimes } from '@/utils/sortByActionTimes';
import { makeFilters } from '@/utils/filters';
import { TableAction, useFixtureStore } from './FixtureStore';
import { mapToTableAction } from './tableActionMapper';

type FiltersStore = {
  tableActions: TableAction[];
  filterValues: FilterValue;
};

export const initialState: FiltersStore = {
  tableActions: [],
  filterValues: DEFAULT_FILTER_VALUE,
};

export const useEventHistoryStore = create<FiltersStore>()(
  devtools(() => ({ ...initialState }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'EventHistoryStore',
  }),
);

export const applyFilters = () => {
  const filterValues = useEventHistoryStore.getState().filterValues;

  const actions = useFixtureStore.getState().actions;
  const actionsAsArray = [...actions.values()].map((value) => value[0]);

  const filters = makeFilters(filterValues);
  const filteredActions = filters.reduce((acc, filterFn) => {
    return acc.filter(filterFn);
  }, actionsAsArray);

  const tableActions = filteredActions
    .filter(({ isRemoved }) => !isRemoved)
    .map(mapToTableAction)
    .sort(sortByActionTimes);

  useEventHistoryStore.setState({ tableActions });
};

export function setFiltersData(filtersData: FilterValue) {
  return useEventHistoryStore.setState({ filterValues: filtersData });
}
