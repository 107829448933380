import { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
} from '@mui/material';
import { PeriodDto } from '@contract';
import { useFixtureStore } from '@/stores/FixtureStore';
import { MINUTES_LABEL, PERIOD_LABEL, SECONDS_LABEL } from './constants';

interface TimeSelectProps {
  controlLabel: string;
  clockTime?: string | null;
  period: PeriodDto | null;
  onClockTimeChange: (time: string) => void;
  onPeriodChange: (period: PeriodDto | null) => void;
}

export const TimeSelect: FC<TimeSelectProps> = ({
  clockTime,
  controlLabel,
  onClockTimeChange,
  onPeriodChange,
  period,
}) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [periodOptions, setPeriodOptions] = useState<PeriodDto[]>([]);
  const periodDetails = useFixtureStore((state) => state.periodDetails);

  useEffect(() => {
    const [minutes, seconds] = (clockTime || '0:0').split(':').map(Number);

    setSeconds(seconds);
    setMinutes(minutes);
  }, [clockTime]);

  useEffect(() => {
    const periods = periodDetails?.map((period) => ({
      sequence: period.seq,
      label: period.label,
    }));

    if (!periods) return;

    setPeriodOptions(periods);
  }, [periodDetails]);

  const changeTime = (fieldValue: string) => {
    onClockTimeChange(fieldValue);
  };

  const handlePeriodChange = (
    value: PeriodDto | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!['clear', 'selectOption'].includes(reason)) return;

    const selectedPeriod = periodDetails?.find(
      (periodDetail) => periodDetail.seq === value?.sequence,
    );

    if (selectedPeriod) {
      onPeriodChange({
        sequence: selectedPeriod.seq,
        label: selectedPeriod.label,
      });
    }
  };

  return (
    <FormControl>
      <FormLabel sx={{ mb: 1 }}>{controlLabel}</FormLabel>
      <FormGroup row>
        <Autocomplete
          options={periodOptions}
          value={period}
          sx={{ mr: 2, minWidth: '200px' }}
          renderInput={(params) => (
            <TextField {...params} label={PERIOD_LABEL} />
          )}
          isOptionEqualToValue={(option, value) =>
            value && option.sequence === value.sequence
          }
          onChange={(e, period, reason) => handlePeriodChange(period, reason)}
        />
        <TextField
          sx={{ mr: 1, minWidth: '100px' }}
          label={MINUTES_LABEL}
          type='number'
          InputProps={{
            inputProps: { min: 0 },
          }}
          value={minutes}
          onChange={(e) => changeTime(`${e.target.value}:${seconds}`)}
        />
        <TextField
          label={SECONDS_LABEL}
          type='number'
          sx={{ minWidth: '100px' }}
          InputProps={{
            inputProps: { max: 59, min: 0 },
          }}
          value={seconds}
          onChange={(e) => changeTime(`${minutes}:${e.target.value}`)}
        />
      </FormGroup>
    </FormControl>
  );
};
