import { useRef, type FC, type MouseEvent, useMemo } from 'react';
import { Stack } from '@mui/material';
import { Dialog } from '@/components/Dialog/Dialog';
import type { Action } from '@/types/action/action';
import { postAction } from '@/service/helpers/postAction';
import { getActionWarnings } from '@/components/ActionWarnings/helpers';
import { ACTION_TYPE } from '@/types/action/actionType';
import { SimplePitch } from '../Common/SimplePitch';
import { PeriodClockField } from '../Common/PeriodClockField';
import { ActionPlayerField } from '../Common/ActionPlayerField';
import { Controls } from '../Common/Controls';
import { FlagField } from '../Common/FlagField';
import { OutcomeField } from '../Common/OutcomeField';
import { IsAssistActionField } from '../Common/IsAssistActionField';
import { IsHeadedField } from '../Common/IsHeadedField';

type GenericDialogProps = {
  action: Action;
  onClose: (e?: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
};

export const GenericEditDialog: FC<GenericDialogProps> = ({
  action,
  onClose,
}) => {
  const initialAction = useRef(action);
  const initialActionJSON = useMemo(
    () => JSON.stringify(initialAction.current),
    [initialAction],
  );
  const actionName =
    ACTION_TYPE[action.actionTypeId] || action.actionTypeMetadata;

  const onRightClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClose();
  };

  const onSubmit = () => {
    postAction({ ...action, warnings: getActionWarnings(action) });
    onClose();
  };

  const hasChanges: boolean =
    action !== initialAction.current &&
    JSON.stringify(action) !== initialActionJSON;

  return (
    <Dialog
      isOpen
      title={`Edit ${actionName} action | [id: ${action.actionId}]`}
      onRightClick={onRightClick}
      maxWidth={'xl'}
      fullWidth
    >
      <Stack direction='row' gap={2} width={'100%'}>
        <SimplePitch action={action} />
        <Stack gap={2}>
          <PeriodClockField action={action} />
          <ActionPlayerField action={action} />
          <OutcomeField action={action} />
          <Stack direction='row' flexWrap='wrap'>
            <FlagField action={action} />
            <IsAssistActionField action={action} />
            <IsHeadedField action={action} />
          </Stack>
        </Stack>
        <Controls
          stackProps={{ marginTop: 'auto' }}
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={!hasChanges}
        />
      </Stack>
    </Dialog>
  );
};
