import Timer from '@mui/icons-material/Timer';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import TimerOff from '@mui/icons-material/TimerOff';
import { useClockStore } from '@/stores/ClockStore';
import { ClockTime } from './ClockTime';
import { ClockControls } from './ClockControls';

export const CLOCK_BUTTON_ID = 'clock';

export const Clock: FC = () => {
  const isRunning = useClockStore((state) => state.isRunning);
  const [controlsVisible, setControlsVisible] = useState(true);
  const toggleControls = () => setControlsVisible((current) => !current);

  return (
    <>
      <Button
        id={CLOCK_BUTTON_ID}
        variant='outlined'
        color='inherit'
        startIcon={isRunning ? <Timer /> : <TimerOff />}
        sx={{ fontSize: '1.3rem' }}
        onClick={toggleControls}
      >
        <ClockTime />
      </Button>
      {controlsVisible && <ClockControls />}
    </>
  );
};
