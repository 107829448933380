import { useCollectionStore } from '@/stores/CollectionStore';
import { DIRECTION_OF_PLAY } from './constants';

export const getIsHomeTeamLeft = (directionOfPlay: DIRECTION_OF_PLAY) => {
  const { collection } = useCollectionStore.getState();

  if (!collection) return false;

  return (
    (collection.isHomeTeamAssigned &&
      directionOfPlay === DIRECTION_OF_PLAY.LEFT_TO_RIGHT) ||
    (!collection?.isHomeTeamAssigned &&
      directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT)
  );
};
