import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  ACTION_TYPE_ID,
  ActionContext,
  AttemptType,
  BodyPart,
  CardType,
  FreeKickAwardedReason,
  GoalkeeperPenaltyFacedOutcome,
  METADATA_KEY,
  PatternOfPlay,
  Position,
  GoalkeeperSaveAttemptType,
  Stance,
} from '@contract';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { Player } from '@/service/lineups';
import {
  Action,
  CardAction,
  CrossAction,
  FreeKickAwardedAction,
  GoalAction,
  GoalkeeperPenaltyFacedAction,
  GoalkeeperSaveAction,
  GoalkeeperThrowAction,
  LaunchAction,
  OwnGoalAction,
  PassAction,
  PlayStoppedAction,
  ShotAction,
  SubstitutionAction,
  VARDecisionAction,
  VARPendingAction,
} from '@/types/action/action';
import { getActionWarnings } from '@/components/ActionWarnings/helpers';
import { getActionMetadata } from '@/utils/actions';
import { postAction } from '@/service/helpers/postAction';
import { ACTION_WARNING } from '@/components/ActionWarnings/constants';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { findPlayerById } from '@/stores/LineupStore/utils';
import {
  getRelativePreviousAction,
  removeTableAction,
  updateTableAction,
  useFixtureStore,
} from '../FixtureStore';
import { useUserStore } from '../UserStore';
import {
  setContextNestedMenu,
  setPitchBlockedActionType,
  useUIStore,
} from '../UIStore';
import { PLAYER_INPUT_AUTO_COMMIT_TIME } from '../constants';
import { useLineupStore } from '../LineupStore/LineupStore';
import { useCollectionStore } from '../CollectionStore';
import { MODE, Mode, PassMode, PlayerQuickInputMap } from './constants';
import {
  createDefaultAction,
  makePendingPass,
  modifyActionMetadata,
} from './utils';
import { buildPlayerFromMap } from './playerInput';

export type PitchCoords = {
  /**
   * Pitch area is currently experimental - it stores
   * pitch element name defined in pitch svg.
   */
  pitchArea: string | null;
  /**
   * x and y are percentage values, used to produce data.
   * The got separated because business logic requires
   * flipping values in Y axis.
   */
  x: number;
  y: number;
  /**
   * Draw values are used as percentage values for drawing UI.
   */
  xDraw: number;
  yDraw: number;
};

export type ActionStore = {
  mode: Mode;
  coords: PitchCoords | null;
  passCoords: PitchCoords | null;
  playerNumber: string | null;
  player: Player | null;
  action: Action | null;
  dialogAction: Action | null;
  recentAwardedAction: Action | null;
  contextAction: Action | null;
  playerInputMap: PlayerQuickInputMap | null;
};

export const DEFAULT_STATE: ActionStore = {
  mode: MODE.PITCH,
  coords: null,
  passCoords: null,
  playerNumber: null,
  player: null,
  action: null,
  dialogAction: null,
  recentAwardedAction: null,
  contextAction: null,
  playerInputMap: null,
};

export const useActionStore = create<ActionStore>()(
  devtools(
    () => ({
      ...DEFAULT_STATE,
    }),
    {
      enabled: isDebugEnabled(),
      trace: isTraceStoreEnabled,
      name: 'ActionStore',
    },
  ),
);

export function reset() {
  const { pitchBlockedActionType } = useUIStore.getState();

  if (pitchBlockedActionType) {
    setPitchBlockedActionType(null);

    return;
  }

  return useActionStore.setState(
    (state) => {
      return { ...DEFAULT_STATE, playerInputMap: state.playerInputMap };
    },
    false,
    'reset',
  );
}

export function setRecentlyAwardedAction(recentAwardedAction: Action | null) {
  setPitchBlockedActionType(
    recentAwardedAction ? recentAwardedAction.actionTypeId : null,
  );

  return useActionStore.setState({ recentAwardedAction });
}

export function setPlayerInputMap(playerInputMap: PlayerQuickInputMap | null) {
  return useActionStore.setState({ playerInputMap });
}

export function getPlayerInputMap() {
  return useActionStore.getState().playerInputMap;
}

export function setCoords(coords: ActionStore['coords']) {
  const { pitchBlockedActionType } = useUIStore.getState();
  if (pitchBlockedActionType) return;

  return useActionStore.setState({ coords }, false, 'setCoords');
}

export function setPassCoords(passCoords: ActionStore['passCoords']) {
  return useActionStore.setState({ passCoords }, false, 'setPassCoords');
}

export function cancelPitch() {
  return useActionStore.setState(
    (state) => {
      if (state.playerNumber) {
        return {
          playerNumber: null,
          player: null,
        };
      }
      return { ...DEFAULT_STATE, action: state.action };
    },
    false,
    'cancelPitch',
  );
}

export function cancelPass() {
  return useActionStore.setState(
    (state) => {
      if (
        (state.action && state.action.actionTypeId === ACTION_TYPE_ID.Pass) ||
        (state.action && state.action.actionTypeId === ACTION_TYPE_ID.Cross) ||
        (state.action && state.action.actionTypeId === ACTION_TYPE_ID.Launch) ||
        (state.action &&
          state.action.actionTypeId === ACTION_TYPE_ID.GoalkeeperThrow)
      ) {
        removeTableAction(state.action.actionId);
      }
      return {
        mode: MODE.PITCH,
        passCoords: null,
        action: null,
      };
    },
    false,
    'cancelPass',
  );
}

export function updatePreviousPassIsAssist(action: ShotAction) {
  const previousAction = getRelativePreviousAction(action.actionId);
  if (
    !previousAction ||
    (previousAction.actionTypeId !== ACTION_TYPE_ID.Pass &&
      previousAction.actionTypeId !== ACTION_TYPE_ID.Cross &&
      previousAction.actionTypeId !== ACTION_TYPE_ID.Launch &&
      previousAction.actionTypeId !== ACTION_TYPE_ID.GoalkeeperThrow)
  ) {
    return Promise.resolve();
  }
  const shotAssist = action.metadata.shot.assistPlayer;
  if (
    shotAssist &&
    previousAction.metadata[previousAction.actionTypeMetadata]?.isAssist
  ) {
    return Promise.resolve();
  }
  if (
    !shotAssist &&
    !previousAction.metadata[previousAction.actionTypeMetadata]?.isAssist
  ) {
    return Promise.resolve();
  }

  const passUpdate:
    | PassAction
    | LaunchAction
    | CrossAction
    | GoalkeeperThrowAction = modifyActionMetadata(previousAction, {
    isAssist: !!shotAssist,
  });
  passUpdate.messageId = null;
  return postAction(passUpdate);
}

function _setPreviousPassRecipient(action: Action | null, recipient: Player) {
  if (!action || !action.isSuccessful) return;
  if (
    action.actionTypeId === ACTION_TYPE_ID.Pass ||
    action.actionTypeId === ACTION_TYPE_ID.Cross ||
    action.actionTypeId === ACTION_TYPE_ID.Launch ||
    action.actionTypeId === ACTION_TYPE_ID.GoalkeeperThrow
  ) {
    const updatedAction:
      | PassAction
      | CrossAction
      | LaunchAction
      | GoalkeeperThrowAction = modifyActionMetadata(action, {
      targetPlayer: { ...recipient },
    });
    updatedAction.isSuccessful = true;
    updatedAction.warnings = getActionWarnings(updatedAction);
    postAction(updatedAction);
  }
}

function _setPassMode(mode: PassMode) {
  return useActionStore.setState(
    (state) => {
      if (!state.playerNumber || !state.coords) {
        return state;
      }

      const newState = { ...state };

      const { collectionId } = useFixtureStore.getState();
      const { teamId } = useLineupStore.getState();
      if (!collectionId || !teamId) {
        return newState;
      }

      const { userId } = useUserStore.getState();
      if (!userId) {
        return newState;
      }

      if (!newState.player) {
        return newState;
      }

      const { action, recentAwardedAction } = state;
      const newPassAction = makePendingPass({
        mode,
        player: newState.player,
        coords: state.coords,
        fixtureId: collectionId,
        teamId,
        userId,
      });
      const newPassActionMetadata = getActionMetadata(newPassAction);

      if (
        recentAwardedAction &&
        (newPassAction.actionTypeId === ACTION_TYPE_ID.Pass ||
          newPassAction.actionTypeId === ACTION_TYPE_ID.Cross ||
          newPassAction.actionTypeId === ACTION_TYPE_ID.Launch)
      ) {
        if (recentAwardedAction.actionTypeId === ACTION_TYPE_ID.CornerAwarded) {
          newPassActionMetadata.actionContext = ActionContext.CornerTaken;
        }
        if (
          recentAwardedAction.actionTypeId === ACTION_TYPE_ID.GoalKickAwarded
        ) {
          newPassActionMetadata.actionContext = ActionContext.GoalKickTaken;
        }
        if (
          recentAwardedAction.actionTypeId === ACTION_TYPE_ID.FreeKickAwarded
        ) {
          newPassActionMetadata.actionContext = ActionContext.FreeKickTaken;
        }
      }

      updateTableAction(newPassAction);

      _setPreviousPassRecipient(action, newState.player);
      return {
        ...newState,
        mode: MODE.PASS,
        action: newPassAction,
      };
    },
    false,
    'setMode',
  );
}

export function _setPlayStoppedMode() {
  const { fixtureId, lineups } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!fixtureId || (lineups && !lineups.teamId) || !userId) return;

  return useActionStore.setState((state) => {
    const defaultPlayStoppedAction = createDefaultAction<PlayStoppedAction>(
      ACTION_TYPE_ID.PlayStopped,
      METADATA_KEY.playStopped,
    );

    if (!defaultPlayStoppedAction) return state;

    const action: PlayStoppedAction = {
      ...defaultPlayStoppedAction,
      metadata: {
        playStopped: {
          reason: null,
          isFromOppositeTeam: null,
        },
      },
    };

    return { mode: MODE.PLAY_STOPPED, action, dialogAction: action };
  });
}

function _setShotMode() {
  const { collectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }
    _setPreviousPassRecipient(state.action, state.player);

    if (!collectionId || !teamId || !userId) return state;

    const defaultShotAction = createDefaultAction<ShotAction>(
      ACTION_TYPE_ID.Shot,
      METADATA_KEY.shot,
    );

    if (!defaultShotAction) return state;

    const action: ShotAction = {
      ...defaultShotAction,
      metadata: {
        shot: {
          assistPlayer: null,
          ballPosition: null,
          blockersPositions: null,
          bodyPart: null,
          extras: null,
          patternOfPlay: null,
          attemptType: AttemptType.TemptAttempt,
          position: state.coords,
        },
      },
    };

    return { mode: MODE.SHOT, action, dialogAction: action };
  });
}

function _setGoalMode() {
  const { collectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();
  const { directionOfPlay } = useCollectionStore.getState();

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }
    _setPreviousPassRecipient(state.action, state.player);

    if (!collectionId || !teamId || !userId) return state;

    const defaultGoalAction = createDefaultAction<GoalAction>(
      ACTION_TYPE_ID.Goal,
      METADATA_KEY.goal,
    );

    if (!defaultGoalAction) return state;

    const action: GoalAction = {
      ...defaultGoalAction,
      metadata: {
        goal: {
          assistPlayer: null,
          bodyPart: BodyPart.RightFoot,
          extras: null,
          patternOfPlay: PatternOfPlay.RegularPlay,
          position: state.coords,
          ballPosition: { x: 50, y: 50 },
          blockersPositions: [
            directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
              ? { x: 15, y: 50 }
              : { x: 85, y: 50 },
          ],
        },
      },
    };

    return { mode: MODE.GOAL, action, dialogAction: action };
  });
}

function _setSubstitutionMode() {
  const { collectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  return useActionStore.setState((state) => {
    if (!collectionId || !teamId || !userId) {
      return state;
    }

    const defaultSubstitutionAction = createDefaultAction<SubstitutionAction>(
      ACTION_TYPE_ID.Substitution,
      METADATA_KEY.substitution,
    );

    if (!defaultSubstitutionAction) return state;

    const action: SubstitutionAction = {
      ...defaultSubstitutionAction,
      metadata: {
        substitution: {
          targetPlayer: null,
        },
      },
    };

    return { mode: MODE.SUBSTITUTION, action, dialogAction: action };
  });
}

function _setOwnGoalMode() {
  const { collectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();
  const { teamId } = useLineupStore.getState();

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }
    _setPreviousPassRecipient(state.action, state.player);

    if (!collectionId || !teamId || !userId) return state;

    const defaultOwnGoalAction = createDefaultAction<OwnGoalAction>(
      ACTION_TYPE_ID.OwnGoal,
      METADATA_KEY.ownGoal,
    );

    if (!defaultOwnGoalAction) return state;

    const action: OwnGoalAction = {
      ...defaultOwnGoalAction,
      metadata: {
        ownGoal: {
          ballPosition: null,
          bodyPart: BodyPart.RightFoot,
          patternOfPlay: PatternOfPlay.RegularPlay,
          position: state.coords,
        },
      },
    };

    return { mode: MODE.OWN_GOAL, action, dialogAction: action };
  });
}

function _setThrowInMode() {
  return useActionStore.setState(
    (state) => {
      if (!state.playerNumber || !state.coords || !state.player) {
        return state;
      }

      return { mode: MODE.THROW_IN };
    },
    false,
    'setThrowInMode',
  );
}

function _setCardMode() {
  const { collectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  return useActionStore.setState((state) => {
    if (!state.playerNumber || !state.coords || !state.player) {
      return state;
    }

    if (!collectionId || !teamId || !userId) return state;

    const defaultCardAction = createDefaultAction<CardAction>(
      ACTION_TYPE_ID.Card,
      METADATA_KEY.card,
    );

    if (!defaultCardAction) return state;

    const action: CardAction = {
      ...defaultCardAction,
      metadata: {
        card: {
          outcome: CardType.Yellow,
          reason: null,
        },
      },
    };

    return { mode: MODE.CARD, action, dialogAction: action };
  });
}

function _setVARPendingMode() {
  const { collectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  return useActionStore.setState((state) => {
    if (!collectionId || !teamId || !userId) return state;

    const defaultVARPendingAction = createDefaultAction<VARPendingAction>(
      ACTION_TYPE_ID.VARPending,
      METADATA_KEY.varPending,
    );

    if (!defaultVARPendingAction) return state;

    const action: VARPendingAction = {
      ...defaultVARPendingAction,
      metadata: {
        varPending: {
          reason: null,
        },
      },
    };

    return { mode: MODE.VAR_PENDING, action, dialogAction: action };
  });
}

function _setVARDecisionMode() {
  const { collectionId } = useFixtureStore.getState();
  const { teamId } = useLineupStore.getState();
  const { userId } = useUserStore.getState();

  return useActionStore.setState((state) => {
    if (!collectionId || !teamId || !userId) return state;

    const defaultVARDecisionAction = createDefaultAction<VARDecisionAction>(
      ACTION_TYPE_ID.VARDecision,
      METADATA_KEY.varDecision,
    );

    if (!defaultVARDecisionAction) return state;

    const action: VARDecisionAction = {
      ...defaultVARDecisionAction,
      metadata: {
        varDecision: {
          outcome: null,
        },
      },
    };

    return { mode: MODE.VAR_DECISION, action, dialogAction: action };
  });
}

function _setFreeKickMode() {
  const { teamId } = useLineupStore.getState();
  const { collectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!collectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.coords || !state.player) return state;

    const defaultFreeKickAction = createDefaultAction<FreeKickAwardedAction>(
      ACTION_TYPE_ID.FreeKickAwarded,
      METADATA_KEY.freeKickAwarded,
    );

    if (!defaultFreeKickAction) return state;

    const action: FreeKickAwardedAction = {
      ...defaultFreeKickAction,
      isSuccessful: true,
      metadata: {
        freeKickAwarded: {
          isPenalty: false,
          reason: FreeKickAwardedReason.Foul,
          position: state.coords,
        },
      },
    };

    return { mode: MODE.FREE_KICK, action, dialogAction: action };
  });
}

function _setGoalkeeperPenaltyFacedMode() {
  const { teamId, pitchPlayers } = useLineupStore.getState();
  const { collectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!collectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.coords || !state.player) return state;

    const playerPosition = pitchPlayers.find(
      ({ position }) => position === Position.Gk,
    );
    const goalkeeperPlayer = findPlayerById(
      playerPosition ? playerPosition.player.id : '',
    );

    const defaultGoalkeeperPenaltyFacedAction =
      createDefaultAction<GoalkeeperPenaltyFacedAction>(
        ACTION_TYPE_ID.GoalkeeperPenaltyFaced,
        METADATA_KEY.goalkeeperPenaltyFaced,
      );

    if (!defaultGoalkeeperPenaltyFacedAction) return state;

    const action: GoalkeeperPenaltyFacedAction = {
      ...defaultGoalkeeperPenaltyFacedAction,
      player: goalkeeperPlayer,
      metadata: {
        goalkeeperPenaltyFaced: {
          position: {
            x: state.coords.x,
            y: state.coords.y,
          },
          stance: Stance.DivedLeft,
          outcome: GoalkeeperPenaltyFacedOutcome.Scored,
        },
      },
    };

    return {
      mode: MODE.GOALKEEPER_PENALTY_FACED,
      action,
      dialogAction: action,
    };
  });
}

function _setGoalkeeperSaveMode() {
  const { teamId, pitchPlayers } = useLineupStore.getState();
  const { collectionId } = useFixtureStore.getState();
  const { userId } = useUserStore.getState();

  if (!collectionId || !teamId || !userId) return;

  return useActionStore.setState((state) => {
    if (!state.coords || !state.player) return state;

    const playerPosition = pitchPlayers.find(
      ({ position }) => position === Position.Gk,
    );
    const goalkeeperPlayer = findPlayerById(
      playerPosition ? playerPosition.player.id : '',
    );

    const defaultGoalkeeperSaveAction =
      createDefaultAction<GoalkeeperSaveAction>(
        ACTION_TYPE_ID.GoalkeeperSave,
        METADATA_KEY.goalkeeperSave,
      );

    if (!defaultGoalkeeperSaveAction) return state;

    const action: GoalkeeperSaveAction = {
      ...defaultGoalkeeperSaveAction,
      player: goalkeeperPlayer,
      warnings: [ACTION_WARNING.FLAG_TO_QA],
      metadata: {
        [METADATA_KEY.goalkeeperSave]: {
          attemptType: GoalkeeperSaveAttemptType.TemptSave,
          bodyPart: null,
          type: null,
          extras: null,
          position: state.coords,
        },
      },
    };

    return { mode: MODE.GOALKEEPER_SAVE, action, dialogAction: action };
  });
}

export function setMode(mode: ActionStore['mode']) {
  switch (mode) {
    case MODE.PASS:
    case MODE.CROSS:
    case MODE.LAUNCH:
    case MODE.GOALKEEPER_THROW:
      return _setPassMode(mode);
    case MODE.SHOT:
      return _setShotMode();
    case MODE.GOAL:
      return _setGoalMode();
    case MODE.OWN_GOAL:
      return _setOwnGoalMode();
    case MODE.THROW_IN:
      return _setThrowInMode();
    case MODE.CARD:
      return _setCardMode();
    case MODE.VAR_PENDING:
      return _setVARPendingMode();
    case MODE.VAR_DECISION:
      return _setVARDecisionMode();
    case MODE.SUBSTITUTION:
      return _setSubstitutionMode();
    case MODE.FREE_KICK:
      return _setFreeKickMode();
    case MODE.PLAY_STOPPED:
      return _setPlayStoppedMode();
    case MODE.GOALKEEPER_PENALTY_FACED:
      return _setGoalkeeperPenaltyFacedMode();
    case MODE.GOALKEEPER_SAVE:
      return _setGoalkeeperSaveMode();
    default:
      return useActionStore.setState({ mode, action: null }, false, 'setMode');
  }
}

export function tryToggleHeaded() {
  return useActionStore.setState(
    (state) => {
      if (!state.action || state.dialogAction) return state;
      const action = state.action;
      const actionType = action.actionTypeId;
      if (
        actionType !== ACTION_TYPE_ID.Pass &&
        actionType !== ACTION_TYPE_ID.Clearance &&
        actionType !== ACTION_TYPE_ID.Interception
      ) {
        return state;
      }
      const bodyPart = getActionMetadata(action).bodyPart;
      const newBodyPart = bodyPart === null ? BodyPart.Head : null;
      const newMeta = {
        bodyPart: newBodyPart,
      };
      const actionUpdate = modifyActionMetadata(action, newMeta);
      postAction(actionUpdate);
      return {
        action: actionUpdate,
      };
    },
    false,
    'tryToggleHeaded',
  );
}

export function setPlayerNumber(playerNumber: ActionStore['playerNumber']) {
  useActionStore.setState(
    (state) => {
      if (!state.coords) return { playerNumber: null };
      return { playerNumber };
    },
    false,
    'setPlayerNumber',
  );
}

export function setAction(action: Action | null) {
  return useActionStore.setState({ action });
}

export function setDialogAction(dialogAction: Action | null) {
  return useActionStore.setState({ dialogAction, action: dialogAction });
}

export function setContextAction(contextAction: Action | null) {
  setContextNestedMenu(null);
  return useActionStore.setState({ contextAction });
}

export function setActionPartialMetadata<
  T extends Action,
  Metadata = ActionMeta<T>,
>(metadata: Partial<Metadata>) {
  return useActionStore.setState((state) => {
    const { action } = state;
    if (!action) return state;
    return { action: modifyActionMetadata(action, metadata) };
  });
}

export function setPartialShotMetadata(
  metadata: Partial<ActionMeta<ShotAction>>,
) {
  setActionPartialMetadata<ShotAction>(metadata);
}

export function setPartialGoalMetadata(
  metadata: Partial<ActionMeta<GoalAction>>,
) {
  setActionPartialMetadata<GoalAction>(metadata);
}

export function setPartialOwnGoalMetadata(
  metadata: Partial<ActionMeta<OwnGoalAction>>,
) {
  setActionPartialMetadata<OwnGoalAction>(metadata);
}

export function setPartialPassMetadata(
  metadata: Partial<
    ActionMeta<PassAction | CrossAction | LaunchAction | GoalkeeperThrowAction>
  >,
) {
  setActionPartialMetadata<
    PassAction | CrossAction | LaunchAction | GoalkeeperThrowAction
  >(metadata);
}

export function setPartialCardMetadata(
  metadata: Partial<ActionMeta<CardAction>>,
) {
  setActionPartialMetadata<CardAction>(metadata);
}

export function setPartialSubstitutionMetadata(
  metadata: Partial<ActionMeta<SubstitutionAction>>,
) {
  setActionPartialMetadata<SubstitutionAction>(metadata);
}

export function setPlayer(player: ActionStore['player']) {
  return useActionStore.setState(
    (state) => {
      if (!state.coords) return { player: null };
      if (!player) return { player };
      return { player, playerNumber: player.shirtNumber };
    },
    false,
    'setPlayer',
  );
}

function _commitPlayerQuickInput(shirtNumber: string, player: Player | null) {
  setPlayerNumber(shirtNumber);
  setPlayer(player);
}

export const buildPlayerNumber = buildPlayerFromMap(
  getPlayerInputMap,
  _commitPlayerQuickInput,
  PLAYER_INPUT_AUTO_COMMIT_TIME,
);

export function resetToPitchMode() {
  return useActionStore.setState(
    {
      passCoords: null,
      mode: MODE.PITCH,
      action: null,
    },
    false,
    'resetToPitchMode',
  );
}
