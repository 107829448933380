import { FC } from 'react';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { CoordsLine } from './CoordsLine';
import { CoordsDot } from './CoordsDot';

export const PassCoordsTarget: FC = () => {
  const [mode, coords, passCoords] = useStoreWithEqualityFn(
    useActionStore,
    (state) => [state.mode, state.coords, state.passCoords],
    shallow,
  );

  if (!mode || !coords || !passCoords) return;

  return (
    <>
      <CoordsLine coordsStart={coords} coordsEnd={passCoords} />
      <CoordsDot coords={passCoords} />
    </>
  );
};
