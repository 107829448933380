import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Pitch } from '@/components/Pitch/Pitch';
import { HotkeyManager } from '@/components/HotkeyManager/HotkeyManager';
import { ActionsTable } from '@/components/ActionsTable/ActionsTable';
import { Lineup } from '@/components/Lineup/Lineup';
import { SelectedPlayersLabels } from '@/components/SelectedPlayerLabel/SelectedPlayerLabel';
import { ActionDialog } from '@/components/Dialogs/ActionDialog';
import { ActionButtons } from '@/components/ActionButtons/ActionButtons';
import { Clock } from '@/components/Clock/Clock';
import { Periods } from '@/components/Periods/Periods';
import { EndMatch } from '@/components/Periods/EndMatch';

export const PitchView: FC = () => {
  return (
    <HotkeyManager hotkeysVariant='collector'>
      <Stack height='100%' overflow='hidden' paddingTop={1}>
        {/* Main content */}
        <Stack direction='row' flex='1 0 0' overflow='hidden' gap={1}>
          {/* Left side */}
          <Stack sx={{ flex: '1 1 60%', overflowY: 'auto' }}>
            <Box display='flex' alignItems='center' gap={0.5} marginBottom={2}>
              <Periods />
              <Clock />
              <SelectedPlayersLabels />
              <Box marginLeft='auto'>
                <EndMatch />
              </Box>
            </Box>

            <Pitch />

            <Stack flex='1' minHeight={200} overflow='auto'>
              <ActionsTable />
            </Stack>
          </Stack>

          {/* Right side */}
          <Stack flex='1 1 40%' overflow='hidden'>
            <ActionButtons />
            <Lineup />
          </Stack>
        </Stack>
      </Stack>
      <ActionDialog />
    </HotkeyManager>
  );
};
