import { FC, useEffect, useState } from 'react';
import {
  Button,
  Stack,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useFixtureStore } from '@/stores/FixtureStore';
import { CANCEL_BUTTON_LABEL } from '@/constants';
import {
  ACTION_TYPE_ID,
  METADATA_KEY,
  type Config_PeriodDetailDto,
} from '@contract';
import { createDefaultAction } from '@/stores/ActionStore/utils';
import type {
  EndPeriodAction,
  StartFixtureAction,
  StartPeriodAction,
} from '@/types/action/action';
import { postAction } from '@/service/helpers/postAction';
import { pauseClock, setClockTicks, startClock } from '@/stores/ClockStore';
import { useCollectionStore } from '@/stores/CollectionStore';
import { RadioButtons } from '../Form/RadioButtons';
import { DIRECTION } from '../Form/constants';
import {
  DIRECTION_OF_PLAY_LABEL,
  DIRECTION_OF_PLAY_OPTIONS,
  END_PERIOD_LABEL,
  KICK_OFF_LABEL,
  MATCH_DETAILS_LABEL,
  SELECT_PERIOD_LABEL,
} from './constants';
import { getIsHomeTeamLeft } from './helpers';

type EditPeriodProps = {
  onClose: VoidFunction;
};

export const EditPeriod: FC<EditPeriodProps> = ({ onClose }) => {
  const periodDetails = useFixtureStore((state) => state.periodDetails);
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);
  const currentPeriodState = useFixtureStore(
    (state) => state.currentPeriodState,
  );
  const collectionState = useFixtureStore((state) => state.collectionState);
  const directionOfPlay = useCollectionStore((state) => state.directionOfPlay);
  const [selectedPeriod, setSelectedPeriod] =
    useState<Config_PeriodDetailDto | null>(() => {
      if (!currentPeriod || !periodDetails || !collectionState) return null;
      if (currentPeriodState && !currentPeriodState.endTime)
        return currentPeriod;

      return (
        periodDetails.find((period) => period.seq === currentPeriod.seq + 1) ||
        null
      );
    });
  const [selectedDirectionOfPlay, setSelectedDirectionOfPlay] =
    useState(directionOfPlay);
  const [isPeriodInProgress, setIsPeriodInProgress] = useState(false);

  useEffect(() => {
    if (!currentPeriod || !collectionState || !currentPeriodState) return;

    setIsPeriodInProgress(!currentPeriodState.endTime);
  }, [currentPeriod, collectionState, currentPeriodState]);

  const onKickOff = () => {
    if (!selectedPeriod) return;

    if (!currentPeriod) {
      const startFixtureAction = createDefaultAction<StartFixtureAction>(
        ACTION_TYPE_ID.StartFixture,
        METADATA_KEY.startFixture,
      );

      if (!startFixtureAction) return;

      postAction({
        ...startFixtureAction,
        metadata: {
          startFixture: {},
        },
      });
    }

    const startPeriodAction = createDefaultAction<StartPeriodAction>(
      ACTION_TYPE_ID.StartPeriod,
      METADATA_KEY.startPeriod,
    );

    if (!startPeriodAction) return;

    postAction({
      ...startPeriodAction,
      period: {
        sequence: selectedPeriod.seq,
        label: selectedPeriod.label,
      },
      metadata: {
        startPeriod: {
          isHomeTeamLeft: getIsHomeTeamLeft(selectedDirectionOfPlay),
        },
      },
    });

    setClockTicks(selectedPeriod.initialClock || 0);
    startClock();

    onClose();
  };

  const onEndPeriod = () => {
    if (!selectedPeriod) return;

    const endPeriodAction = createDefaultAction<EndPeriodAction>(
      ACTION_TYPE_ID.EndPeriod,
      METADATA_KEY.endPeriod,
    );

    if (!endPeriodAction) return;

    postAction({
      ...endPeriodAction,
      period: {
        sequence: selectedPeriod.seq,
        label: selectedPeriod.label,
      },
      metadata: {
        endPeriod: {},
      },
    });

    pauseClock();

    onClose();
  };

  return (
    <Stack direction='row' alignItems='top' p={2} gap={3}>
      <Stack gap={2} justifyContent='space-between' sx={{ minWidth: '200px' }}>
        <Typography variant='body1'>{MATCH_DETAILS_LABEL}</Typography>
        <Autocomplete
          autoHighlight
          disabled={isPeriodInProgress}
          disableClearable={true}
          value={selectedPeriod || undefined}
          options={periodDetails || []}
          renderInput={(params) => (
            <TextField {...params} label={SELECT_PERIOD_LABEL} />
          )}
          onChange={(_, period) => setSelectedPeriod(period)}
        />
      </Stack>
      <RadioButtons
        disabled={isPeriodInProgress}
        name='directionOfPlay'
        onChange={setSelectedDirectionOfPlay}
        value={selectedDirectionOfPlay}
        controlLabel={DIRECTION_OF_PLAY_LABEL}
        fields={DIRECTION_OF_PLAY_OPTIONS}
        direction={DIRECTION.COLUMN}
      />
      <Stack alignItems='end' direction='row' gap={1}>
        <Button variant='text' color='inherit' onClick={onClose}>
          {CANCEL_BUTTON_LABEL}
        </Button>
        {isPeriodInProgress ? (
          <Button variant='contained' color='primary' onClick={onEndPeriod}>
            {END_PERIOD_LABEL}
          </Button>
        ) : (
          <Button
            variant='contained'
            color='primary'
            onClick={onKickOff}
            disabled={!selectedPeriod}
          >
            {KICK_OFF_LABEL}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
