import { Box, Button } from '@mui/material';
import { buttons } from './constants';

export const ActionButtons = () => {
  return (
    <Box
      id='action-buttons'
      display='grid'
      overflow='auto'
      gridTemplateColumns='repeat(3, 1fr)'
      gridAutoRows='max-content'
      gap={1}
    >
      {buttons.map(({ label, onClick }) => (
        <Button
          key={label}
          variant='outlined'
          onClick={(e) => {
            e.currentTarget.blur();
            onClick && onClick();
          }}
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};
