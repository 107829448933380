import { Extras } from '@contract';

export const EXTRAS = {
  [Extras.Woodwork]: 'Woodwork',
  [Extras.Deflection]: 'Deflection',
  [Extras.BigChance]: 'Big chance',
};

type ExtrasOptions = Extras.Woodwork | Extras.Deflection | Extras.BigChance;

export const goalExtras = Object.keys(EXTRAS).map((k) => {
  const key = Number(k) as ExtrasOptions;
  return {
    value: key,
    label: EXTRAS[key],
  };
});
