import { MouseEvent } from 'react';
import { createCoords } from '@/stores/ActionStore/utils';
import { isDebugEnabled } from '@/utils/debug';
import { getPitchOffset } from './utils';

export const MOUSE_BUTTON = {
  LEFT: 'left',
  RIGHT: 'right',
};

export type MouseButton = ConstType<typeof MOUSE_BUTTON>;

export const logMouseClick = (
  button: MouseButton,
  e: MouseEvent,
  clientRect: DOMRect | undefined,
) => {
  if (!isDebugEnabled() || !clientRect) {
    return;
  }

  const { offsetX, offsetY, pitchHeight, pitchWidth } = getPitchOffset(
    e,
    clientRect,
  );

  const clickedCoords = createCoords({
    clientHeight: pitchHeight,
    clientWidth: pitchWidth,
    offsetX,
    offsetY,
    pitchArea: (e.target as SVGPathElement).id || null,
  });

  console.log(
    `Mouse ${button} click, coords: `,
    clickedCoords,
    'Time:',
    new Date(),
  );
};

export const logKeyDown = (e: KeyboardEvent) => {
  if (!isDebugEnabled()) {
    return;
  }

  console.log('Key:', e.code, 'Time:', new Date());
};
