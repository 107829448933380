import { FC } from 'react';
import { FormLabel, Stack } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { SubstitutionAction } from '@/types/action/action';
import { Dialog } from '@/components/Dialog/Dialog';
import { SUBSTITUTION_DIALOG_TITLE } from '@/components/Dialogs/constants';
import {
  setAction,
  setPartialSubstitutionMetadata,
  setPlayer,
  setPlayerNumber,
} from '@/stores/ActionStore/ActionStore';
import { PlayerAutocomplete } from '@/components/Form/PlayerAutocomplete';
import { Player } from '@/service/lineups';
import { postAction } from '@/service/helpers/postAction';
import { Controls } from '@/components/Dialogs/Common/Controls';
import { PLAYERS_LIST_VARIANT } from '@/stores/LineupStore/utils';
import { saveLineup } from '@/stores/SocketStore/SocketStore';
import {
  decomposeLineup,
  getSwappedPositionLineup,
  useLineupStore,
} from '@/stores/LineupStore/LineupStore';
import { useFixtureStore } from '@/stores/FixtureStore';
import { PeriodClockField } from '@/components/Dialogs/Common/PeriodClockField';
import { PLAYER_OFF_LABEL, PLAYER_ON_LABEL } from './constants';

interface SubstitutionDialogProps {
  action: SubstitutionAction;
  onClose: VoidFunction;
}

const subbedOnPlayerChange = (player: Player | null) => {
  setPartialSubstitutionMetadata({
    targetPlayer: player,
  });
};

export const SubstitutionDialog: FC<SubstitutionDialogProps> = ({
  action,
  onClose,
}) => {
  const teamId = useLineupStore((state) => state.teamId);
  const lineup = useLineupStore((state) => state.lineup);
  const collectionId = useFixtureStore((state) => state.collectionId);
  const isSubmitDisabled =
    action.player === null ||
    action.metadata.substitution.targetPlayer === null;

  const subbedOffPlayerChange = (player: Player | null) => {
    setAction({
      ...action,
      player,
    });
  };

  const onSubmit = () => {
    if (
      !teamId ||
      !collectionId ||
      !action.player ||
      !action.metadata.substitution.targetPlayer
    ) {
      return;
    }
    const playerOffId = action.player.id;
    const playerOff = lineup.find(({ player }) => player.id === playerOffId);

    const playerOnId = action.metadata.substitution.targetPlayer.id;
    const playerOn = lineup.find(({ player }) => player.id === playerOnId);

    if (!playerOff || !playerOn) return;

    const swappedLineup = getSwappedPositionLineup(
      lineup,
      [playerOn.position, playerOn.player],
      [playerOff.position, playerOff.player],
    );

    saveLineup({
      ...decomposeLineup(swappedLineup),
      teamId,
      fixtureId: collectionId,
    });

    postAction(action);
    setPlayerNumber(null);
    setPlayer(null);

    onClose();
  };

  return (
    <Dialog isOpen title={SUBSTITUTION_DIALOG_TITLE}>
      <Stack width='100%' p={1} gap={2}>
        <Stack direction='row'>
          <PlayArrowIcon sx={{ rotate: '180deg' }} color='error' />
          <FormLabel>{PLAYER_OFF_LABEL}</FormLabel>
        </Stack>
        <PlayerAutocomplete
          label={PLAYER_OFF_LABEL}
          player={action.player}
          onChange={subbedOffPlayerChange}
        />
        <Stack direction='row'>
          <PlayArrowIcon color='success' />
          <FormLabel>{PLAYER_ON_LABEL}</FormLabel>
        </Stack>
        <PlayerAutocomplete
          label={PLAYER_ON_LABEL}
          player={action.metadata.substitution.targetPlayer}
          onChange={subbedOnPlayerChange}
          playersListVariant={PLAYERS_LIST_VARIANT.BENCH}
        />
        <PeriodClockField action={action} />
        <Controls
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={isSubmitDisabled}
        />
      </Stack>
    </Dialog>
  );
};
