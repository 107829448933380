import { Container, Stack, Typography } from '@mui/material';

export const QAView = () => {
  return (
    <Stack height='100%'>
      <Container>
        <Typography textAlign='center' variant='h3'>
          You are QA
        </Typography>
      </Container>
    </Stack>
  );
};
