import { Button, Paper, Stack, Typography, colors } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FC } from 'react';
import { UserRole } from '@contract';
import { useCurrentCollection } from '@/stores/CollectionStore';

type Team = {
  id: string;
  name: string;
  score: number;
};

export type Teams = {
  homeTeam: Team;
  awayTeam: Team;
};

interface StyledButton {
  label: string;
  value: string;
  selected: string[];
  onClick: (value: string) => void;
  disabled?: boolean;
}

const COLORS = {
  fontsEnabled: colors.common.white,
  fontsDisabled: colors.grey[500],
  bgEnabled: colors.grey[800],
  bgDisabled: colors.grey[900],
  buttonIcon: colors.purple[600],
};

const StyledButton: FC<StyledButton> = ({
  label,
  value,
  selected,
  onClick,
  disabled,
}) => {
  const isSelected = selected.some((teamId) => teamId === value);
  return (
    <Button
      disabled={disabled}
      sx={{
        borderRadius: '10px',
        backgroundColor: isSelected ? COLORS.bgEnabled : COLORS.bgDisabled,
        color: isSelected ? COLORS.fontsEnabled : COLORS.fontsDisabled,
        '&:hover': {
          color: COLORS.fontsEnabled,
        },
        '&:disabled': {
          backgroundColor: !isSelected ? COLORS.bgDisabled : '',
        },
      }}
      disableElevation={!isSelected}
      variant='contained'
      startIcon={
        isSelected && (
          <FiberManualRecordIcon sx={{ color: COLORS.buttonIcon }} />
        )
      }
      onClick={() => onClick(value)}
      disableRipple
    >
      {label}
    </Button>
  );
};

interface ScoreView {
  teamSelected: string[];
  setTeamSelected: (value: string) => void;
}

export const ScoreView: FC<ScoreView> = ({ teamSelected, setTeamSelected }) => {
  const collection = useCurrentCollection();
  if (!collection) return <></>;

  const teams: Teams = {
    homeTeam: {
      id: collection.fixture.homeTeamId,
      name: collection.fixture.homeTeam,
      score: 7,
    },
    awayTeam: {
      id: collection.fixture.awayTeamId,
      name: collection.fixture.awayTeam,
      score: 0,
    },
  };
  const { homeTeam, awayTeam } = teams;

  const isQa = collection.role === UserRole.Qa;
  const isTeamASelected = teamSelected.some((teamId) => teamId === homeTeam.id);
  const isTeamBSelected = teamSelected.some((teamId) => teamId === awayTeam.id);

  return (
    <Paper
      component={Stack}
      elevation={0}
      square={false}
      gap={3}
      direction='row'
      sx={{
        backgroundColor: colors.grey[900],
        width: 'fit-content',
        p: '10px',
      }}
    >
      <StyledButton
        value={homeTeam.id}
        label={homeTeam.name}
        selected={teamSelected}
        onClick={setTeamSelected}
        disabled={!isQa}
      />
      <Stack gap={8} direction='row'>
        <Typography
          variant='h6'
          sx={{
            color: isTeamASelected ? COLORS.fontsEnabled : COLORS.fontsDisabled,
          }}
        >
          {homeTeam.score}
        </Typography>
        <Typography
          sx={{
            color: isTeamBSelected ? COLORS.fontsEnabled : COLORS.fontsDisabled,
          }}
          variant='h6'
        >
          {awayTeam.score}
        </Typography>
      </Stack>
      <StyledButton
        value={awayTeam.id}
        label={awayTeam.name}
        selected={teamSelected}
        onClick={setTeamSelected}
        disabled={!isQa}
      />
    </Paper>
  );
};
