import { FC, MouseEvent, useEffect, useMemo, useRef } from 'react';
import { Stack } from '@mui/material';
import { PlayerAutocomplete } from '@/components/Form/PlayerAutocomplete';
import {
  CrossAction,
  GoalkeeperThrowAction,
  LaunchAction,
  PassAction,
  type ThrowInTakenAction,
} from '@/types/action/action';
import { postAction } from '@/service/helpers/postAction';
import { Dialog } from '@/components/Dialog/Dialog';
import { FROM_PLAYER_LABEL, PASS_TO_LABEL } from '@/constants';
import { getActionWarnings } from '@/components/ActionWarnings/helpers';
import { getActionMetadata } from '@/utils/actions';
import { setPartialPassMetadata } from '@/stores/ActionStore/ActionStore';
import { Player } from '@/service/lineups';
import { Controls } from '../Common/Controls';
import { DestinationPitch } from '../Common/DestinationPitch';
import { PeriodClockField } from '../Common/PeriodClockField';
import { OutcomeField } from '../Common/OutcomeField';
import { FlagField } from '../Common/FlagField';
import { ActionPlayerField } from '../Common/ActionPlayerField';
import { IsAssistActionField } from '../Common/IsAssistActionField';
import { IsHeadedField } from '../Common/IsHeadedField';

interface PassDialogProps {
  action:
    | PassAction
    | CrossAction
    | LaunchAction
    | GoalkeeperThrowAction
    | ThrowInTakenAction;
  onClose: (e?: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
}

export const PassDialog: FC<PassDialogProps> = ({ action, onClose }) => {
  const initialAction = useRef<
    | PassAction
    | CrossAction
    | LaunchAction
    | GoalkeeperThrowAction
    | ThrowInTakenAction
    | null
  >(action);
  const initialActionJSON = useMemo(
    () => JSON.stringify(initialAction.current),
    [initialAction],
  );

  const metadata = getActionMetadata(action);
  useEffect(() => {
    if (!action.isSuccessful && typeof action.isSuccessful === 'boolean') {
      setPartialPassMetadata({ targetPlayer: null });
    }
  }, [action.isSuccessful]);

  const onSubmit = () => {
    postAction({ ...action, warnings: getActionWarnings(action) });
    onClose();
  };

  const onRightClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    onClose();
  };

  const onToPlayerChange = (player: Player | null) => {
    if (!player) return;

    setPartialPassMetadata({ targetPlayer: player });
  };

  if (!action) return;

  const hasChanges: boolean =
    action !== initialAction.current &&
    JSON.stringify(action) !== initialActionJSON;

  return (
    <Dialog
      isOpen
      title={`Edit ${action.actionTypeMetadata} action [id: ${action.actionId}]`}
      onRightClick={onRightClick}
      maxWidth={'xl'}
      fullWidth
    >
      <Stack direction='row' gap={2} width={'100%'}>
        <DestinationPitch action={action} />

        <Stack gap={2}>
          <PeriodClockField action={action} />
          <OutcomeField action={action} />
          <ActionPlayerField
            action={action}
            playerAutoCompleteProps={{ label: FROM_PLAYER_LABEL }}
          />
          {action.isSuccessful && (
            <PlayerAutocomplete
              player={metadata.targetPlayer}
              onChange={onToPlayerChange}
              label={PASS_TO_LABEL}
            />
          )}
          <Stack direction='row' flexWrap='wrap'>
            <FlagField action={action} />
            <IsAssistActionField action={action} />
            <IsHeadedField action={action} />
          </Stack>
          <Controls
            stackProps={{ marginTop: 'auto' }}
            onClose={onClose}
            onSubmit={onSubmit}
            disabled={!hasChanges}
          />
        </Stack>
      </Stack>
    </Dialog>
  );
};
