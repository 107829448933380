import { v4 as uuid } from 'uuid';
import { UserRole, type CollectionDto } from '@contract';

export function makeFakeCollectionInfo(collectionId: string): CollectionDto {
  return {
    id: collectionId,
    status: 0,
    myAcceptation: 'Pending',
    chatLink: '',
    isHomeTeamAssigned: true,
    role: UserRole.DataCollector,
    fixture: {
      id: uuid(),
      name: 'Inter Milan vs AC Milan',
      status: 0,
      startDateUtc: new Date('2023-12-21T21:00:00'),
      sportName: 'Soccer',
      competitionName: 'Serie A',
      competitionCategory: 'Category 1',
      competitionLocation: {
        city: '',
        country: 'Italy',
        continent: 'Europe',
      },
      venueName: 'San Siro',
      venueLocation: {
        city: '',
        country: 'Italy',
        continent: 'Europe',
      },
      homeTeamId: '98cfb91e-ccf0-4676-9a96-751c8419f63e',
      homeTeam: 'Inter Milan',
      awayTeamId: '80b5a467-63ef-4dbd-a103-3f7d36334afe',
      awayTeam: 'AC Milan',
    },
  };
}
