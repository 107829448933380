import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Action } from '@/types/action/action';
import { postAction } from '@/service/helpers/postAction';
import { PromiseQueue } from '@/utils/promiseQueue';
import { isExactAction } from './utils';
import { ACTION_STATE } from './constants';
import { FixtureStore } from './FixtureStore';

const LS_PREFIX = 'evd-actions-';

export const RESYNC_BATCH_SIZE = 10;

type UnsyncedActionsStore = {
  actions: Record<string, Action>;
  expiresAt: number;
};
const DEFAULT: Omit<UnsyncedActionsStore, 'expiresAt'> = {
  actions: {},
};

let unsyncedActionsStore: ReturnType<typeof _makeUnsyncedActionsStore> | null =
  null;

const TTL = 1000 * 60 * 60 * 48; // 48h

function _cleanOldBackups() {
  const now = Date.now();
  const actionsBackupKeys = Object.keys(localStorage).filter((key) =>
    key.startsWith(LS_PREFIX),
  );
  for (const k of actionsBackupKeys) {
    const value = localStorage.getItem(k);
    if (!value) continue;
    const state = JSON.parse(value).state as UnsyncedActionsStore;
    if (state.expiresAt < now) {
      localStorage.removeItem(k);
    }
  }
}

function _makeUnsyncedActionsStore(collectionId: string) {
  _cleanOldBackups();
  return create<UnsyncedActionsStore>()(_makePersistedStore(collectionId));
}

function _makePersistedStore(collectionId: string) {
  return persist(() => ({ ...DEFAULT, expiresAt: Date.now() + TTL }), {
    name: `${LS_PREFIX}${collectionId}`,
  });
}

export function useBackupActions(): UnsyncedActionsStore['actions'] {
  if (unsyncedActionsStore === null) {
    return {};
  }
  return unsyncedActionsStore((state) => state.actions);
}

export function subscribeUnsyncedActions(collectionId?: string) {
  if (!collectionId) return (unsyncedActionsStore = null);
  unsyncedActionsStore = _makeUnsyncedActionsStore(collectionId);
}

export function backupAction(action: Action) {
  if (unsyncedActionsStore === null) return;
  unsyncedActionsStore.setState((state) => {
    const existingAction = state.actions[action.actionId];
    if (existingAction && existingAction.updatedAt > action.updatedAt) {
      return state;
    }
    return {
      actions: { ...state.actions, [action.actionId]: action },
      expiresAt: action.updatedAt + TTL,
    };
  });
}

export function removeBackupAction(action: Action) {
  if (unsyncedActionsStore === null) return;
  unsyncedActionsStore.setState((state) => {
    const existingAction = state.actions[action.actionId];
    if (existingAction && existingAction.updatedAt > action.updatedAt) {
      return state;
    }
    const actions = { ...state.actions };
    delete actions[action.actionId];
    return { actions };
  });
}

export function cleanSyncedActions(actions: FixtureStore['actions']) {
  if (unsyncedActionsStore === null) return;
  unsyncedActionsStore.setState((state) => {
    const values = Object.values(state.actions);
    const filtered = values.filter((backup) => {
      const history = actions.get(backup.actionId);
      if (!history) return true;
      return !history.some((action) => action.updatedAt === backup.updatedAt);
    });

    const newActions = filtered.reduce<Record<string, Action>>(
      (acc, action) => {
        acc[action.actionId] = action;
        return acc;
      },
      {},
    );
    return { actions: newActions };
  });
}

export async function resyncBackupActions() {
  if (unsyncedActionsStore === null) return Promise.resolve();
  if (RESYNC_BATCH_SIZE < 1) {
    throw new Error('Resync batch size needs to be positive');
  }
  const actions = getBackedUpActions();
  const promiseQueue = new PromiseQueue<string | void>();

  const postActions = actions.map((action) =>
    promiseQueue.addPromise(() => postAction(action)),
  );

  return Promise.allSettled(postActions);
}

export async function resyncBackupAction(action: Action) {
  if (unsyncedActionsStore === null) return Promise.resolve();
  const actions = unsyncedActionsStore.getState().actions;
  const backupAction = actions[action.actionId];
  if (!backupAction || !isExactAction(backupAction, action)) {
    return Promise.resolve();
  }
  return postAction(action);
}

export function getBackedUpActions() {
  if (unsyncedActionsStore === null) return [];
  return Object.values(unsyncedActionsStore.getState().actions);
}

export function getBackedUpActionsMap() {
  if (unsyncedActionsStore === null) return {};
  return unsyncedActionsStore.getState().actions;
}

export function getErrorActions() {
  if (unsyncedActionsStore === null) return [];
  return getBackedUpActions().filter(
    (action) => action.state === ACTION_STATE.ERROR,
  );
}

export function actionHasBackup(action: Action) {
  return getBackedUpActions().some((a) => isExactAction(a, action));
}
