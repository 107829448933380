import { FC, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from '@mui/material';
import { Player } from '@/service/lineups';
import { makeTablePlayerName } from '@/stores/utils';
import {
  getPlayersList,
  type PlayersListVariant,
} from '@/stores/LineupStore/utils';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';

export interface PlayerAutocompleteProps {
  label: string;
  player: Player | null;
  onChange: (player: Player | null) => void;
  disableClearable?: boolean;
  disabled?: boolean;
  playersListVariant?: PlayersListVariant;
}

export const PlayerAutocomplete: FC<PlayerAutocompleteProps> = ({
  player,
  label,
  onChange,
  disableClearable = true,
  disabled = false,
  playersListVariant,
}) => {
  const initialPlayer = useRef(player);
  const lineups = useLineupStore();
  const [options, setOptions] = useState<Player[]>(
    getPlayersList(lineups, playersListVariant),
  );

  useEffect(() => {
    setOptions(getPlayersList(lineups, playersListVariant));
  }, [playersListVariant, lineups]);

  const onFieldChange = (
    player: Player | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (!['clear', 'selectOption'].includes(reason)) return;

    onChange(player);
  };

  if (!options.length) return;

  return (
    <Autocomplete
      sx={{ pt: 1 }}
      disableClearable={disableClearable}
      autoHighlight
      defaultValue={initialPlayer.current}
      options={options}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      getOptionLabel={makeTablePlayerName}
      renderInput={(params) => <TextField {...params} label={label} />}
      onChange={(e, player, reason) => onFieldChange(player, reason)}
    />
  );
};
