import { Stack, Divider, Typography, styled } from '@mui/material';
import { useLineupStore } from '@/stores/LineupStore/LineupStore';
import { Formation } from './Formation';
import { SquadLength } from './SquadLength';

const InfoContainer = styled(Stack)`
  align-items: center;
  justify-content: space-evenly;
`;

export const SquadInfo = () => {
  const teamName = useLineupStore((state) => state.teamName);
  return (
    <Stack
      direction='row'
      justifyContent={'space-evenly'}
      gap={1}
      divider={<Divider flexItem orientation='vertical' />}
    >
      <InfoContainer>
        <Typography variant='overline'>Team:</Typography>
        <Typography>{teamName || '???'}</Typography>
      </InfoContainer>

      <InfoContainer>
        <Typography variant='overline'>Left to assign</Typography>
        <SquadLength />
      </InfoContainer>

      <InfoContainer>
        <Typography variant='overline'>Formation</Typography>
        <Formation />
      </InfoContainer>
    </Stack>
  );
};
