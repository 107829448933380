import Add from '@mui/icons-material/Add';
import {
  IconButton,
  Popover,
  type IconButtonProps,
  TextField,
  Stack,
  Button,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { FC, useRef, useState, type ComponentProps, useCallback } from 'react';
import type { Player } from '@/service/lineups';
import { addAddedPlayer } from '@/stores/LineupStore/LineupStore';

const FORM_NAME = {
  FORM: 'Add player',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  SHIRT_NUMBER: 'shirtNumber',
} as const;
export const AddPlayerForm: FC<ComponentProps<'form'>> = (allProps) => {
  const { onSubmit, ...props } = allProps;

  const _onSubmit = useCallback<NonNullable<typeof onSubmit>>(
    (e) => {
      e.preventDefault();
      const data = new FormData(e.currentTarget);
      const firstName = data.get(FORM_NAME.FIRST_NAME) as string;
      const lastName = data.get(FORM_NAME.LAST_NAME) as string;
      const shirtNumber = data.get(FORM_NAME.SHIRT_NUMBER) as string;
      if (!firstName || !lastName || !shirtNumber) {
        return;
      }

      const player: Player = {
        id: uuid(),
        firstName,
        lastName,
        shirtNumber,
        fullName: `${firstName} ${lastName}`,
      };
      addAddedPlayer(player);
      onSubmit && onSubmit(e);
    },
    [onSubmit],
  );

  return (
    <form
      name={FORM_NAME.FORM}
      aria-label={FORM_NAME.FORM}
      {...props}
      onSubmit={_onSubmit}
    >
      <Stack p={2} gap={2}>
        <TextField
          required
          autoFocus
          label='First Name'
          name={FORM_NAME.FIRST_NAME}
        />
        <TextField required label='Last Name' name={FORM_NAME.LAST_NAME} />
        <TextField
          required
          label='Shirt number'
          type='number'
          name={FORM_NAME.SHIRT_NUMBER}
        />
        <Button type='submit'>Submit</Button>
      </Stack>
    </form>
  );
};

export type AddPlayerProps = IconButtonProps;
export const BUTTON_LABEL = 'Add player';
export const AddPlayer: FC<AddPlayerProps> = (props) => {
  const $addButton = useRef<HTMLButtonElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <IconButton
        aria-label={BUTTON_LABEL}
        title={BUTTON_LABEL}
        {...props}
        ref={$addButton}
        onClick={() => setIsActive((current) => !current)}
      >
        <Add />
      </IconButton>
      <Popover
        open={isActive}
        anchorEl={$addButton.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setIsActive(false)}
      >
        <AddPlayerForm onSubmit={() => setIsActive(false)} />
      </Popover>
    </>
  );
};
