import { Stack, colors } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import WarningIcon from '@mui/icons-material/Warning';
import FlagIcon from '@mui/icons-material/Flag';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StarIcon from '@mui/icons-material/Star';
import EventIcon from '@mui/icons-material/Event';
import { ACTION_TYPE_ID, Extras } from '@contract';
import { makeTablePlayerName } from '@/stores/utils';
import {
  setFiltersData,
  useEventHistoryStore,
} from '@/stores/EventHistoryStore';

import { FilterChip } from './FiltersDrawer/FilterChip';
import { FILTER_NAME } from './FiltersDrawer/constants';

export const LABELS = {
  FLAG: {
    TRUE: 'Flagged events',
    FALSE: 'Unflagged events',
  },
  WARNING: {
    TRUE: 'Events with warnings',
    FALSE: 'Events without warnings',
  },
  OUTCOME: 'Outcome',
} as const;

const ICONS_COLORS = {
  players: '',
  events: colors.orange[500],
  extras: colors.blue[500],
  outcome: {
    successful: 'green',
    failed: 'red',
  },
  flags: colors.red[900],
  warnings: colors.orange[500],
};

export const SelectedFilterChips = () => {
  const selectedValues = useEventHistoryStore((state) => state.filterValues);
  const { players, events, extras, outcome, flags, warnings } = selectedValues;

  return (
    <Stack id='filters-view' gap={1} direction='row' flexWrap='wrap'>
      {players.map((player) => (
        <FilterChip
          onDelete={() => {
            setFiltersData({
              ...selectedValues,
              [FILTER_NAME.PLAYERS]: players.filter((p) => p.id !== player.id),
            });
          }}
          key={player.id}
          icon={<PersonIcon sx={{ '&&': { color: ICONS_COLORS.players } }} />}
          label={makeTablePlayerName(player)}
        />
      ))}
      {events.map((event) => (
        <FilterChip
          tooltipMessage='Event'
          icon={<EventIcon sx={{ '&&': { color: ICONS_COLORS.events } }} />}
          onDelete={() => {
            setFiltersData({
              ...selectedValues,
              [FILTER_NAME.EVENTS]: events.filter((e) => e !== event),
            });
          }}
          key={event}
          label={ACTION_TYPE_ID[event]}
        />
      ))}
      {extras.map((extra) => (
        <FilterChip
          tooltipMessage='Extra'
          icon={<StarIcon sx={{ '&&': { color: ICONS_COLORS.extras } }} />}
          onDelete={() => {
            setFiltersData({
              ...selectedValues,
              [FILTER_NAME.EXTRAS]: extras.filter((e) => e !== extra),
            });
          }}
          key={extra}
          label={Extras[extra]}
        />
      ))}

      {outcome !== null && (
        <FilterChip
          icon={
            outcome ? (
              <CheckCircleOutlineIcon
                sx={{ '&&': { color: ICONS_COLORS.outcome.successful } }}
              />
            ) : (
              <HighlightOffIcon
                sx={{ '&&': { color: ICONS_COLORS.outcome.failed } }}
              />
            )
          }
          onDelete={() => {
            setFiltersData({
              ...selectedValues,
              [FILTER_NAME.OUTCOME]: null,
            });
          }}
          label={LABELS.OUTCOME}
        />
      )}

      {flags !== null && (
        <FilterChip
          icon={<FlagIcon sx={{ '&&': { color: ICONS_COLORS.flags } }} />}
          onDelete={() => {
            setFiltersData({
              ...selectedValues,
              [FILTER_NAME.FLAGS]: null,
            });
          }}
          label={flags ? LABELS.FLAG.TRUE : LABELS.FLAG.FALSE}
        />
      )}

      {warnings !== null && (
        <FilterChip
          icon={<WarningIcon sx={{ '&&': { color: ICONS_COLORS.warnings } }} />}
          onDelete={() => {
            setFiltersData({
              ...selectedValues,
              [FILTER_NAME.WARNINGS]: null,
            });
          }}
          label={warnings ? LABELS.WARNING.TRUE : LABELS.WARNING.FALSE}
        />
      )}
    </Stack>
  );
};
