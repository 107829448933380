import {
  FC,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { PitchCoords } from '@/stores/ActionStore/ActionStore';
import { createCoords } from '@/stores/ActionStore/utils';
import { CoordsDot } from './CoordsDot';

export const POSITION_ATTRIBUTE = 'data-position-id';

export type DotProps = {
  positionId: string;
  coords: Omit<PitchCoords, 'pitchArea'>;
  color?: string;
};

interface ClickableDotsProps {
  dots: DotProps[];
  setCoords: (coords: DotProps) => void;
}

export const ClickableDots: FC<PropsWithChildren<ClickableDotsProps>> = ({
  dots,
  setCoords,
  children,
}) => {
  const [activeDot, setActiveDot] = useState<string | null>(null);
  const $clickableContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dots && dots.length === 1) {
      setActiveDot(dots[0].positionId);
      return;
    }

    setActiveDot(null);
  }, [dots]);

  const onDropClick = (e: MouseEvent<HTMLDivElement | SVGSVGElement>) => {
    const positionId = (e.target as HTMLElement).getAttribute(
      POSITION_ATTRIBUTE,
    );

    if (positionId && !activeDot) {
      setActiveDot(positionId);
      return;
    }

    if (!$clickableContainer.current) return;

    const clickedCoords = createCoords({
      clientHeight: $clickableContainer.current.scrollHeight,
      clientWidth: $clickableContainer.current.clientWidth,
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
      pitchArea: (e.target as HTMLDivElement).id || '',
    });

    if (activeDot) {
      setCoords({ positionId: activeDot, coords: clickedCoords });
    }

    if (dots && dots.length !== 1) {
      setActiveDot(null);
    }
  };

  const renderDot = (dot: DotProps) => {
    return (
      <CoordsDot
        key={dot.positionId}
        coords={dot.coords}
        positionId={dot.positionId}
        color={dot.color || ''}
        sx={{
          border: dot.positionId === activeDot ? '2px solid black' : 'unset',
          borderRadius: '50%',
          pointerEvents: activeDot ? 'none' : 'unset',
        }}
      />
    );
  };

  if (!dots) return;

  return (
    <Box
      id='clickable-dots'
      ref={$clickableContainer}
      onClick={onDropClick}
      sx={
        children
          ? {}
          : {
              inset: 0,
              position: 'absolute',
            }
      }
    >
      {children}
      {dots.map(renderDot)}
    </Box>
  );
};
