import { FC, MutableRefObject } from 'react';
import { Stack, Typography } from '@mui/material';
import { ViewportList, ViewportListRef } from 'react-viewport-list';
import { TableAction } from '@/stores/FixtureStore';
import { useActionsTable } from '@/components/ActionsTable/useActionsTable';
import { EventsTableRow } from './EventsTableRow';
import { NO_EVENTS_LABEL } from './constants';

interface EventsTableBodyProps {
  viewportRef: MutableRefObject<HTMLDivElement | null>;
  listRef: MutableRefObject<ViewportListRef | null>;
  actions: TableAction[];
}

export const EventsTableBody: FC<EventsTableBodyProps> = ({
  viewportRef,
  listRef,
  actions,
}) => {
  const items = actions;

  const { contextMenu, onContextMenuClick, onTableDoubleClick } =
    useActionsTable();

  if (!items.length) {
    return (
      <Typography variant='body2' sx={{ textAlign: 'center', padding: '8px' }}>
        {NO_EVENTS_LABEL}
      </Typography>
    );
  }

  return (
    <Stack
      onContextMenu={onContextMenuClick}
      onDoubleClick={onTableDoubleClick}
      flex={1}
    >
      <ViewportList viewportRef={viewportRef} ref={listRef} items={items}>
        {(action) => (
          <EventsTableRow
            key={action.messageId || action.createdAt}
            action={action}
          />
        )}
      </ViewportList>
      {contextMenu}
    </Stack>
  );
};
