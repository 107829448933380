import { Box } from '@mui/material';
import { FC } from 'react';
import { ClickableDots, type DotProps } from '@/components/Pitch/ClickableDots';
import { getActionMetadata } from '@/utils/actions';
import PitchHorizontalSVG from '@/assets/football-pitch.svg?react';
import { setActionPartialMetadata } from '@/stores/ActionStore/ActionStore';
import type { Action } from '@/types/action/action';
import { PITCH_POSITION } from '../constants';

type SimplePitchProps = {
  action: Action;
};

export const SimplePitch: FC<SimplePitchProps> = ({ action }) => {
  const metadata = getActionMetadata(action);
  const hasPosition = 'position' in metadata && !!metadata.position;
  if (!hasPosition) return;

  const setCoords = (dot: DotProps) => {
    if (!dot.positionId || dot.positionId !== PITCH_POSITION.ACTION_POSITION) {
      return;
    }

    setActionPartialMetadata({ position: dot.coords });
  };

  return (
    <Box
      sx={{
        position: 'relative',
        isolation: 'isolate',
        flex: '1 0 auto',
      }}
    >
      <ClickableDots
        dots={[
          {
            positionId: PITCH_POSITION.ACTION_POSITION,
            coords: {
              ...metadata.position,
              xDraw: metadata.position.x,
              yDraw: 100 - metadata.position.y,
            },
          },
        ]}
        setCoords={setCoords}
      >
        <PitchHorizontalSVG />
      </ClickableDots>
    </Box>
  );
};
