import { FC } from 'react';
import { useActionStore } from '@/stores/ActionStore/ActionStore';
import { CoordsDot } from './CoordsDot';

export const CoordsTarget: FC = () => {
  const coords = useActionStore((state) => state.coords);
  const playerNumber = useActionStore((state) => state.playerNumber);
  const selectedPlayer = useActionStore((state) => state.player);

  if (!coords) return;

  return (
    <CoordsDot
      coords={coords}
      playerNumber={playerNumber || selectedPlayer?.shirtNumber || ''}
    />
  );
};
