import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Player } from '@/service/lineups';
import { isDebugEnabled, isTraceStoreEnabled } from '@/utils/debug';
import { Position, type SaveLineupDto } from '@contract';
import { sortPlayersByShirtNumber } from '@/utils/sortPlayersByShirtNumber';
import { setPlayerInputMap } from '@/stores/ActionStore/ActionStore';
import { makePlayerQuickInputMap } from '@/stores/ActionStore/playerInput';
import { useFixtureStore } from '../FixtureStore';
import {
  saveLineup,
  subscribeLineups,
  unsubscribeLineups,
} from '../SocketStore/SocketStore';
import {
  LineupStore,
  PITCH_PLAYERS_MAX_LEN,
  LineupPositionEntry,
  LINEUP_NAMED_POSITION,
  type PlayerPosition,
} from './constants';
import {
  calculateFormation,
  isPlayerPositionDto,
  playerPositionsToMap,
} from './utils';

function getStoreDefault(): LineupStore {
  return {
    teamId: undefined,
    teamName: undefined,
    pitchPlayersMap: new Map(),
    squads: [],
    addedPlayers: [],
    outPlayers: [],
    benchPlayers: [],
    offPlayers: [],
    pitchPlayers: [],
    lineup: [],
    selectedEntry: null,
    pitchPlayersLenght: 0,
    formation: '',
    isGKSelected: false,
  };
}

export const useLineupStore = create<LineupStore>()(
  devtools(() => ({ ...getStoreDefault() }), {
    enabled: isDebugEnabled(),
    trace: isTraceStoreEnabled,
    name: 'LineupStore',
  }),
);

export const onTeamIdChange = useLineupStore.subscribe((state, prevState) => {
  const teamId = state.teamId;
  const currentTeamId = prevState.teamId;
  if (teamId === currentTeamId) return;
  const currentCollectionId = useFixtureStore.getState().collectionId;
  if (currentTeamId && currentCollectionId) {
    unsubscribeLineups({
      fixtureId: currentCollectionId,
      teamId: currentTeamId,
    });
  }
  if (teamId && currentCollectionId) {
    subscribeLineups({ fixtureId: currentCollectionId, teamId });
  }
});

export function reset() {
  useLineupStore.setState({ ...getStoreDefault() });
}
export function setTeamId(teamId: LineupStore['teamId']) {
  useLineupStore.setState({ teamId });
}
export function setSquads(squads: Player[]) {
  return useLineupStore.setState({ squads });
}

export function addAddedPlayer(player: Player) {
  return useLineupStore.setState((state) => {
    const addedPlayers = [...state.addedPlayers, player];
    return { addedPlayers };
  });
}

export function removeAddedPlayer(player: Player) {
  return useLineupStore.setState((state) => {
    const addedPlayers = state.addedPlayers.filter((p) => p.id !== player.id);
    return { addedPlayers };
  });
}

export function useIsAddedPlayer(player: Player) {
  const addedPlayers = useLineupStore((state) => state.addedPlayers);
  return addedPlayers.some((p) => p.id === player.id);
}

export function setLineup(lineup: LineupStore['lineup']) {
  return useLineupStore.setState({ lineup });
}

export function selectEntry(
  position: LineupPositionEntry[0],
  player: LineupPositionEntry[1],
) {
  useLineupStore.setState((state) => {
    const prevEntry = state.selectedEntry;

    const isTheSamePosition = prevEntry && prevEntry[0] === position;
    const isTheSamePlayer =
      prevEntry &&
      (prevEntry[1] === player ||
        (prevEntry[1] && player && prevEntry[1].id === player.id));

    if (isTheSamePlayer && isTheSamePosition) {
      return { selectedEntry: null };
    }

    return { selectedEntry: [position, player] };
  });
}
export const onEntrySelected = useLineupStore.subscribe(
  (newState, oldState) => {
    if (
      newState.selectedEntry === oldState.selectedEntry ||
      newState.selectedEntry === null ||
      oldState.selectedEntry === null
    ) {
      return;
    }
    const { selectedEntry: newEntry } = newState;
    const { selectedEntry: oldEntry } = oldState;

    const [destinationPosition, destinationPlayer] = newEntry;
    const [targetPosition, targetPlayer] = oldEntry;
    if (!destinationPlayer && !targetPlayer) return;
    if (destinationPosition === targetPosition) return;
    if (targetPlayer === destinationPlayer) return;

    if (newState.pitchPlayersLenght >= PITCH_PLAYERS_MAX_LEN) {
      if (
        destinationPosition in Position &&
        !(targetPosition in Position) &&
        targetPlayer &&
        !destinationPlayer
      ) {
        return;
      }
      if (
        targetPosition in Position &&
        !(destinationPosition in Position) &&
        !targetPlayer &&
        destinationPlayer
      ) {
        return;
      }
    }

    const lineup = getSwappedPositionLineup(
      newState.lineup,
      oldEntry,
      newEntry,
    );

    useLineupStore.setState({
      lineup,
      selectedEntry: null,
    });
  },
);

export const onPitchPlayersChange = useLineupStore.subscribe(
  (newState, prevState) => {
    const { pitchPlayers } = newState;
    if (prevState.pitchPlayers === pitchPlayers) return;
    setPlayerInputMap(
      pitchPlayers.length
        ? makePlayerQuickInputMap(pitchPlayers.map(({ player }) => player))
        : null,
    );

    return useLineupStore.setState({
      pitchPlayersMap: playerPositionsToMap(pitchPlayers),
      pitchPlayersLenght: pitchPlayers.length,
      formation: calculateFormation(pitchPlayers),
      isGKSelected: pitchPlayers.some(
        ({ position }) => position === Position.Gk,
      ),
    });
  },
);

function adjustLineupOutPlayers(
  allPlayers: Player[],
  lineup: LineupStore['lineup'],
) {
  const lineupNewPlayers = allPlayers.reduce<PlayerPosition[]>(
    (acc, player) => {
      if (acc.some(({ player: p }) => p.id === player.id)) return acc;
      acc.push({ player, position: LINEUP_NAMED_POSITION.OUT });
      return acc;
    },
    [...lineup],
  );
  return lineupNewPlayers;
}

export const onSquadChange = useLineupStore.subscribe((state, prevState) => {
  if (
    state.squads === prevState.squads &&
    state.addedPlayers === prevState.addedPlayers
  ) {
    return;
  }

  const allPlayers = [...state.squads, ...state.addedPlayers];
  setLineup(adjustLineupOutPlayers(allPlayers, state.lineup));
  return;
});

export function decomposeLineup(lineup: LineupStore['lineup']) {
  const { benchPlayers, offPlayers, outPlayers, pitchPlayers } = lineup.reduce(
    (acc, pp) => {
      if (pp.position === LINEUP_NAMED_POSITION.BENCH) {
        acc.benchPlayers.push(pp.player);
      }
      if (pp.position === LINEUP_NAMED_POSITION.OFF) {
        acc.offPlayers.push(pp.player);
      }
      if (pp.position === LINEUP_NAMED_POSITION.OUT) {
        acc.outPlayers.push(pp.player);
      }
      if (isPlayerPositionDto(pp)) {
        acc.pitchPlayers.push(pp);
      }
      return acc;
    },
    {
      benchPlayers: [] as LineupStore['benchPlayers'],
      offPlayers: [] as LineupStore['offPlayers'],
      outPlayers: [] as LineupStore['outPlayers'],
      pitchPlayers: [] as LineupStore['pitchPlayers'],
    },
  );

  return {
    benchPlayers: benchPlayers.sort(sortPlayersByShirtNumber),
    offPlayers: offPlayers.sort(sortPlayersByShirtNumber),
    outPlayers: outPlayers.sort(sortPlayersByShirtNumber),
    pitchPlayers,
  };
}

export function composeLineup(data: SaveLineupDto) {
  const { benchPlayers, pitchPlayers, offPlayers } = data;
  const inGameLineup: LineupStore['lineup'] = [];

  benchPlayers.forEach((player) =>
    inGameLineup.push({ player, position: LINEUP_NAMED_POSITION.BENCH }),
  );
  offPlayers.forEach((player) =>
    inGameLineup.push({ player, position: LINEUP_NAMED_POSITION.OFF }),
  );
  pitchPlayers.forEach((pp) => inGameLineup.push(pp));

  return inGameLineup;
}

export const onLineupChange = useLineupStore.subscribe((state, prevState) => {
  if (state.lineup === prevState.lineup) return;
  const { benchPlayers, offPlayers, outPlayers, pitchPlayers } =
    decomposeLineup(
      adjustLineupOutPlayers(
        [...state.squads, ...state.addedPlayers],
        state.lineup,
      ),
    );

  return useLineupStore.setState({
    benchPlayers,
    offPlayers,
    outPlayers,
    pitchPlayers,
    selectedEntry: null,
  });
});

export function getSwappedPositionLineup(
  lineup: LineupStore['lineup'],
  a: LineupPositionEntry,
  b: LineupPositionEntry,
) {
  const newLineup = [...lineup];
  const [positionA, playerA] = a;
  const [positionB, playerB] = b;

  if (playerA) {
    const newPlayerPosition: PlayerPosition = {
      position: positionB,
      player: playerA,
    };
    const lineupPlayerIdx = newLineup.findIndex(
      ({ player }) => player.id === playerA.id,
    );
    newLineup.splice(lineupPlayerIdx, 1, newPlayerPosition);
  }
  if (playerB) {
    const swappedPlayerPosition: PlayerPosition = {
      position: positionA,
      player: playerB,
    };
    const lineupPlayerIdx = newLineup.findIndex(
      ({ player }) => player.id === playerB.id,
    );
    newLineup.splice(lineupPlayerIdx, 1, swappedPlayerPosition);
  }
  return newLineup;
}

export function submitStartingLineup() {
  const collectionId = useFixtureStore.getState().collectionId;
  if (!collectionId) return;
  const { teamId, benchPlayers, offPlayers, pitchPlayers } =
    useLineupStore.getState();
  if (!teamId) return;

  saveLineup({
    benchPlayers,
    offPlayers,
    pitchPlayers,
    teamId,
    fixtureId: collectionId,
  });
}
