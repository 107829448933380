import useSWRImmutable from 'swr/immutable';
import useSWR from 'swr';
import { CollectionDto } from '@contract';
import { SERVICE_ENDPOINT } from './constants/endpoint';
import { fetchData } from './fetchData';
import { useAuthToken } from './hooks/authToken';

export type FixturesCollectionResponse = {
  previousCollections: CollectionDto[];
  upcomingCollections: CollectionDto[];
};

export function useGetFixturesCollection() {
  const token = useAuthToken();
  // TODO: Change to useSWR when BE stops mocking response.
  return useSWRImmutable<FixturesCollectionResponse>(
    SERVICE_ENDPOINT.FixturesCollection.get,
    (url: string) => fetchData({ url, token }),
  );
}

export function useGetCollection(id?: string) {
  const token = useAuthToken();
  const url = id ? `${SERVICE_ENDPOINT.Collection.get(id)}` : null;
  return useSWR<CollectionDto>(
    url,
    (url: string) => fetchData({ url, token }),
    { revalidateIfStale: false, revalidateOnFocus: false },
  );
}
