import { FC, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import { CANCEL_BUTTON_LABEL } from '@/constants';
import { useFixtureStore } from '@/stores/FixtureStore';
import { pauseClock } from '@/stores/ClockStore';
import { createDefaultAction } from '@/stores/ActionStore/utils';
import type { EndFixtureAction, EndPeriodAction } from '@/types/action/action';
import { ACTION_TYPE_ID, METADATA_KEY } from '@contract';
import { postAction } from '@/service/helpers/postAction';
import { END_MATCH_LABEL } from './constants';

export const EndMatch: FC = () => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const toggleConfirmationOpen = () =>
    setIsConfirmationOpen((current) => !current);
  const closeConfirmation = () => setIsConfirmationOpen(false);
  const $popperAnchor = useRef(null);
  const collectionState = useFixtureStore((state) => state.collectionState);
  const currentPeriod = useFixtureStore((state) => state.currentPeriod);
  const currentPeriodState = useFixtureStore(
    (state) => state.currentPeriodState,
  );

  const onEndMatch = async () => {
    if (!currentPeriod || !collectionState) return;

    if (currentPeriodState && !currentPeriodState.endTime) {
      const endPeriodAction = createDefaultAction<EndPeriodAction>(
        ACTION_TYPE_ID.EndPeriod,
        METADATA_KEY.endPeriod,
      );

      if (endPeriodAction) {
        await postAction({
          ...endPeriodAction,
          period: {
            sequence: currentPeriod.seq,
            label: currentPeriod.label,
          },
          metadata: {
            endPeriod: {},
          },
        });
      }
    }

    const endFixtureAction = createDefaultAction<EndFixtureAction>(
      ACTION_TYPE_ID.EndFixture,
      METADATA_KEY.endFixture,
    );

    if (!endFixtureAction) return;

    postAction({
      ...endFixtureAction,
      metadata: {
        endFixture: {},
      },
      period: {
        sequence: currentPeriod.seq,
        label: currentPeriod.label,
      },
    });

    pauseClock();

    closeConfirmation();
  };

  if (!currentPeriod) return;

  return (
    <Stack direction='row'>
      <Button
        aria-label={END_MATCH_LABEL}
        ref={$popperAnchor}
        onClick={toggleConfirmationOpen}
        variant='text'
        color='inherit'
      >
        {END_MATCH_LABEL}
      </Button>
      <Popper
        anchorEl={$popperAnchor.current}
        open={isConfirmationOpen}
        placement='bottom-end'
      >
        <ClickAwayListener onClickAway={closeConfirmation}>
          <Paper elevation={6}>
            <Stack p={2} gap={3}>
              <Typography variant='body1'>{END_MATCH_LABEL}</Typography>
              <Stack direction='row' gap={1}>
                <Button
                  variant='text'
                  color='inherit'
                  onClick={closeConfirmation}
                >
                  {CANCEL_BUTTON_LABEL}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={onEndMatch}
                >
                  {END_MATCH_LABEL}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Stack>
  );
};
