import {
  GoalkeeperSaveAttemptType,
  GoalkeeperSaveBodyPart,
  GoalkeeperSaveType,
  GoalkeeperSaveExtras,
} from '@contract';

export const GOALKEEPER_SAVE_ATTEMPT_TYPE: Record<
  GoalkeeperSaveAttemptType,
  string
> = {
  [GoalkeeperSaveAttemptType.TemptSave]: 'Temp Save',
  [GoalkeeperSaveAttemptType.Save]: 'Save',
};

export const GOALKEEPER_SAVE_BODY_PART: Record<GoalkeeperSaveBodyPart, string> =
  {
    [GoalkeeperSaveBodyPart.Hands]: 'Hands',
    [GoalkeeperSaveBodyPart.Body]: 'Body',
    [GoalkeeperSaveBodyPart.Feet]: 'Feet',
    [GoalkeeperSaveBodyPart.Other]: 'Other',
  };

export const GOALKEEPER_SAVE_STANCE: Record<GoalkeeperSaveType, string> = {
  [GoalkeeperSaveType.Catch]: 'Catch',
  [GoalkeeperSaveType.Collected]: 'Collected',
  [GoalkeeperSaveType.ParriedDanger]: 'Parried Danger',
  [GoalkeeperSaveType.ParriedSafe]: 'Parried Safe',
};

export const GOALKEEPER_SAVE_EXTRAS: Record<GoalkeeperSaveExtras, string> = {
  [GoalkeeperSaveExtras.Penalty]: 'Penalty',
  [GoalkeeperSaveExtras.HitWoodwork]: 'Hit WoodWork',
};

export const goalkeeperSaveExtras = Object.keys(GOALKEEPER_SAVE_EXTRAS).map(
  (k) => {
    const key = Number(k) as GoalkeeperSaveExtras;

    return {
      value: key,
      label: GOALKEEPER_SAVE_EXTRAS[key],
    };
  },
);
