/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';

import PitchVerticalSVG from '@/assets/football-pitch-vertical.svg?react';
import { PitchCoords } from '@/stores/ActionStore/ActionStore';
import { AttemptType, CoordinatesDto } from '@contract';
import { PitchControls } from '@/components/Pitch/PitchControls';
import { ClickableDots, DotProps } from '@/components/Pitch/ClickableDots';
import {
  calculateCoordsClockwise,
  calculateCoordsCounterClockwise,
} from '@/components/Pitch/utils';
import { ShotAction, GoalAction, OwnGoalAction } from '@/types/action/action';
import { DIRECTION_OF_PLAY } from '@/components/Periods/constants';
import { useCollectionStore } from '@/stores/CollectionStore';
import {
  DEFENDERS_VALUES,
  PITCH_PLAYERS_VALUES,
  PITCH_POSITION,
} from '../constants';

const getShotDot = (
  position: CoordinatesDto,
  directionOfPlay: DIRECTION_OF_PLAY,
) => ({
  positionId: PITCH_POSITION.SHOT,
  coords:
    directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
      ? calculateCoordsClockwise(position)
      : calculateCoordsCounterClockwise(position),
  color: PITCH_PLAYERS_VALUES.SHOT.COLOR,
});

const getGoalkeeperDot = (
  blockersPositions: CoordinatesDto[],
  directionOfPlay: DIRECTION_OF_PLAY,
) => ({
  positionId: PITCH_POSITION.GOALKEEPER,
  coords:
    directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
      ? calculateCoordsClockwise(blockersPositions[0])
      : calculateCoordsCounterClockwise(blockersPositions[0]),
  color: PITCH_PLAYERS_VALUES.GOALKEEPER.COLOR,
});

const getBlockersDots = (
  blockersPositions: CoordinatesDto[],
  directionOfPlay: DIRECTION_OF_PLAY,
) => {
  const blockersWithoutGoalkeeper = blockersPositions
    ? [...blockersPositions].filter((_, i) => i !== 0)
    : [];
  const blockerDots = blockersWithoutGoalkeeper?.map((blockerCoords, i) => ({
    positionId: PITCH_POSITION.DEFENDER + (i + 1),
    coords:
      directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
        ? calculateCoordsClockwise(blockerCoords)
        : calculateCoordsCounterClockwise(blockerCoords),
    color: PITCH_PLAYERS_VALUES.DEFENDER.COLOR,
  }));

  return blockerDots;
};

interface PitchProps {
  position: CoordinatesDto;
  blockersPositions?: CoordinatesDto[] | null;
  attemptType?: AttemptType;
  setPartialMetadata: (
    metadata:
      | Partial<ActionMeta<ShotAction>>
      | Partial<ActionMeta<GoalAction>>
      | Partial<ActionMeta<OwnGoalAction>>,
  ) => void;
}

export const ShotPitch: FC<PitchProps> = ({
  setPartialMetadata,
  position,
  blockersPositions,
  attemptType,
}) => {
  const directionOfPlay = useCollectionStore((state) => state.directionOfPlay);
  const [initialShotCoordinates, setInitialShotCoordinates] =
    useState<Omit<PitchCoords, 'pitchArea'>>();
  const [clickableDots, setClickableDots] = useState<DotProps[] | null>(null);
  const $pitchContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initialShotCoordinates || !position) return;

    setInitialShotCoordinates(
      directionOfPlay === DIRECTION_OF_PLAY.LEFT_TO_RIGHT
        ? calculateCoordsCounterClockwise(position)
        : calculateCoordsClockwise(position),
    );
  }, [position]);

  useEffect(() => {
    if (!position) return;

    const shotDot = getShotDot(position, directionOfPlay);

    if (!blockersPositions) {
      setClickableDots([shotDot]);
      return;
    }

    const goalkeeperDot = getGoalkeeperDot(blockersPositions, directionOfPlay);
    const blockersDots = getBlockersDots(blockersPositions, directionOfPlay);

    setClickableDots([shotDot, goalkeeperDot, ...blockersDots]);
  }, [position, blockersPositions]);

  useEffect(() => {
    if (!$pitchContainer.current || !initialShotCoordinates) return;

    if (initialShotCoordinates.yDraw < 50) return;

    $pitchContainer.current.scrollTop =
      ((initialShotCoordinates.yDraw - 50) / 100) *
      $pitchContainer.current.scrollHeight;
  }, [$pitchContainer, initialShotCoordinates]);

  const handleDefendersCountChange = useCallback(
    (count: number) => {
      if (
        (count > DEFENDERS_VALUES.MAX_COUNT &&
          count < DEFENDERS_VALUES.MIN_COUNT) ||
        !blockersPositions
      )
        return;

      if (blockersPositions && count < blockersPositions.length) {
        const newArray = [...blockersPositions];
        newArray.pop();
        setPartialMetadata({ blockersPositions: newArray });
        return;
      }

      const x =
        directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
          ? DEFENDERS_VALUES.RTL_POSITION.X
          : DEFENDERS_VALUES.LTR_POSITION.X;
      const y =
        directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
          ? DEFENDERS_VALUES.RTL_POSITION.Y +
            (blockersPositions || []).length * DEFENDERS_VALUES.GAP
          : DEFENDERS_VALUES.LTR_POSITION.Y +
            (blockersPositions || []).length * DEFENDERS_VALUES.GAP;

      setPartialMetadata({
        blockersPositions: [...(blockersPositions || []), { x, y }],
      });
    },
    [blockersPositions, directionOfPlay],
  );

  const setCoords = (dot: DotProps) => {
    if (!dot.positionId) return;

    const calculatedCoords =
      directionOfPlay === DIRECTION_OF_PLAY.RIGHT_TO_LEFT
        ? calculateCoordsCounterClockwise(dot.coords)
        : calculateCoordsClockwise(dot.coords);

    if (dot.positionId === PITCH_POSITION.SHOT) {
      setPartialMetadata({ position: calculatedCoords });
      return;
    }
    if (dot.positionId === PITCH_POSITION.GOALKEEPER) {
      const editedBlockersPositions = [...(blockersPositions || [])];

      editedBlockersPositions[0] = calculatedCoords;
      setPartialMetadata({ blockersPositions: editedBlockersPositions });
      return;
    }

    const defenderPositionId = dot.positionId.split(PITCH_POSITION.DEFENDER);

    if (defenderPositionId.length === 2) {
      const defenderIndex = parseInt(defenderPositionId[1]);
      const editedDefendersPositions = [...(blockersPositions || [])];
      editedDefendersPositions[defenderIndex] = calculatedCoords;
      setPartialMetadata({ blockersPositions: editedDefendersPositions });
    }
  };

  return (
    <>
      <Stack
        id='clickable-pitch'
        ref={$pitchContainer}
        sx={{
          overflowX: 'hidden',
          position: 'relative',
        }}
        overflow='scroll'
        height='550px'
      >
        <Box sx={{ position: 'relative' }}>
          <ClickableDots dots={clickableDots || []} setCoords={setCoords}>
            <PitchVerticalSVG />
          </ClickableDots>
        </Box>
      </Stack>
      {attemptType !== undefined && (
        <PitchControls
          attemptType={attemptType}
          defendersCount={blockersPositions ? blockersPositions.length - 1 : 0}
          onDefendersCountChange={handleDefendersCountChange}
        />
      )}
    </>
  );
};
