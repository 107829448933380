/* eslint-disable no-restricted-imports */
import { FixtureActionType as ACTION_TYPE_ID } from '@img-arena/sps-msed-contract';

export const PLAYER_INPUT_AUTO_COMMIT_TIME = 300;

export const ACTION_STATE = {
  NONE: 'none',
  ERROR: 'error',
  SYNC: 'sync',
  SUCCESS: 'success',
} as const;
export type ActionState = ConstType<typeof ACTION_STATE>;

export const AWARDED_ACTIONS = [
  ACTION_TYPE_ID.CornerAwarded,
  ACTION_TYPE_ID.ThrowInAwarded,
  ACTION_TYPE_ID.FreeKickAwarded,
  ACTION_TYPE_ID.GoalKickAwarded,
];

export const TAKEN_ACTIONS = [
  ACTION_TYPE_ID.Pass,
  ACTION_TYPE_ID.PenaltyAwarded,
  ACTION_TYPE_ID.EndPeriod,
  ACTION_TYPE_ID.Cross,
  ACTION_TYPE_ID.Launch,
  ACTION_TYPE_ID.Shot,
  ACTION_TYPE_ID.Goal,
];
