import Storage from '@mui/icons-material/Storage';
import Warning from '@mui/icons-material/Warning';
import { CircularProgress } from '@mui/material';
import { TableAction } from '@/stores/FixtureStore';
import { ACTION_STATE, ActionState } from '@/stores/constants';
import { Dot } from '../Dot/Dot';

export enum EditAction {
  DIALOG = 'dialog',
  CONTEXT = 'context',
}

type ColumnId = keyof TableAction;
export type ActionTableColumn = {
  id: ColumnId;
  name: JSX.Element | string;
  width: number;
};

export const COLUMNS: ActionTableColumn[] = [
  { id: 'createdAt', name: 'Created at', width: 10 },
  { id: 'updatedAt', name: 'Updated at', width: 7 },
  { id: 'time', name: 'Time', width: 8 },
  { id: 'type', name: 'Event', width: 8 },
  { id: 'player', name: 'Player', width: 9 },
  { id: 'targetPlayer', name: 'Target', width: 9 },
  { id: 'extras', name: 'Extras', width: 27 },
  { id: 'isSuccessful', name: 'Outcome', width: 5 },
  { id: 'actionId', name: 'Action Id', width: 11 },
  { id: 'warnings', name: <Warning fontSize='small' />, width: 3 },
  { id: 'state', name: <Storage fontSize='small' />, width: 3 },
];

(function () {
  const totalWidth = COLUMNS.reduce((acc, col) => {
    return acc + col.width;
  }, 0);
  if (totalWidth !== 100) {
    console.error(
      `ActionsTable columns width is ${totalWidth}% - should be a 100%`,
    );
  }
})();

export const TABLE_CLASS_NAMES = {
  ROW: 'action-row',
};

export const NO_EVENTS_LABEL = 'No events added yet';

export const TABLE_ACTION_STATE_ICON: Record<ActionState, JSX.Element> = {
  [ACTION_STATE.NONE]: <span data-state={ACTION_STATE.NONE}></span>,
  [ACTION_STATE.ERROR]: (
    <Dot data-state={ACTION_STATE.ERROR} color='red' size='1em'></Dot>
  ),
  [ACTION_STATE.SYNC]: (
    <CircularProgress data-state={ACTION_STATE.SYNC} size='1em' />
  ),
  [ACTION_STATE.SUCCESS]: (
    <Dot data-state={ACTION_STATE.SUCCESS} color='green' size='1em'></Dot>
  ),
} as const;
export type TableActionStateIcon = ConstType<typeof TABLE_ACTION_STATE_ICON>;
