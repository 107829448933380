import { MouseEvent as ReactMouseEvent } from 'react';
import { CoordinatesDto } from '@contract';

export const calculateCoordsClockwise = (coords: CoordinatesDto) => {
  return {
    x: coords.y,
    y: (10000 - coords.x * 100) / 100,
    xDraw: coords.y,
    yDraw: coords.x,
  };
};

export const calculateCoordsCounterClockwise = (coords: CoordinatesDto) => {
  return {
    x: (10000 - coords.y * 100) / 100,
    y: coords.x,
    xDraw: (10000 - coords.y * 100) / 100,
    yDraw: (10000 - coords.x * 100) / 100,
  };
};

export const getPitchOffset = (e: ReactMouseEvent, clientRect: DOMRect) => {
  if (!clientRect)
    return { offsetX: 0, offsetY: 0, pitchHeight: 0, pitchWidth: 0 };

  let offsetX = e.clientX - clientRect.left;
  let offsetY = e.clientY - clientRect.top;

  if (offsetX < 0) offsetX = 0;
  if (offsetX > clientRect.width) offsetX = clientRect.width;
  if (offsetY < 0) offsetY = 0;
  if (offsetY > clientRect.height) offsetY = clientRect.height;

  return {
    offsetX,
    offsetY,
    pitchHeight: clientRect.height,
    pitchWidth: clientRect.width,
  };
};
