import Add from '@mui/icons-material/Add';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Edit from '@mui/icons-material/Edit';
import Remove from '@mui/icons-material/Remove';
import {
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { FC, useRef, useState } from 'react';
import Pause from '@mui/icons-material/Pause';
import {
  updateClockBy,
  pauseClock,
  startClock,
  useClockStore,
} from '@/stores/ClockStore';
import { EditClock } from './EditClock';

const increase = () => updateClockBy(1);
const decrease = () => updateClockBy(-1);

const CLOCK_CONTROL_ID = {
  DECREASE: 'clock-decrease',
  INCREASE: 'clock-increase',
  EDIT: 'clock-edit',
  PAUSE: 'clock-pause',
  START: 'clock-start',
};

export const ClockControls: FC = () => {
  const isRunning = useClockStore((state) => state.isRunning);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const toggleEditOpen = () => setIsEditOpen((current) => !current);
  const closeEdit = () => setIsEditOpen(false);
  const $popperAnchor = useRef(null);

  const PlayPauseButton = isRunning ? (
    <IconButton id={CLOCK_CONTROL_ID.PAUSE} onClick={pauseClock}>
      <Pause />
    </IconButton>
  ) : (
    <IconButton id={CLOCK_CONTROL_ID.START} onClick={startClock}>
      <PlayArrow />
    </IconButton>
  );

  return (
    <Stack direction='row'>
      {PlayPauseButton}
      <IconButton id={CLOCK_CONTROL_ID.DECREASE} onClick={decrease}>
        <Remove />
      </IconButton>
      <IconButton id={CLOCK_CONTROL_ID.INCREASE} onClick={increase}>
        <Add />
      </IconButton>
      <IconButton
        id={CLOCK_CONTROL_ID.EDIT}
        ref={$popperAnchor}
        onClick={toggleEditOpen}
      >
        <Edit />
      </IconButton>
      <Popper anchorEl={$popperAnchor.current} open={isEditOpen}>
        <ClickAwayListener onClickAway={closeEdit}>
          <Paper elevation={6}>
            <EditClock afterSubmit={closeEdit} />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Stack>
  );
};
