import { FC, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { PeriodDto } from '@contract';
import { PassAction } from '@/types/action/action';
import { TimeSelect } from '@/components/Form/TimeSelect';
import { postAction } from '@/service/helpers/postAction';
import { timeStringToSeconds } from '@/utils/timeStringToSeconds';
import {
  COMMIT_BUTTON_LABEL,
  RESET_BUTTON_LABEL,
  TIME_LABEL,
} from '@/constants';
import { setAction, setContextAction } from '@/stores/ActionStore/ActionStore';
import type { PropsWithAction } from '../ActionContextMenuContent';

export const EditActionTimePanel: FC<PropsWithAction> = ({ action }) => {
  const [clockTime, setClockTime] = useState<string>(action.clockTime);
  const onReset = () => {
    setClockTime(action.clockTime);
  };

  const onPeriodChange = (period: PeriodDto | null) => {
    setAction({
      ...action,
      period,
    });
  };

  const onClockTimeChange = (clockTime: string) => {
    setClockTime(clockTime);
  };

  const onSubmit = () => {
    postAction({
      ...(action as PassAction),
      clockTime,
      clockTimeTicks: timeStringToSeconds(clockTime),
    });
    setContextAction(null);
  };

  return (
    <Stack gap={2} sx={{ p: 2 }}>
      <TimeSelect
        onClockTimeChange={onClockTimeChange}
        period={action.period}
        onPeriodChange={onPeriodChange}
        controlLabel={TIME_LABEL}
        clockTime={clockTime}
      />
      <Stack justifyContent={'flex-end'} direction='row'>
        <Button
          onClick={onReset}
          variant='text'
          disabled={action.clockTime === clockTime}
        >
          {RESET_BUTTON_LABEL}
        </Button>
        <Button
          onClick={onSubmit}
          variant='outlined'
          disabled={action.clockTime === clockTime}
          sx={{ flex: '1' }}
        >
          {COMMIT_BUTTON_LABEL}
        </Button>
      </Stack>
    </Stack>
  );
};
